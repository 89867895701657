import React, {useState, useEffect } from "react";
 import Lghelp from "./Lghelp";
 import Navbar from "./Navbar";
import { FcAutomotive } from "react-icons/fc";
import { FcApproval } from "react-icons/fc";
 import Footer from "./Footer";
import { FcFlashOn } from "react-icons/fc";
import blablaCarImage from '../Images/dashboard.jpg';
import blablaCarImage1 from '../Images/dashboard 3.jpeg';

import './Landing.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
 
function Landing() {
  useEffect(() => {
    Aos.init({duration: 1000});
  }, []);
  const [showDropdown, setShowDropdown] = useState(false);
 
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [passengers, setPassengers] = useState(1);
 
  const handleSwapLocations = () => {
    const temp = from;
    setFrom(to);
    setTo(temp);
  };
 
  const handleFromChange = (e) => {
    setFrom(e.target.value);
  };
 
  const handleToChange = (e) => {
    setTo(e.target.value);
  };
 
  const handleIncrement = () => {
    if (passengers < 8) {
      setPassengers(passengers + 1);
    }
  };
 
  const handleDecrement = () => {
    if (passengers > 1 ) {
      setPassengers(passengers - 1);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const destinations = [
     { source: 'New Delhi', destination: 'Chandigarh' },
    { source: 'New Delhi', destination: 'Jaipur' },
     { source: 'New Delhi', destination: 'Agra' },
     { source: 'Mumbai', destination: 'Pune' },
     { source: 'Agra', destination: 'New Delhi' },
     { source: 'Jaipur', destination: 'New Delhi' }
 
  ];

  const [showAllDestinations, setShowAllDestinations] = useState(false);

  const toggleDestinations = () => {
    setShowAllDestinations(!showAllDestinations);
  };

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  const destinationsToRender = showAllDestinations ? destinations : destinations.slice(0, 3);

  const rows = chunkArray(destinationsToRender, 3);


  return (
    <div className="home-page">
   <Navbar />
     <div className="lg-whole">
      <div>

     <div className="lg-hero-1">
      <div>
     </div>
    <div className="lg-comes-lion-1">
    <h1 className="lg-hero-car-1">EZY Ride</h1>
     <p className="lg-comes-lion-2">Where Safety Comes First, Every Journey, Every Time.</p>
     
     </div>
     </div>
     
  </div>

    

      <main className="lg-main-content">
        <section className="lg-hero">
          <h2 className="lg-text"></h2>
         
        </section>
<div >
      <div>
      </div>
      <div className="lg-card-container">
        <div className="lg-card">
          <div className="lg-card__icon">
           <FcAutomotive  className='lg-card-lgicon' />
          </div>
          <div className="lg-card__content">
            <p className="lg-card__title">Affordable Adventures: Explore More for Less!</p>
            <p className="lg-card__description"> 
             Find your perfect ride, whether it's by bus or 
             carpool, to any destination, all at incredibly 
             low prices. Start your journey with us today!.</p>
          </div>
        </div>
 
        <div className="lg-card">
          <div className="lg-card__icon">
            {/* Using FiClock icon */}
            <FcApproval   className='lg-card-lgicon'/>
          </div>
          <div className="lg-card__content">
            <p className="lg-card__title">Reliable Rides, Trusted Travels</p>
            <p className="lg-card__description"> 
              We make sure you know your travel companions.
              With thorough reviews, profiles, and ID checks, 
              booking your ride is secure and stress-free. 
              Travel confidently with us!.</p>
          </div>
        </div>
 
        <div className="lg-card">
          <div className="lg-card__icon">
            {/* Using FiHome icon */}
            <FcFlashOn   className='lg-card-lgicon' />
          </div>
          <div className="lg-card__content">
            <p className="lg-card__title">Effortless Travel: Scroll, Click, Tap, and Explore!</p>
            <p className="lg-card__description"> 
             Booking made simple! Our user-friendly app,
             backed by cutting-edge technology, lets you 
             find and book a ride near you in minutes.
             Seamless travel at your fingertips!</p>
          </div>
        </div>
      </div>
     
    </div>



</main>
   
<div className="lg-cta-our">
<div className="lg-paragraph-our">
  <div>
<img className="lg-our-img" src={blablaCarImage} alt="BlaBlaCar"  />
</div>
<section className="lg-our-1">

    <h2 className="lg-our-2">Ensuring Your Safety</h2>
    <p className="lg-our-3">
    Safeguarding your journey is paramount to us at Ensuring .
    While we strive to maintain the highest levels of security on our
     platform, we understand that occasional scams may occur.
      That's why we're committed to empowering you with the knowledge
      to avoid and report any such incidents. Follow our expert tips to 
    help us uphold your safety standards.
    </p>
   
  </section>
 
  <div class="lg-text-center">
  <div class="lg-button-container">
    <a href="/login" class="lg-learn-more-btn-1">Learn more</a>
  </div>
</div>
 
 

</div>
 
</div>
 
 
<div className="lg-car-pn-pm">
<div className="lg-paragraph-drive">
<div>
<img className="lg-car-img" src={blablaCarImage1} alt="BlaBlaCar"  />
</div>
  <div className="lg-paragraph-car">
  <section className="lg-drive-1">
 
    <h2 className="lg-paragraph-1">Drive Smart, Earn Rewards!</h2>
    <p className="lg-paragraph-2">
    Drivers, here's some exciting news! Your smart driving
    choices are now being recognized and rewarded through 
    our Carpool Bonus program. Dive into carpooling and 
    unlock exclusive rewards. Check out the eligibility 
    requirements and start earning rewards for your 
     eco-friendly efforts! 
      </p>
    </section>
    <a href="/login" class="lg-more-btn-2">Offer a ride</a>

  </div>
</div>
</div>

<div className="lg-want-ps">
        <h2 className="lg-pn-pm-want">Where do you want to go?</h2>
        <div className="lg-destination-row">
          {destinations.slice(0, 3).map((destination, index) => (
            <div key={index} className={`lg-destination-box lg-box-${index + 1}`}>
              <span className="lg-destination">{destination.source}</span>
              <span className="lg-arrow">&rarr;</span>
              <span className="lg-destination">{destination.destination}</span>
              <span className="lg-end-arrow">&gt;</span>
            </div>
          ))}
        </div>
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} className="lg-destination-row">
            {row.map((destination, index) => (
              <div key={index} className={`lg-destination-box lg-box-${index + 4}`}>
                <span className="lg-destination">{destination.source}</span>
                <span className="lg-arrow">&rarr;</span>
                <span className="lg-destination">{destination.destination}</span>
                <span className="lg-end-arrow">&gt;</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <button className="lg-pn-pm-See" onClick={toggleDestinations}>
        {showAllDestinations ? 'See fewer rides' : 'See our most popular rides'}
      </button>
 
</div>
 <Lghelp />
 <Footer />
</div>

 
   
   
  );
}

 
export default Landing;