import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import UserSidebar from '../components/UserNavbars/Usersidebar';
import UsersNavbar from '../Landingpages/UsersNavbar';



const Sampledata = () => {
    const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [ setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'user') {
        navigate('/');
      }
    }
  }, []);
  return (
    <div>
       <UserSidebar/>
      <UsersNavbar/>
      <div>
        <p>pages</p>
        <p>{username}</p>
        </div>
    </div>
  )
}

export default Sampledata