import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Signup.css';
 
import AlertMessage from './AlertMessage';
 
const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [dob, setDob] = useState('');
  const [dobError, setDobError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [reEnterPasswordError, setReEnterPasswordError] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // Added state for showing/hiding password

 
 
    const handleAlertClose = () => {
    setAlertOpen(false);
  };
 
  const validateFirstName = () => {
    if (!firstName) {
      setFirstNameError('First Name is required');
      return false;
    }
    setFirstNameError('');
    return true;
  };
 
  const validateLastName = () => {
    if (!lastName) {
      setLastNameError('Last Name is required');
      return false;
    }
    setLastNameError('');
    return true;
  };
 
  const validateDob = () => {
    if (!dob) {
      setDobError('Date of Birth is required');
      return false;
    }
    setDobError('');
    return true;
  };
 
  const validatePhoneNumber = () => {
    const phoneRegex = /^\d{10}$/;
    if (!phoneNumber.match(phoneRegex)) {
      setPhoneNumberError('Please enter a valid phone number');
      return false;
    }
    setPhoneNumberError('');
    return true;
  };
 
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(emailRegex)) {
      setEmailError('Please enter a valid email address');
      return false;
    }
    setEmailError('');
    return true;
  };
 
  const validatePassword = () => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!password.match(passwordRegex)) {
      setPasswordError('Password must contain at least 8 characters, including 1 uppercase letter, 1 number, and 1 special character');
      return false;
    }
    setPasswordError('');
    return true;
  };
 
  const handleFirstNameBlur = () => {
    validateFirstName();
  };
 
  const handleLastNameBlur = () => {
    validateLastName();
  };
 
  const handleDobBlur = () => {
    validateDob();
  };
 
  const handlePhoneNumberBlur = () => {
    validatePhoneNumber();
  };
 
  const handleEmailBlur = () => {
    validateEmail();
  };
 
   const handlePasswordBlur = () => {
    validatePassword();
   };
 
  const handleSignup = async () => {
    setLoading(true);
 
    // Validate all fields before submitting the form
    const isValidFirstName = validateFirstName();
    const isValidLastName = validateLastName();
    const isValidDob = validateDob();
    const isValidPhoneNumber = validatePhoneNumber();
    const isValidEmail = validateEmail();
    const isValidPassword = validatePassword();
 
    if (!isValidFirstName || !isValidLastName || !isValidDob || !isValidPhoneNumber || !isValidPassword || !isValidEmail ) {
      setLoading(false);
      return;
    }
 
    try {
      console.log('Preparing form data...');
      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('dob', dob);
      formData.append('phoneNumber', phoneNumber);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('role', 'user'); // Added hidden role field
      formData.append('profileImage', profileImage);
 
      console.log('Sending signup request...');
      const response = await axios.post('https://api.ezyrides.chitgenius.com/login', {
        firstName,
        lastName,
        dob,
        phoneNumber,
        email,
        password
    });
 
    console.log('Signup response:', response.data);
    if (response.data.success) {
        setAlertSeverity('success');
        setAlertMessage('Signup successful!');
        setAlertOpen(true);
        
      } else {
        if (response.data.message === 'Phone number already registered') {
          setAlertSeverity('error');
          setAlertMessage('Phone number is already registered');
        } else {
          setAlertSeverity('error');
          setAlertMessage(response.data.message || 'Signup failed');
        }
        setAlertOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setAlertSeverity('error');
        setAlertMessage(error.response.data.message);
      } else {
        setAlertSeverity('error');
        setAlertMessage('An error occurred while signing up');
      }
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };
 
 
  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };
 
  return (
    <div className="rg_background">
    <div className="rg_register-container">
      <div className="rg_register">
        <div className="rg_animation1">
          <h3 className="rg_heading">EZY Rides</h3>
          <h2>best rides, best prices</h2>
        </div>
        <div className="rg_form1">
          <h2>Welcome</h2>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onBlur={handleFirstNameBlur}
          />
          {firstNameError && <span className="error-message">{firstNameError}</span>}
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onBlur={handleLastNameBlur}
          />
          {lastNameError && <span className="error-message">{lastNameError}</span>}
          <input
            type="date"
            placeholder="Date of Birth"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            onBlur={handleDobBlur}
          />
          {dobError && <span className="error-message">{dobError}</span>}
          <input
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            onBlur={handlePhoneNumberBlur}
          />
          {phoneNumberError && <span className="error-message">{phoneNumberError}</span>}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={handleEmailBlur}
          />
          {emailError && <span className="error-message">{emailError}</span>}
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
           onBlur={handlePasswordBlur}
          />
          {passwordError && <span className="error-message">{passwordError}</span>}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            disabled
          />
          <input
            type="hidden"
            name="role"
            value="user"
          />
           <button className="rg_signup" onClick={handleSignup}>
      {loading ? 'Signing up...' : 'Sign Up'}
    </button>
    <p className="login-text">If you have an account? <a href="/Login" className="login-link">Login</a></p>
    <p className="login-text">Go to  <a href="/" className="signup-link">Home page</a></p>
        </div>
      </div>

     

      <AlertMessage
        open={alertOpen}
        severity={alertSeverity}
        message={alertMessage}
        handleClose={handleAlertClose}
      />
    </div>
   

  </div>
  
);
};
 
 
export default Signup;
 