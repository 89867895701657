
import React, { useState, useEffect } from "react";
import { IoMdAlarm } from "react-icons/io";
import axios from "axios";
import { IoCarSport } from "react-icons/io5";
import { IoLocationSharp } from "react-icons/io5";
import { CgSandClock } from "react-icons/cg";
import { FaUserClock } from "react-icons/fa";
// import { BiAlarm } from "react-icons/bi";
import { GrGroup } from "react-icons/gr";
import { AiOutlineFieldTime } from "react-icons/ai";
import Select from "react-select";
 
import { FaPlus, FaMinus, FaRegUser } from "react-icons/fa";
 
import { IoCalendarOutline } from "react-icons/io5";
 
import { LuArrowDownUp } from "react-icons/lu";
 
import { IoMdCheckbox } from "react-icons/io";
 
import {
  MdRadioButtonChecked,
  MdCheckBoxOutlineBlank,
  MdVerifiedUser,
  MdPets,
  MdSmokingRooms,
  MdBookmark,
} from "react-icons/md";
 
import { FaRupeeSign } from "react-icons/fa";
 
import manImage from "../Images/man.jpeg";
 
import { Link } from "react-router-dom";
 
import { MdRunCircle } from "react-icons/md";
 
import { jwtDecode } from "jwt-decode";
 
import Cookies from "js-cookie";
 
import {
  FaClock,
  FaDollarSign,
  FaMapMarkerAlt,
  FaFlagCheckered,
  FaUserCheck,
  FaBolt,
  FaSmoking,
  FaPaw,
} from "react-icons/fa";
 
import { CiSliderVertical } from "react-icons/ci";
 
import bagg from "../Images/bagg.png";
 
import "./Ser.css";
 
import { useLocation, useNavigate } from "react-router-dom";
 
import UsersNavbar from "../Landingpages/UsersNavbar";
 
import { MdOutlineArrowForward } from "react-icons/md";
 
import { IoArrowBackSharp } from "react-icons/io5";
 
const Ser = () => {
  const [isDisabled, setIsDisabled] = useState(false); // default value
 
  const navigate = useNavigate();
 
  const [username, setUsername] = useState("");
 
  const [role, setRole] = useState("");
 
  const location = useLocation();
 
  const params = new URLSearchParams(location.search);
 
  const initialFrom = params.get("from") || "";
 
  const initialTo = params.get("to") || "";
 
  const initialDate = params.get("date") || "";
 
  const initialPassengers = params.get("passengers") || "";
 
  const [profileImage, setProfileImage] = useState();
  const [firstName, setFirstName] = useState();
 
  const [fromOptions, setFromOptions] = useState([]);
 
  const [toOptions, setToOptions] = useState([]);
 
  const [from, setFrom] = useState(initialFrom);
 
  const [to, setTo] = useState(initialTo);
 
  const [date, setDate] = useState(initialDate);
 
  const [passengers, setPassengers] = useState(initialPassengers);
 
  const [searchResults, setSearchResults] = useState([]);
 
  const [loading, setLoading] = useState(false);
 
  const [error, setError] = useState(null);
 
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
 
  const [invalidData, setInvalidData] = useState(false);
 
  const [invalidFrom, setInvalidFrom] = useState("");
 
  const [invalidTo, setInvalidTo] = useState("");
 
  const [filters, setFilters] = useState({
    lowestPrice: false,
 
    verifiedProfile: false,
 
    instantBooking: false,
 
    smokingAllowed: false,
 
    petsAllowed: false,
  });
 
  useEffect(() => {
    const role = localStorage.getItem("role");
 
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get("token");
 
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
 
      setUsername(decoded.username);
 
      setRole(decoded.role);
 
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    const fetchRides = async () => {
      try {
        const response = await axios.get("https://api.ezyrides.chitgenius.com/rides");
 
        const rides = response.data.rides;
 
        const departureLocations = [
          ...new Set(rides.map((ride) => ride.leavingfrom)),
        ];
 
        const destinationLocations = [
          ...new Set(rides.map((ride) => ride.goingto)),
        ];
 
        setFromOptions(
          departureLocations.map((location) => ({
            value: location,
            label: location,
          }))
        );
 
        setToOptions(
          destinationLocations.map((location) => ({
            value: location,
            label: location,
          }))
        );
 
        if (location.state) {
          const { from, to, date, passengers } = location.state;
 
          setFrom(from);
 
          setTo(to);
 
          setDate(date);
 
          setPassengers(passengers);
        }
      } catch (error) {
        console.error("Error fetching rides:", error);
 
        setError("Error fetching rides. Please try again later.");
      }
    };
 
    fetchRides();
  }, [location.state]);
 
  const handleSearch = async () => {
    setSearchResults([]);
    setError(null);
    setLoading(true);
    setShowAdditionalInfo(false);
    setInvalidData(false);
    try {
      const response = await axios.post("https://api.ezyrides.chitgenius.com/search", {
        from,
        to,
        date,
        passengers,
      });
      if (response.data.success) {
        let filteredRides = response.data.rides;
        if (filters.lowestPrice) {
          filteredRides.sort((a, b) => a.price - b.price);
        }
        if (filters.verifiedProfile) {
          filteredRides = filteredRides.filter((ride) => ride.verifiedProfile);
        }
        if (filters.instantBooking) {
          filteredRides = filteredRides.filter((ride) => ride.instantBooking);
        }
        if (filters.smokingAllowed) {
          filteredRides = filteredRides.filter(
            (ride) => ride.smoke !== "No smoking"
          );
        }
        if (filters.petsAllowed) {
          filteredRides = filteredRides.filter((ride) => ride.petsAllowed);
        }
 
        if (filteredRides.length > 0 && filteredRides[0].profileImage) {
          setProfileImage(filteredRides[0].profileImage);
        }
        if (filteredRides.length > 0 && filteredRides[0].firstName) {
          setFirstName(filteredRides[0].firstName);
        }
        setSearchResults(filteredRides);
        setShowAdditionalInfo(true);
        setInvalidData(false);
        setInvalidFrom("");
        setInvalidTo("");
      } else {
        setInvalidData(true);
        setInvalidFrom(from);
        setInvalidTo(to);
        setShowAdditionalInfo(true);
      }
    } catch (error) {
      setError("Error searching for rides. Please try again later.");
      console.error("Error searching for rides:", error);
    } finally {
      setLoading(false);
    }
  };
 
  const handleFromChange = (selectedOption) => {
    setFrom(selectedOption.value);
  };
 
  const handleToChange = (selectedOption) => {
    setTo(selectedOption.value);
  };
 
  const handleDecrement = () => {
    if (parseInt(passengers) > 1) {
      setPassengers(parseInt(passengers) - 1);
    }
  };
 
  const handleIncrement = () => {
    if (parseInt(passengers) < 8) {
      setPassengers(parseInt(passengers) + 1);
    }
  };
 
  const handleFilterChange = (filter) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [filter]: !prevFilters[filter] };
 
      let filteredRides = searchResults;
 
      if (newFilters.lowestPrice) {
        filteredRides = [...filteredRides].sort((a, b) => a.price - b.price);
      }
 
      if (newFilters.verifiedProfile) {
        filteredRides = filteredRides.filter((ride) => ride.verifiedProfile);
      }
 
      if (newFilters.instantBooking) {
        filteredRides = filteredRides.filter(
          (ride) => !ride.instantbooking.includes("notinstantbooked")
        );
      }
 
      if (newFilters.smokingAllowed) {
        filteredRides = filteredRides.filter(
          (ride) => !ride.smoke.includes("No smoking")
        );
      }
 
      if (newFilters.petsAllowed) {
        filteredRides = filteredRides.filter((ride) =>
          ride.pet.includes("I love pets. Woof!")
        );
      }
 
      setSearchResults(filteredRides);
 
      return newFilters;
    });
  };
 
  const handleClearAllFilters = () => {
    setFilters({
      lowestPrice: false,
 
      verifiedProfile: false,
 
      instantBooking: false,
 
      smokingAllowed: false,
 
      petsAllowed: false,
    });
 
    handleSearch();
  };
 
  const renderIcon = (filter) => {
    switch (filter) {
      case "lowestPrice":
        return <FaRupeeSign />;
 
      case "verifiedProfile":
        return <MdVerifiedUser />;
 
      case "instantBooking":
        return <MdBookmark />;
 
      case "smokingAllowed":
        return <MdSmokingRooms />;
 
      case "petsAllowed":
        return <MdPets />;
 
      default:
        return null;
    }
  };
 
  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }
 

 
  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];
 
  const formatLabel = (filter) => {
    return filter
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };
 
  return (
    <div>
      <div>
        <div>
          <UsersNavbar username={username} />
        </div>
      </div>
 
      <div className="ls-mainbb">
        <div className="ls-search-container">
          <div className="ls-search-inputs">
            <div className="ls-gapsu1">
              <div className="ls-sectt">
                <MdRadioButtonChecked className="ls-radio-icon" />
 
                <Select
                  value={fromOptions.find((option) => option.value === from)}
                  onChange={handleFromChange}
                  options={fromOptions}
                  className="ls-leaving"
                  placeholder="Leaving from"
                  styles={{
                    control: (provided) => ({ ...provided, height: "60px" }),
                  }}
                />
              </div>
 
              <LuArrowDownUp className="ls-arrow-icon" />
 
              <div className="ls-sectp">
                <MdRadioButtonChecked className="ls-radio-icon" />
 
                <Select
                  value={toOptions.find((option) => option.value === to)}
                  onChange={handleToChange}
                  options={toOptions}
                  className="ls-leaving"
                  placeholder="Going to"
                  styles={{
                    control: (provided) => ({ ...provided, height: "60px" }),
                  }}
                />
              </div>
            </div>
 
            <div className="ls-gapsu1">
              <div className="ls-sectt">
                <IoCalendarOutline className="ls-calendar-icon" />
 
                <input
                  type="date"
                  value={date}
                  className="ls-cal"
                  onChange={(e) => setDate(e.target.value)}
                  min={today}
                />
              </div>
 
              <div className="ls-passenger-input">
                <FaRegUser className="ls-user-icon" />
 
                <input
                  type="text"
                  className="ls-passenger-count"
                  value={`${passengers} Passenger${
                    passengers !== 1 ? "s" : ""
                  }`}
                  readOnly
 
                  // styles={{ control: provided => ({ ...provided, height: '60px' }) }}
                />
 
                <div className="ls-passenger-controls">
                  <FaMinus
                    className="ls-passenger-minus-icon"
                    onClick={handleDecrement}
                  />
 
                  <input
                    type="number"
                    className="ls-passenger-customm"
                    value={passengers}
                    onChange={(e) => setPassengers(e.target.value)}
                    min="1"
                    max="8"
                  />
 
                  <FaPlus
                    className="ls-passenger-plus-icon"
                    onClick={handleIncrement}
                  />
                </div>
              </div>
            </div>
 
            <div className="search-container">
              <button
                onClick={handleSearch}
                disabled={loading}
                className="ls-search"
              >
                Search
              </button>
            </div>
          </div>
 
          {loading && <p>Loading...</p>}
 
          {error && <p>{error}</p>}
 
          {showAdditionalInfo && (
            <div className="ls-results-container">
              <div className="ls-search-results">
                <h2 className="ls-res">Search Results</h2>
 
                <ul>
                  {invalidData ? (
                    <>
                      <h1 className="filter">No Filter available</h1>
 
                      <img
                        src={bagg}
                        alt="No rides available"
                        className="baggg"
                      />
 
                      <li className="ls-res1">
                        There are no rides yet for this date between{" "}
                        {invalidFrom} and {invalidTo}
                      </li>
 
                      {invalidFrom === from && invalidTo === to && (
                        <li className="ls-create-ride-button1-container">
                          <Link
                            to="/Pickingup"
                            className="ls-create-ride-button1"
                          >
                            Create Ride
                          </Link>
                        </li>
                      )}
                    </>
                  ) : (
                    <>
                      <li className="ls-inno">
                        <strong>{`${from}, ${to}:`}</strong>{" "}
                        {`${searchResults.length} rides available`}
                      </li>
 
                      <div className="ls-divide">
                        {searchResults.map((ride, index) => (
                          <li className="ls-inn" key={index}>
                            {ride.passengers === 0 ? (
                              <div
                                className={`ls-river ${
                                  isDisabled ? "disabled" : ""
                                }`}
                              >
                                <div className="ride-info">
                                  <div className="lscard">
                                    <div className="lscard-details">
                                      <div className="lscard-location-full">
                                        <div className="lscard-ride-info-full">
                                          {ride && (
                                            <div>
                                              <div className="lscard-date-full">
                                                {formatDate(ride.date)}
                                              </div>
                                              <div className="SA-rider-full-card">
                                                <p><IoLocationSharp /> <b>Ride:</b>{" "} {ride.leavingfrom}{" "}
                                                  <MdOutlineArrowForward className="lscard-ride" />{" "}
                                                  {ride.goingto}
                                                </p>
                                                <p className="SA-ride-pick-up"><CgSandClock /> <b>PickUp Time:</b>{" "}
                                                  {ride?.time}
                                                </p>
                                                <p className="SA-ride-pick-up"><FaUserClock /> <b>DropOff Time:</b>
                                                  {ride?.timedrop}
                                                </p>
                                                <p className="SA-ride-pick-up">
                                                 <IoMdAlarm /> <b>Duration:</b>{" "}
                                                 {ride?.duration} hrs
                                                </p>
                                                <p className="SA-ride-pick-up">
                                                  <FaRupeeSign className="lscard-price-icon-full" />
                                                  <b className="lscard-seats-price-full">
                                                    Price:
                                                  </b>
                                                  {ride?.price}
                                                </p>
                                                <p className="SA-ride-pick-up">
                                                <GrGroup className="lscard-pricenoof-full" />{" "}
                                                <b>Total Seats: </b> {ride.totalseats}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
 
                                        <div className="lscard-user">
                                        <div className="lscard-seats-avail-full">
                                          {/* No. of seats: {ride.passengers} */}
                                          <span className="full-text-full">
                                            Full
                                          </span>{" "}
                                        </div>
                                        <img
                                          src={`https://api.ezyrides.chitgenius.com/uploads/${ride?.profileImage}`}
                                          className="lscard-user-image-full"
                                          alt="User Profile"
                                        />
                                        <div className="lscard-seats-name-full">
                                          Name: {ride.firstName}
                                        </div>
                                      </div>
                                      </div>
                                 
 
                                     
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <Link
                                to={`/info/${ride.riderbooking_id}`}
                                className="ride-link"
                              >
                                <div className="ride-info">
                                  <div className="lscard">
                                    <div className="lscard-details">
                                      <div className="lscard-location">
                                        <div className="lscard-ride-info">
                                          {ride && (
                                            <div>
                                              <div className="lscard-date">
                                                {formatDate(ride.date)}
                                              </div>
                                              <div className="SA-ride-normal-card">
                                                <p>
                                                  <IoLocationSharp /> <b>Ride:</b>{" "} {ride.leavingfrom}{" "}
                                                  <MdOutlineArrowForward className="lscard-arrow" />{" "}
                                                  {ride.goingto}
                                                </p>
                                                <p className="SA-ride-pick-up"><CgSandClock /> <b>PickUp Time:</b>{" "}
                                                  {ride?.time}
                                                </p>
                                                <p className="SA-ride-pick-up">
                                                  <FaUserClock /> <b>DropOff Time:</b>
                                                  {ride?.timedrop}
                                                </p>
                                                <p className="SA-ride-pick-up">
                                                  <IoMdAlarm /> <b>Duration:</b>{" "}
                                                  {ride?.duration} hrs
                                                </p>
                                                <p className="SA-ride-pick-up">
                                                  <FaRupeeSign  />
                                                  <b className="lscard-price1-price">
                                                    Price :{" "}
                                                  </b>
                                                  {ride?.price}
                                                </p>
                                                <p className="SA-ride-pick-up">
                                                  <GrGroup className="lscard-pricenoof" />{" "}
                                                  <b>Total Seats: </b>{" "}
                                                  {ride.totalseats}
                                                </p>
                                              </div>
                                             
                                            </div>
                                          )}
                                        </div>
 
                                        <div className="SA-lscard-user">
                                        <div className="lscard-seats-avail">
                                          Available seats: {ride.passengers}
                                        </div>
                                        <img
                                          src={`https://api.ezyrides.chitgenius.com/uploads/${ride?.profileImage}`}
                                          className="lscard-user-image"
                                          alt="User Profile"
                                        />
                                        <div className="lscard-seats-name">
                                          <b> Name:</b> {ride.firstName}
                                        </div>
 
                                        {/* <h4 className="lscard-user-id">Rahul</h4> */}
                                      </div>
 
                                       
                                      </div>
 
                                     
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            )}
                          </li>
                        ))}
                      </div>
 
                      <li className="ls-create-ride-button-container">
                        <Link to="/Pickingup" className="ls-create-ride-button">
                          Create Ride
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </div>
 
              {!invalidData && (
                <div className="lsh-filter-container">
                  <div className="lsh-filter-header">
                    <h3 className="lsh-fli">Filters</h3>
 
                    <button
                      className="lsh-clear-filters"
                      onClick={handleClearAllFilters}
                    >
                      Clear All Filters
                    </button>
                  </div>
 
                  <div className="lsh-filter-options">
                    {Object.keys(filters).map((filter) => (
                      <div key={filter} className="lsh-filter-option">
                        <label htmlFor={filter}>
                          {renderIcon(filter)}
 
                          {formatLabel(filter)}
                        </label>
 
                        <div
                          className="ls-filter-checkbox"
                          onClick={() => handleFilterChange(filter)}
                        >
                          {filters[filter] ? (
                            <IoMdCheckbox className="ls-checked-icon" />
                          ) : (
                            <MdCheckBoxOutlineBlank className="ls-unchecked-icon" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
 
export default Ser;
 
 