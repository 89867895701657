import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ReportDetails.css';
import SAnavbar from '../components/SuperAdminNavbars/SAnavbar';
import {jwtDecode }from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { IoArrowBackSharp } from "react-icons/io5";

import Cookies from 'js-cookie';
 
const ReportDetails = () => {
  const [report, setReport] = useState(null);
  const [reportCount, setReportCount] = useState(0);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
 
  const [role, setRole] = useState('');
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'superadmin') {
        navigate('/');
      }
    }
  }, []);
 
  const { report_id } = useParams();
 
  useEffect(() => {
    axios.get(`https://api.ezyrides.chitgenius.com/api/reports/${report_id}`)
      .then(response => {
        if (response.data.success) {
          setReport(response.data.report);
          const riderId = response.data.report.Reported_riderid;
          return axios.get(`https://api.ezyrides.chitgenius.com/api/reports/count/${riderId}`);
        } else {
          console.error('Error fetching report details:', response.data.message);
        }
      })
      .then(response => {
        if (response && response.data.success) {
          setReportCount(response.data.count);
        }
      })
      .catch(error => {
        console.error('Error fetching report details or count:', error);
      });
  }, [report_id]);
 
  return (
    <div>  
      <div><SAnavbar username={username} /></div>
      <div className="report-details-container">
      
        {report && (
          <>
          
          <table className="report-details-table">
          
            <h2 className="report-details-heading">Report Details</h2> <button onClick={() => navigate('/Reports')} className="report-button-page">
          <IoArrowBackSharp /> Previous Page
        </button>
            <tbody>
              <tr>
                <td><strong>Report ID:</strong></td>
                <td>{report.Report_id}</td>
              </tr>
              <tr>
                <td><strong>User ID:</strong></td>
                <td>{report.User_id}</td>
              </tr>
              <tr>
                <td><strong>Ride Booking ID:</strong></td>
                <td>{report.riderbooking_id}</td>
              </tr>
              <tr>
                <td><strong>Rider ID:</strong></td>
                <td>{report.Reported_riderid}</td>
              </tr>
              <tr>
                <td><strong>What do you want to report:</strong></td>
                <td>{report.wan_to_report_you_want_to_report}</td>
              </tr>
              <tr>
                <td><strong>What happened exactly:</strong></td>
                <td>{report.exactly_happened}</td>
              </tr>
              <tr>
                <td><strong>Please tell us more about:</strong></td>
                <td>{report.tell_us_about_and_more}</td>
              </tr>
              <tr>
                <td><strong>No of Reports on this Rider:</strong></td>
                <td>{reportCount}</td>
              </tr>
            </tbody>
          </table>
          </>
        )}
        
      </div>
      
    </div>
  );
};
 
export default ReportDetails;