 
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import './Edittraveldata.css';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import UsersNavbar from '../Landingpages/UsersNavbar';
 
const Edittraveldata = () => {
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const { id } = useParams();
  const [userId, setUserId] = useState({});
  const [riderInfo, setRiderInfo] = useState(null);
  const [data, setData] = useState({});
  const [selectedMusicPreference, setSelectedMusicPreference] = useState("");
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSmokingPreference, setSelectedSmokingPreference] = useState("");
  const [selectedPetPreference, setSelectedPetPreference] = useState("");
  const [selectedVehicleNumber, setSelectedVehicleNumber] = useState("");
  const [musicPreferences, setMusicPreferences] = useState([]);
  const [smokingPreferences, setSmokingPreferences] = useState([]);
  const [petPreferences, setPetPreferences] = useState([]);
  const [instantBookings, setInstantBookings] = useState([]);
  const [selectedInstantBooking, setSelectedInstantBooking] = useState("");
  const [manualVehicleNumber, setManualVehicleNumber] = useState("");
  const [manualColor, setManualColor] = useState("");
  const [brandId, setBrandId] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  const [travelPreferences, setTravelPreferences] = useState({});
  const [vehicleNumberError, setVehicleNumberError] = useState("");
  const [errors, setErrors] = useState({});
 
  const navigate = useNavigate();
 
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://api.ezyrides.chitgenius.com/api/getmemberdetails/afterlogin/${username}`
        );
 
        if (response.data.length > 0) {
          setUserId(response.data[0].id);
          console.log(response.data[0].id);
        } else {
          console.error("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    if (username) {
      fetchUserData();
    }
  }, [username]);
 
  console.log("userId", userId);
 
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://api.ezyrides.chitgenius.com/api/userdetails/${username}`
        );
        setData({ ...response.data[0] });
      } catch (error) {
        console.error("Error fetching assessment data", error);
      }
    };
    fetchUserData();
  }, [username]);
 
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(
          `https://api.ezyrides.chitgenius.com/api/userinfodetails/afterlogin/${userId}`
        );
 
        if (response.data.length > 0) {
          setRiderInfo(response.data[0]);
          setManualColor(response.data[0].color);
          setManualVehicleNumber(response.data[0].vehicle_num);
          console.log(response.data[0]);
        } else {
          console.error("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    if (userId) {
      fetchUserInfo();
    }
  }, [userId]);
  console.log("riderId", riderInfo);
 
  useEffect(() => {
    fetchMusicPreferences();
    fetchSmokingPreferences();
    fetchPetPreferences();
    fetchBrands();
    fetchInstantBookings();
  }, []);
 
  const fetchMusicPreferences = async () => {
    try {
      const response = await fetch('https://api.ezyrides.chitgenius.com/api/music-preferences');
      if (!response.ok) {
        throw new Error('Failed to fetch music preferences');
      }
      const data = await response.json();
      setMusicPreferences(data);
    } catch (error) {
      console.error('Error fetching music preferences:', error);
    }
  };
 
  const fetchSmokingPreferences = async () => {
    try {
      const response = await fetch('https://api.ezyrides.chitgenius.com/api/smoking-preferences');
      if (!response.ok) {
        throw new Error('Failed to fetch smoking preferences');
      }
      const data = await response.json();
      setSmokingPreferences(data);
    } catch (error) {
      console.error('Error fetching smoking preferences:', error);
    }
  };
 
  const fetchPetPreferences = async () => {
    try {
      const response = await fetch('https://api.ezyrides.chitgenius.com/api/pet-preferences');
      if (!response.ok) {
        throw new Error('Failed to fetch pet preferences');
      }
      const data = await response.json();
      setPetPreferences(data);
    } catch (error) {
      console.error('Error fetching pet preferences:', error);
    }
  };
 
  const fetchBrands = async () => {
    try {
      const response = await axios.get(
        'https://api.ezyrides.chitgenius.com/api/brands'
      );
      setBrands(response.data);
    } catch (error) {
      console.error('Error fetching brands:', error);
      setBrands([]);
    }
  };
 
  const fetchInstantBookings = async () => {
    try {
      const response = await fetch('https://api.ezyrides.chitgenius.com/api/instantbookings');
      if (!response.ok) {
        throw new Error('Failed to fetch instant bookings');
      }
      const data = await response.json();
      setInstantBookings(data);
    } catch (error) {
      console.error('Error fetching instant bookings:', error);
    }
  };
 
  useEffect(() => {
    if (selectedBrand) {
      fetchModels(selectedBrand);
    }
  }, [selectedBrand]);
 
  const fetchModels = async (brandName) => {
    try {
      const response = await axios.get(`https://api.ezyrides.chitgenius.com/brands/models/${brandName}`);
      setModels(response.data);
    } catch (error) {
      console.error('Error fetching models:', error);
      setModels([]);
    }
  };
 
  useEffect(() => {
    if (brandId) {
      fetchModels();
    }
  }, [brandId]);
 
  console.log("id is", data.id);
 
 
  const handleSubmit = async () => {
    const data = {
      music: selectedMusicPreference,
      smoke: selectedSmokingPreference,
      pet: selectedPetPreference,
      brands: selectedBrand,
      models: selectedModel,
      color: manualColor,
      vehicle_num: manualVehicleNumber,
     
      instantbooking: selectedInstantBooking,
    };
   
    let validationErrors = {};
    if (!selectedMusicPreference) {
      validationErrors.musicPreference = 'Please select a music preference.';
    }
    if (!selectedSmokingPreference) {
      validationErrors.smokingPreference = 'Please select a smoking preference.';
    }
    if (!selectedPetPreference) {
      validationErrors.petPreference = 'Please select a pet preference.';
    }
    if (!selectedBrand) {
      validationErrors.brand = 'Please select a vehicle brand.';
    }
    if (!selectedModel) {
      validationErrors.model = 'Please select a vehicle model.';
    }
    if (!manualColor) {
      validationErrors.color = 'Please enter the vehicle color.';
    }
   
    if (!selectedInstantBooking) {
      validationErrors.instantBooking = 'Please select an instant booking option.';
    }
 
    const alphanumericRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/;
    if (!alphanumericRegex.test(manualVehicleNumber)) {
      setVehicleNumberError('Enter valid vehicle number');
      return;
    }
 
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
 
    setErrors({});
    try {
      const response = await axios.put(`https://api.ezyrides.chitgenius.com/api/travel_preferences/${userId}`, data);
 
      if (response.status === 200) {
        setSaveStatus('success');
        setTimeout(() => {
            setSaveStatus(null);
            setVehicleNumberError("");  
          }, 2000);
      } else {
        setSaveStatus('error');
      }
    } catch (error) {
        console.error('Error updating travel preferences:', error);
        if (error.response && error.response.data && error.response.data.message) {
          setVehicleNumberError('Enter valid vehicle number');  // Set error message from backend response if needed
        } else {
          setVehicleNumberError('Error updating travel preferences. Please try again.');
        }
        setSaveStatus('error');
      }
  };
 
 
 
  const handleBrandSelection = async (selectedOption) => {
    setSelectedBrand(selectedOption.value);
    setSelectedModel(null);
    await fetchModels(selectedOption.value);
  };
 
  const handleModelSelection = (selectedOption) => {
    setSelectedModel(selectedOption ? selectedOption.value : null);
  };
 
  const handleManualVehicleNumberChange = (e) => {
    setManualVehicleNumber(e.target.value);
    setSelectedVehicleNumber("");
  };
 
  const handleManualColorChange = (e) => {
    setManualColor(e.target.value);
    setSelectedColor("");
  };
 
  useEffect(() => {
    fetchBrands();
  }, []);
 
  useEffect(() => {
    const fetchTravelPreferences = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `https://api.ezyrides.chitgenius.com/travelPreferences/${userId}`,
          config
        );
        setTravelPreferences(response.data);
      } catch (error) {
        console.error("Error fetching travel preferences:", error);
      }
    };
 
    fetchTravelPreferences();
  }, []);
 
  return (
    <>
      <div className="lii-users"><UsersNavbar username={username} /></div>
      <div className="edit-center-container">
        <div className="edit-travel-preferences">
          <div className="edit-sections-contents">
            <h1 className="edit-travel-edit">Edit Travel Preferences 
          
            <Link to="/profileinfo" className="edit-travel-backsss">Back</Link>
          
            </h1>

            <div className="edit-preferences">
              <div className="edit-preference">
                <label htmlFor="musicPreferencesDropdown" className="edit-custom-label">Music</label>
                <select id="musicPreferencesDropdown" className="edit-custom-dropdown" value={selectedMusicPreference} onChange={(e) => setSelectedMusicPreference(e.target.value)}>
                  <option value="">Select Music Preference</option>
                  {musicPreferences.map(preference => (
                    <option key={preference.id} value={preference.id}>{preference.name}</option>
                  ))}
                </select>
                {errors.musicPreference && <p className="tp-error-message">{errors.musicPreference}</p>}
              </div>
              <div className="edit-preference">
                <label htmlFor="smokingPreferencesDropdown" className="edit-custom-label">Smoking</label>
                <select id="smokingPreferencesDropdown" className="edit-custom-dropdown" value={selectedSmokingPreference} onChange={(e) => setSelectedSmokingPreference(e.target.value)}>
                  <option value="">Select Smoking Preference</option>
                  {smokingPreferences.map(preference => (
                    <option key={preference.id} value={preference.id}>{preference.name}</option>
                  ))}
                </select>
                {errors.smokingPreference && <p className="tp-error-message">{errors.smokingPreference}</p>}
              </div>
 
              <div className="edit-preference">
                <label htmlFor="instantBookingDropdown" className="edit-custom-label">Instant Booking</label>
                <select id="instantBookingDropdown" className="edit-custom-dropdown" value={selectedInstantBooking} onChange={(e) => setSelectedInstantBooking(e.target.value)}>
                  <option value="">Select Instant Booking</option>
                  {instantBookings.map((booking) => (
                    <option key={booking.id} value={booking.name}>
                    {booking.name}
                  </option>
                  ))}
                </select>
                {errors.instantBooking && <p className="tp-error-message">{errors.instantBooking}</p>}
              </div>
              <div className="edit-preference">
                <label htmlFor="petsPreferencesDropdown" className="edit-custom-label">Pets</label>
                <select id="petsPreferencesDropdown" className="edit-custom-dropdown" value={selectedPetPreference} onChange={(e) => setSelectedPetPreference(e.target.value)}>
                  <option value="">Select Pet Preference</option>
                  {petPreferences.map(preference => (
                    <option key={preference.id} value={preference.id}>{preference.name}</option>
                  ))}
                </select>
                {errors.petPreference && <p className="tp-error-message">{errors.petPreference}</p>}
              </div>
            </div>
          </div>
          <div className="edit-sections-contents">
            <h1 className="edit-travel">Vehicle</h1>
            <div className="edit-vehicle-preferences">
              <div className="edit-preference">
                <label htmlFor="vehicleBrandDropdown"><strong>What's your vehicle's brand?</strong></label>
                <Select
                  value={selectedBrand ? { value: selectedBrand, label: selectedBrand } : null}
                  onChange={handleBrandSelection}
                  options={brands.map(brand => ({
                    value: brand.brand_name,
                    label: brand.brand_name
                  }))}
                  isClearable
                  placeholder="Search brands..."
                  className="edit-custom-dropdown"
                  classNamePrefix="react-select"
                />
                {errors.brand && <p className="tp-error-message">{errors.brand}</p>}
              </div>
              <div className="edit-preference">
                <label htmlFor="vehicleModelDropdown"><strong>Vehicle Model</strong></label>
                <Select
                  id="vehicleModelDropdown"
                  value={selectedModel ? { value: selectedModel, label: selectedModel } : null}
                  onChange={handleModelSelection}
                  options={models.map(model => ({
                    value: model.model_name,
                    label: model.model_name
                  }))}
                  isClearable
                  placeholder="Select model..."
                  className="edit-custom-dropdown"
                  classNamePrefix="react-select"
                />
                 {errors.model && <p className="tp-error-message">{errors.model}</p>}
              </div>
              <div className="edit-preference">
                <label htmlFor="manualColorInput" className="edit-custom-label">Enter Color</label>
                <input
                  type="text"
                  id="manualColorInput"
                  className="edit-custom-input"
                  value={manualColor}
                  onChange={handleManualColorChange}
                  placeholder="Enter vehicle color manually"
                />
                {errors.color && <p className="tp-error-message">{errors.color}</p>}
              </div>
              <div className="edit-preference">
                <label htmlFor="manualVehicleNumberInput" className="edit-custom-label">Enter Vehicle Number</label>
                <input
                  type="text"
                  id="manualVehicleNumberInput"
                  className="edit-custom-input"
                  value={manualVehicleNumber}
                  onChange={handleManualVehicleNumberChange}
                  placeholder="Enter vehicle number manually"
                />
                  {vehicleNumberError && <p className="edit-error-message">{vehicleNumberError}</p>}
              </div>
            </div>
            <button type="edit-button" className="edit-custom-links" onClick={handleSubmit}>Update</button>
            {saveStatus === 'success' && <p className="edit_save-success">Data saved successfully!</p>}
            {saveStatus === 'error' && <p className="edit_save-error">Error saving data. Please try again.</p>}
          </div>
        </div>
      </div>
    </>
  );
};
 
export default Edittraveldata;
 
 