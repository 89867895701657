import React, { useState } from "react";
import "./Lghelp.css";

const Lghelp = () => {
  const [expanded, setExpanded] = useState({
    para1: false,
    para2: false,
    para3: false,
    para4: false,
    para5: false,
    para6: false,
  });

  const toggleExpanded = (para) => {
    setExpanded({ ...expanded, [para]: !expanded[para] });
  };

  return (
    <div>
      <h6 className="lg-help">Carpool Help Centre</h6>
      <div className="lg-flex-container">
        {/* First column */}
        <div className="lg-content-1">
          <h4 className="lg-howdo-1">Easy Steps to Book Your Carpool Ride?</h4>
          <p className={expanded.para1 ?"md-expanded" : "md-collapsed"}>
          Booking a carpool ride is a breeze with Eazy ride! Whether
           you prefer our mobile app or Eazy ride.com, the process
            is straightforward. Just search for your destination, 
            select your travel date, and choose the carpool that 
            fits your needs. Some rides are available for instant
             booking, while others require driver approval. 
             Regardless, securing 
          your carpool ride is quick, hassle-free, and convenient. </p>
          
           <hr className="lg-more-hr"/>
        </div >
        
        <div className="lg-content-2">
          <h4 className="lg-howdo-1">Share Your Ride: A Quick Guide to Posting Your Carpool</h4>
          <p className={expanded.para2 ? "md-expanded" : "md-collapsed"}>
          Ready to share your journey? It's simple with Eazy ride. 
          Just hop onto our mobile app or Eazy ride.com to publish 
          your ride hassle-free. Provide details like your departure 
          and arrival points, departure time, available seats, and
           seat price. You'll also decide whether to accept bookings 
           automatically or manually. With these straightforward 
           steps, you'll
           be offering your carpool ride in no time at all!
          </p>
         
           <hr className="lg-more-hr"/>
        </div>
        {/* Second column */}
        <div className="lg-content-3">
          <h4 className="lg-howdo-1">Canceling Your Carpool Ride: A Step-by-Step Guide</h4>
          <p className={expanded.para3 ? "md-expanded" : "md-collapsed"}>
          Need to change your plans? Canceling your carpool ride is simple with Eazy ride.
           Just head to the 'Your rides' section of our app and follow the prompts.
            It's best to cancel as soon as possible to give the driver time to find new passengers.
             Your refund amount depends on how far in advance you cancel. For instance,
              canceling more than 24 hours before departure typically results in a full refund, 
              minus the service fee. Follow these steps for a seamless cancellation process.
          </p>
         
           <hr className="lg-more-hr"/>
        </div>
        <div className="lg-content-4">
          <h4 className="lg-howdo-1">Advantages of Choosing Carpooling for Your Travels</h4>
          <p className={expanded.para4 ? "md-expanded" : "md-collapsed"}>
          Discover the Benefits of Carpooling for Your Journeys!
          Opting for carpooling offers numerous advantages compared 
          to other modes of transportation. Not only is it typically more cost-effective,
           especially for longer distances, but it's also environmentally friendly. By sharing 
           a ride, there are fewer cars on the road, leading to reduced emissions. Additionally,
            carpooling provides a safer travel option, particularly in today's circumstances. 
            Experience the perks of carpooling on your next journey!
          </p>
         
           <hr className="lg-more-hr"/>
        </div>
        <div className="lg-content-5">
          <h4 className="lg-howdo-1">Understanding Carpool Ride Pricing</h4>
          <p className={expanded.para5 ? "md-expanded" : "md-collapsed"}>
          Unlocking the Cost of Carpool Rides!
         Carpool ride prices are variable and influenced by several factors, 
         including distance, departure time, demand, and more. With the flexibility
          for drivers to set their own seat prices, pinpointing an exact cost can be challenging.
           Explore some of our popular carpool destinations for price insights, or kickstart your search for 
           the perfect ride on Eazy ride.com. Discover the diverse pricing landscape of carpooling!
          </p>
         
           <hr className="lg-more-hr"/>
        </div>
        
        <div className="lg-content-6">
          <h4 className="lg-howdo-1">Getting Started with Carpooling</h4>
          <p className={expanded.para6 ? "md-expanded" : "md-collapsed"}>
          Embark on Your Carpooling Journey with Ease!
          Beginning your carpooling adventure with Eazy ride 
          is both simple and free! Just create an account and 
          provide some basic information about yourself. Once your 
          Eazy ride account is set up, you're all set to either book rides or offer 
          your own directly through our user-friendly app or website. Start your carpooling experience today!
          </p>
        
           <hr className="lg-more-hr"/>
        </div>
      </div>
      {/* <div>
        <button className="lr-Readour-1">Read our Help Centre</button>
      </div> */}
    </div>
  );
};

export default Lghelp;
