import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { toast } from "react-toastify";
import { format } from 'date-fns';
import './Userrrides.css';
import SuccessMess from './SuccessMess';
import { Link } from 'react-router-dom';
import UsersNavbar from '../Landingpages/UsersNavbar';

const Userrrides = () => {
  const { booking_id } = useParams();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [rideDetails, setRideDetails] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'user') {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'user') {
        navigate('/');
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchRideDetails = async () => {
      try {
        const response = await axios.get(`https://api.ezyrides.chitgenius.com/api/getchitid/${booking_id}`);
        console.log('Fetched ride details:', response.data);
        if (response.data && response.data.length > 0) {
          setRideDetails(response.data[0]);
          setError('');
        } else {
          setError('Ride not found');
          setRideDetails(null);
        }
      } catch (error) {
        console.error('Error fetching ride details', error);
        setError('Ride not found');
        setRideDetails(null);
      }
    };
    fetchRideDetails();
  }, [booking_id]);

  console.log('user booking data', rideDetails);

  const deleteContact = async (booking_id,riderbooking_id,Noofseatsbooked) => {
        if (window.confirm("Are you sure that you wanted to delete that contact?")) {
      try {
        await axios.delete(`https://api.ezyrides.chitgenius.com/api/remove/${booking_id}/${riderbooking_id}/${Noofseatsbooked}`);
        toast.success("Contact Deleted Successfully");
        setShowSuccess(true);
        setRideDetails(null);
      } catch (error) {
        console.error('Error cancelling ride', error);
        setError('Error cancelling ride');
      }
    }
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
    navigate('/your-rides');
  };

  return (
    <div>
      {rideDetails ? (
        <div>
    <div><UsersNavbar username={username} /></div>
          <div className="lrd-main">
            <div className='lrd-bd'>
          
            </div>
            <div className="lrd-card">
              <div className="lrd-head"></div>
              <div className="lrd-details">
                <div className="lrd-details-left">
                <h2 className='lrd-bdd'>Booking Details</h2>
                  <p>
                    <strong>Booking ID:</strong> {rideDetails.booking_id}
                  </p>
                  <p>
                    <strong>Rider ID:</strong> {rideDetails.riderbooking_id}
                  </p>
                  <p>
                    <strong>Date:</strong> {format(new Date(rideDetails.date), 'E, dd MMM')}
                  </p>
                  <p>
                    <strong>From:</strong> {rideDetails.fromto}
                  </p>
                  <p>
                    <strong>To:</strong> {rideDetails.goingto}
                  </p>
                  <p>
                    <strong>Price:</strong> {rideDetails.price}
                  </p>
              
                  <p>
                    <strong>Time:</strong> {rideDetails.time}
                  </p>
                  <p>
                    <strong>Total Booking seats:</strong> {rideDetails.Noofseatsbooked}
                  </p>
                 
                
               
                  <button className='lrd-btn-delete' onClick={() => deleteContact(rideDetails.booking_id, rideDetails.riderbooking_id,rideDetails.Noofseatsbooked)}>Cancel Your Ride</button>                </div>
              </div>
            </div>
          </div>
          {showSuccess && <SuccessMess onClose={handleCloseSuccess} />}
          {error && <div>{error}</div>}
        </div>
      ) : showSuccess ? (
        <div>
    <div><UsersNavbar username={username} /></div>
          <div className="lrd-mainn">
            <div className="lrd-cardd">
              <div className="lrd-head"></div>
              <div className="lrd-details">
                <p className="lrd-details5">Your ride has been cancelled successfully.</p>
                <button onClick={handleCloseSuccess}></button>
                <Link to="/ser?passengers=1">
                  <button className='lrd-close'>Close</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default Userrrides;
