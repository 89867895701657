import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import person from '../Images/17134403639001.jpg';
import './SuperAdminprofile.css';
import SAnavbar from '../components/SuperAdminNavbars/SAnavbar';
import {jwtDecode} from 'jwt-decode';
import Cookies from 'js-cookie';
 
const SuperAdminprofile = () => {
  const [userData, setUserData] = useState({
    phoneNumber: '',
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    profileImage: '',
  });
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'superadmin') {
        navigate('/');
      }
    }
  }, [navigate]);
 
  const fetchUserProfile = () => {
    console.log('Fetching user profile for phone number:', username);
    axios.get(`https://api.ezyrides.chitgenius.com/profile/${username}`)
      .then(response => {
        if (response.data.success) {
          console.log('Fetched user data:', response.data.user);
          setUserData(response.data.user); // Set userData instead of formData
        } else {
          console.error('User not found');
        }
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  };
 
 
  useEffect(() => {
    fetchUserProfile();
 
  })
 
  console.log("data",userData);
  const renderProfileImage = () => {
    if (userData.profileImage) {
      return <img src={`https://api.ezyrides.chitgenius.com/uploads/${userData.profileImage}`} alt="Profile" className="suam_profile-image" />;
    } else {
      return <img src={person} alt="Default" className="suam_profile-image" />;
    }
  };
 
  return (
    <div>
      <div><SAnavbar username={username} /></div>
      <div className='suam-head'>
        <div className="suam_profile-main">
          <div className="suam_profile-form">
            <p className="suam_complete-profile-text">Complete your profile here <Link to={`/Suprofile/${userData.id}`}>Edit</Link></p>
            <div className="suam_profile-form-row-1">
              <div className="suam_profile-form-group-1">
                {renderProfileImage()}
                <label className='suam_profile-form-profile'>Profile Image</label>
               
              </div>
            </div>
            <div className="suam_profile-form-row">
              <div className="suam_profile-form-group">
                <label>First Name:</label>
                <input type="text" className="suam_profile-textbox" value={userData.firstName} readOnly />
              </div>
              <div className="suam_profile-form-group">
                <label>Last Name:</label>
                <input type="text" className="suam_profile-textbox" value={userData.lastName} readOnly />
              </div>
            </div>
            <div className="suam_profile-form-row">
              <div className="suam_profile-form-group">
                <label>Date of Birth:</label>
                <input type="text" className="suam_profile-textbox" value={userData.dob} readOnly />
              </div>
              <div className="suam_profile-form-group">
                <label>Email:</label>
                <input type="text" className="suam_profile-textbox" value={userData.email} readOnly />
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default SuperAdminprofile;
 
 