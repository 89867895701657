import React, { useEffect, useState } from "react";
import "./Profileinfo.css";
import { IoIosAddCircleOutline } from "react-icons/io";
import axios from "axios";
import {jwtDecode} from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import UsersNavbar from "../Landingpages/UsersNavbar";

const Profileinfo = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [firstName, setFirstName] = useState("");
  const [activeTab, setActiveTab] = useState("about");
  const [profileImage, setProfileImage] = useState();
  const [userId, setUserId] = useState(null);
  const [riderInfo, setRiderInfo] = useState(null);
  const [rider_id, setRider_id] = useState(null);
  const [travelPreferences, setTravelPreferences] = useState({});
  const [emailVerification, setEmailVerification] = useState("");

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phoneNumber: "",
    password: "",
    profileImage: null,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://api.ezyrides.chitgenius.com/api/getmemberdetails/afterlogin/${username}`
        );

        if (response.data.length > 0) {
          setUserId(response.data[0].id); // Set the user ID from the fetched data
          console.log(response.data[0].id); // Log the fetched user ID
        } else {
          console.error("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    if (username) {
      fetchUserData();
    }
  }, [username]);

  console.log("userId", userId);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(
          `https://api.ezyrides.chitgenius.com/api/userinfodetails/afterlogin/${userId}`
        );

        if (response.data.length > 0) {
          setRiderInfo(response.data[0]); // Set the user ID from the fetched data
          console.log(response.data[0]); // Log the fetched user ID
        } else {
          console.error("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };
    if (userId) {
      fetchUserInfo();
    }
  }, [userId]);
  console.log("riderId", riderInfo);

  useEffect(() => {
    const fetchUserProfileInfo = async () => {
      try {
        const token = Cookies.get('token');
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        };
        const response = await axios.get('https://api.ezyrides.chitgenius.com/profileinfo', config);
        const userProfileData = response.data;
        setFormData(userProfileData.userProfile);
        setProfileImage(userProfileData.userProfile.profileImage); // Set profile image
        setEmailVerification(userProfileData.userProfile.isEmailVerified); // Set email verification status
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfileInfo();
  }, []);

  useEffect(() => {
    const fetchTravelPreferences = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `https://api.ezyrides.chitgenius.com/travelPreferences/${userId}`,
          config
        );
        setTravelPreferences(response.data);
      } catch (error) {
        console.error("Error fetching travel preferences:", error);
      }
    };

    fetchTravelPreferences();
  }, []);

  const handleEditPersonalDetails = () => {
    navigate("/userprofile");
  };

  const handleTravelPreference = () => {
    navigate("/travelpreference");
  };

  const handleEditTravelPreference = () => {
    navigate("/edittraveldata");
  };

  const handleVerifyProfileClick = () => {
    navigate("/verifyprofile");
  };


 
  

  return (
    <div>
      <div>
        <div><UsersNavbar username={username} /></div>
      </div>
      <div className="pf-main">
        <div className="pf-head"></div>
        <div className="pf-content">
          {activeTab === "about" && (
            <>
              <div className="pf-about-content">
                <div className="pf-about-content-ba">
                  <div className="pf-profile-info">
                    <h3 className="pf-name">{formData.firstName}</h3>
                  </div>
                  <div className="pf-profile-icon">
                    <img
                      src={`https://api.ezyrides.chitgenius.com/uploads/${profileImage}`}
                      className="pf-profile-image"
                    />
                    <div className="pf-Userid">{formData.id}</div>
                  </div>
                  {/* <div className="pf-profille-info-ba1">
                    <IoIosAddCircleOutline />
                    <span className="pf-add-icon">Add Profile Picture</span>
                  </div> */}
                </div>
              </div>

              <div className="pf-aboutt-content">
                <div className="pf-profille-info">
                  <div className="pf-about-content-ba1">
                    <span
                      className="pf-add-icon"
                      onClick={handleEditPersonalDetails}
                    >
                      Edit personal details
                    </span>
                  </div>
                </div>
              </div>

              <div className="pf-about-content-ba2">
                <div className="pf-profile-info-1">
                  <h4>Verify your profile</h4>
                </div>
                <div className="pf-profille-info-2">
                  <IoIosAddCircleOutline className="pf-add-iconne" />
                  <span className="pf-add-icon">{formData.phoneNumber}</span>
                </div>
                <div className="pf-profille-info-3" style={{ cursor: 'pointer' }} onClick={handleVerifyProfileClick}>
                  <IoIosAddCircleOutline className="pf-add-iconnee" />
                  <span className="pf-add-icon">{formData.email}</span>
                  {!emailVerification && (
                    <span className="verify-email-text" onClick={handleVerifyProfileClick}>
                      (Please verify your email)
                    </span>
                  )}
                  {emailVerification === 'verified' && (
                    <span className="pf-verified"> (Verified)</span>
                  )}
                </div>
              </div>

              <div className="pf-aboutt-content">
                <div className="pf-about-content-ba3">
                  <span className="pf-add-icon">
                    <button
                      onClick={handleTravelPreference}
                      className={riderInfo ? "button-disabled" : ""}
                      disabled={!!riderInfo}
                    >
                      Add Travel Preferences
                    </button>
                  </span>
                </div>
              </div>

              {riderInfo === null ? (
                <p></p>
              ) : (
                <>
                  <div className="pf-about-content-ba5">
                    <div className="pf-profille-info-pf1">
                      <span className="pf-add-icon">
                        Music: {riderInfo.music}
                      </span>
                    </div>
                    <div className="pf-profille-info-pff2">
                      <span className="pf-add-icon">
                        Pet Policy: {riderInfo.pet}
                      </span>
                    </div>
                    <div className="pf-profille-info-pfff3">
                      <span className="pf-add-icon">
                        Smoking Policy: {riderInfo.smoke}
                      </span>
                    </div>
                  </div>
                  <div className="pf-aboutt-content">
                    <div className="pf-about-content-ba4">
                      <span
                        className="pf-add-icon"
                        onClick={handleEditTravelPreference}
                      >
                        Edit Travel Preference
                      </span>
                    </div>
                  </div>

                  {/* <div className="pf-aboutt-content">
                    <div className="pf-account-content-delete">
                      <span className="pf-add-icon"
                        onClick={handleDeleteAccount}>
                          Delete Account
                      </span>
                    </div>
                  </div> */}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profileinfo;
