
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Booked.css"; // Import your CSS file for styling
import SAnavbar from "../components/SuperAdminNavbars/SAnavbar";
import {jwtDecode} from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Tabs, Tab } from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";
 
const Booked = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [month, setMonth] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [role, setRole] = useState("");
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      if (decoded.role !== "superadmin") {
        navigate("/");
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    axios
      .get("https://api.ezyrides.chitgenius.com/api/bookings")
      .then((response) => {
        console.log("Fetched bookings:", response.data.rides); // Debugging line
        setBookings(response.data.rides);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching bookings:", error);
        setError("Error fetching bookings. Please try again later.");
        setLoading(false);
      });
  }, []);
 
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  }
 
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.ezyrides.chitgenius.com/api/bookings/month",
        { month }
      );
      setSearchResult(response.data);
      setSelectedBookings([]);
      setSelectAll(false);
    } catch (error) {
      console.error(error);
    }
  };
 
  const handleSelect = (bookingId) => {
    setSelectedBookings((prevSelected) => {
      if (prevSelected.includes(bookingId)) {
        return prevSelected.filter((id) => id !== bookingId);
      } else {
        return [...prevSelected, bookingId];
      }
    });
  };
 
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedBookings([]);
    } else {
      setSelectedBookings(searchResult.map((booking) => booking.booking_id));
    }
    setSelectAll(!selectAll);
  };
 
  const handleDeleteMultiple = async () => {
    try {
      const willDelete = await Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover these bookings!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete them!",
        cancelButtonText: "No, keep them",
      });
 
      if (willDelete.isConfirmed) {
        await axios.post("https://api.ezyrides.chitgenius.com/api/bookings/delete-multiple", {
          bookingIds: selectedBookings,
        });
        setSearchResult(
          searchResult.filter(
            (booking) => !selectedBookings.includes(booking.booking_id)
          )
        );
        setSelectedBookings([]);
        setSelectAll(false);
        Swal.fire("Deleted!", "Bookings have been deleted successfully.", "success");
      }
    } catch (error) {
      console.error("There was an error deleting the bookings!", error);
    }
  };
 
  const handleDeleteSingle = async (booking) => {
    try {
      const willDelete = await Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this booking!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      });
 
      if (willDelete.isConfirmed) {
        await axios.post("https://api.ezyrides.chitgenius.com/api/bookings/delete", {
          booking_id: booking.booking_id,
          riderbooking_id: booking.riderbooking_id,
          Noofseatsbooked: booking.Noofseatsbooked,
        });
        setSearchResult(
          searchResult.filter((b) => b.booking_id !== booking.booking_id)
        );
        Swal.fire("Deleted!", "Booking has been deleted successfully.", "success");
      }
    } catch (error) {
      console.error("There was an error deleting the booking!", error);
    }
  };
 
  if (loading) {
    return <div>Loading...</div>;
  }
 
  if (error) {
    return <div>{error}</div>;
  }
 
  return (
    <div>
      <SAnavbar username={username} />
      <div className="booked-pagrmain">
        <div className="booked-headd">
          <div className="booked-main">
            <Tabs
              defaultActiveKey="home"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="home" title="All Bookings">
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Price</th>
                      <th>Time</th>
                      <th>Booking ID</th>
                      <th>Rider ID</th>
                      <th>User ID</th>
                      <th>Booking Seats</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {bookings.map((booking) => (
                      <tr key={booking.booking_id}>
                        <td>{formatDate(booking.date)}</td>
                        <td>{booking.fromto}</td>
                        <td>{booking.goingto}</td>
                        <td>{booking.price}</td>
                        <td>{booking.time}</td>
                        <td>{booking.booking_id}</td>
                        <td>{booking.riderbooking_id}</td>
                        <td>{booking.user_id}</td>
                        <td>{booking.Noofseatsbooked}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Tab>
              <Tab eventKey="profile" title="Monthwise">
                <div className="MS_bookings">
                  <form onSubmit={handleSearch} className="MS_form-container">
                    <div className="MS_months">
                      <div className="MS_monthwise-bookings">
                        <label className="MS-Smonths">
                          <strong>Months</strong>
                        </label>
                        <select
                          value={month}
                          className="MS_monthly-reports"
                          name="bookings_month"
                          onChange={(e) => setMonth(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>
                        <input
                          type="submit"
                          className="MS_search"
                          value="Search"
                        ></input>
                      </div>
                    </div>
                  </form>
                  {selectedBookings.length > 0 && (
                    <button
                      className="MS_delete-multiple-button"
                      onClick={handleDeleteMultiple}
                    >
                      Delete Selected
                    </button>
                  )}
                  <div className="MS_bookings">
                    {searchResult.length > 0 ? (
                      <div className="MS_table-bookingid">
                        <table className="MS-styled-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </th>
                              <th style={{ textAlign: "center" }}>No.</th>
                              <th style={{ textAlign: "center" }}>
                                Booking ID
                              </th>
                              <th style={{ textAlign: "center" }}>User ID</th>
                              <th style={{ textAlign: "center" }}>Date</th>
                              <th style={{ textAlign: "center" }}>Time</th>
                              <th style={{ textAlign: "center" }}>From</th>
                              <th style={{ textAlign: "center" }}>To</th>
                              <th style={{ textAlign: "center" }}>Price</th>
                              <th style={{ textAlign: "center" }}>Booking Seats</th>
                              <th style={{ textAlign: "center" }}>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {searchResult.map((result, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedBookings.includes(
                                      result.booking_id
                                    )}
                                    onChange={() =>
                                      handleSelect(result.booking_id)
                                    }
                                  />
                                </td>
                                <td>{index + 1}</td>
                                <td>{result.booking_id}</td>
                                <td>{result.user_id}</td>
                                <td>
                                  {moment(result.date).format("DD-MM-YYYY")}
                                </td>
                                <td>{result.time}</td>
                                <td>{result.fromto}</td>
                                <td>{result.goingto}</td>
                                <td>{result.price}</td>
                                <td>{result.Noofseatsbooked}</td>
                                <td>
                                  <button
                                    className="MS_delete-button"
                                    onClick={() => handleDeleteSingle(result)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        <p className="MS_nodata">No Data Available</p>
                      </div>
                    )}
                  </div>
                </div>
              </Tab>
            </Tabs>
 
            <div className="rides-container"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Booked;
 
 
 
 