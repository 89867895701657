
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { IoIosMusicalNote, IoIosArrowForward } from "react-icons/io";
import { FaRupeeSign, FaSmoking } from "react-icons/fa";
import { MdCancel, MdThumbDown, MdPets } from "react-icons/md";
import { IoArrowBackSharp } from "react-icons/io5";
import UsersNavbar from "../Landingpages/UsersNavbar";
import { GrGroup } from "react-icons/gr";
import { IoMdAlarm } from "react-icons/io";
import { FaUserClock } from "react-icons/fa";
import { CgSandClock } from "react-icons/cg";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
 
import "./Info.css";
import { MdOutlineArrowForward } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
 
import { HiArrowSmRight } from "react-icons/hi";
 
function Info() {
  const { riderbooking_id } = useParams();
  const [user, setUser] = useState({});
  const [coTravellers, setCoTravellers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
 
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const bookingResponse = await axios.get(
          `https://api.ezyrides.chitgenius.com/info/${riderbooking_id}`
        );
        const bookingData = bookingResponse.data.rideInfo;
        setUser(bookingData);
 
        try {
          const coTravelerResponse = await axios.get(
            `https://api.ezyrides.chitgenius.com/co-travelerinfo/${riderbooking_id}`
          );
          const coTravelerData = coTravelerResponse.data.coTravelerInfo;
          setCoTravellers(coTravelerData);
        } catch (error) {
          console.error(
            "Error fetching co-traveller data:",
            error.response ? error.response.data : error.message
          );
          setCoTravellers([]); // Set empty array if co-travellers fetch fails
        }
      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
        setError("Error fetching data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
 
    fetchData();
  }, [riderbooking_id]);
 
  if (loading) {
    return <div>Loading...</div>;
  }
 
  if (error) {
    return <div>{error}</div>;
  }
 
  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }
 
  return (
    <div>
      <div className="lii-users">
        <UsersNavbar username={username} />
      </div>
      <div className="li-mainbb">
        <div className="licard">
          <div className="licard-details">
            <div className="licard-location">
              <div></div>
 
              <div className="licard-ride-info">
                <button
                  onClick={() => navigate("/ser?passengers=1")}
                  className="licard-back-button"
                >
                  <FaRegArrowAltCircleLeft /> GoBack
                </button>
                <div></div>
                <div className="licard-date">{formatDate(user.date)}</div>
                {user && (
                  <div className="licard-dest">
                   <div>
                      <p>
                        <b>
                          {" "}
                          <IoLocationSharp /> Ride:{" "}
                        </b>{" "}
                        {user.leavingfrom}{" "}
                        <MdOutlineArrowForward className="licard-ride" />{" "}
                        {user.goingto}
                      </p>
                      <p className="SA-info-ride-card">
                        <CgSandClock /> <b>PickUp Time:</b> {user?.time}
                      </p>
                      <p className="SA-info-ride-card">
                        <FaUserClock /> <b>DropOff Time:</b>
                        {user?.timedrop}
                      </p>
                      <p className="SA-info-ride-card">
                        <IoMdAlarm /> <b>Duration:</b> {user?.duration} hrs
                      </p>
                      <p className="SA-info-ride-card">
                        <FaRupeeSign className="licard-price-icon" />
                        <b className="licard-price-price">Price : </b>
                        {user?.price}
                      </p>
                      <p className="SA-info-ride-card">
                        <GrGroup className="licard-pricenoof-ofseats" />{" "}
                        <b>Total Seats:</b> {user.totalseats}
                      </p>
                   </div>
                   
                  </div>
                )}
              </div>
             
             
            </div>
 
            <div className="licard-user">
              <div className="licard-seats-seats">
                Available seats: {user.passengers}
              </div>
 
              <img
                src={`https://api.ezyrides.chitgenius.com/uploads/${user?.profileImage}`}
                className="licard-user-images"
                alt="User Profile"
              />
              <div className="licard-seats-name">
                <b>Name:</b> {user.firstName}
              </div>
              {/* <h4 className="licard-user-id">{user?.user_id}</h4>
              <h4 className="licard-user-id-rider" id="droptime">{user?.rider_id}</h4> */}
            </div>
          </div>
        </div>
        <div className="li-additional-details">
          <div>
            <MdCancel />
            <span>Never cancels rides</span>
            <hr className="li-hr" />
          </div>
          <div>
            <MdThumbDown />
            <span>
              Your booking won't be confirmed until the driver approves your
              request
            </span>
            <hr className="li-hr" />
          </div>
          <div>
            {user?.smoke === "No smoking" ? <MdCancel /> : <FaSmoking />}
            <span>{user?.smoke}</span>
            <hr className="li-hr" />
          </div>
          <div>
            <MdPets />
            <span>{user?.pet}</span>
            <hr className="li-hr" />
          </div>
          <div>
            <IoIosMusicalNote />
            <span>{user?.music}</span>
            <hr className="li-hr" />
          </div>
        </div>
        <p className="li-Reportride">
          <Link to={`/Reportride/${user.riderbooking_id}/${user.rider_id}`}>
            Report ride
          </Link>
        </p>
        <hr className="li-hrr"></hr>
        <div className="li-co-travellers">
          <h3>Co-Travellers</h3>
          <p className="li-co-leavi">
            <strong>{user.leavingfrom}</strong> <HiArrowSmRight />{" "}
            <strong>{user.goingto}</strong>
          </p>
          {coTravellers.length > 0 ? (
            coTravellers.map((traveller, index) => (
              <div key={index} className="li-co-traveller">
                <div className="li-co-traveller-text">
                  <p className="li-co">
                    <strong>{traveller.firstName}</strong>
                  </p>
                </div>
                <div className="li-co-traveller-image-container">
                  <img
                    src={`https://api.ezyrides.chitgenius.com/uploads/${traveller.profileImage}`}
                    className="li-co-traveller-image"
                    alt="Co-traveller"
                  />
                  <IoIosArrowForward className="li-co-arrow" />
                </div>
              </div>
            ))
          ) : (
            <p>No co-travellers found.</p>
          )}
        </div>
        <hr className="li-hrr"></hr>
        <div className="li-bookk">
          <Link
            to={`/book/${user.riderbooking_id}/${user.rider_id}`}
            className="li-book"
          >
            Request a booking
          </Link>
        </div>
      </div>
    </div>
  );
}
 
export default Info;
 
 