
import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";
import "./Navbar.css";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "../Images/logos.png";
 
function Navbar() {
  const [data, setData] = useState([]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.ezyrides.chitgenius.com/api/get");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
 
  const navRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
 
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // This smooth scrolls to the top
    });
  };
 
  const closeNavbar = () => {
    navRef.current.classList.remove("responsive_nav");
  };
 
  const toggleNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
 
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
 
  return (
    <>
      <div className="navbar-cnavbar"></div>
      <header >
        <div>
          <Link to="/" className="navbar-linked-text">
            <div className="navbar-logo-10">
              <img src={logo} className="navbar-yaa" alt="Logo" />
            </div>
          </Link>
        </div>
        <nav ref={navRef}>
          <div className="navbar-tiger">
            <ul>
              <p className="search-main">
                <a href="#whyus" className="search-1" onClick={closeNavbar}>
                  
                </a>
              </p>
              <p className="navbar-publish-main">
                <a href="/Publishride" className="navbar-publish" onClick={closeNavbar}>
                  <IoIosAddCircleOutline className="navbar-publish-1" /> Publish a ride
                </a>
              </p>
              
              <div className="navbar-dropdown" onClick={toggleDropdown}>
                <FaRegUser className="navbar-icon" />
                <RiArrowDropDownLine className="navbar-icons" />
                {showDropdown && (
                  <div className="navbar-dropdown-content">
                    <a href="/login">Log In</a>
                    <a href="/signup">Sign Up</a>
                  </div>
                )}
              </div>
            </ul>
          </div>
          <button className="navbar-nav-btn nav-close-btn" onClick={toggleNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="navbar-nav-btn-1" onClick={toggleNavbar}>
          <FaBars />
        </button>
      </header>
    </>
  );
}
 
export default Navbar;
 