
import React, { useState, useEffect } from 'react';
import './Dash.css'; // Import your CSS file for styling
import { FaUsers, FaUser, FaCar } from 'react-icons/fa'; // Import icons from react-icons library
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import PieChartComponent from './PieChartComponent'; // Import PieChartComponent
import { jwtDecode } from 'jwt-decode';
 
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import SAnavbar from '../components/SuperAdminNavbars/SAnavbar';
 
const Dash = () => {
  const [memberCount, setMemberCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [riderCount, setRiderCount] = useState(0);
  const [monthlyRiderData, setMonthlyRiderData] = useState([]);
 
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    }
  }, []);
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'superadmin') {
        navigate('/');
      }
    }
  }, []);
 
  useEffect(() => {
    // Fetch member count
    const fetchMemberCount = async () => {
      try {
        const response = await fetch("https://api.ezyrides.chitgenius.com/api/expenses/");
        if (!response.ok) {
          throw new Error('Failed to fetch member count');
        }
        const data = await response.json();
        if (data && data.total_members !== undefined) {
          setMemberCount(data.total_members);
        } else {
          console.error('No member data found');
        }
      } catch (error) {
        console.error('Error fetching member data:', error);
      }
    };
    fetchMemberCount();
  }, []);
  useEffect(() => {
    // Fetch user count
    const fetchUserCount = async () => {
      try {
        const response = await fetch("https://api.ezyrides.chitgenius.com/api/expenses1/");
        if (!response.ok) {
          throw new Error('Failed to fetch user count');
        }
        const data = await response.json();
        if (data && data.user_count !== undefined) {
          setUserCount(data.user_count);
        } else {
          console.error('No user count data found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchUserCount();
  }, []);

 
  useEffect(() => {
    // Fetch rider count
    const fetchRiderCount = async () => {
      try {
        const response = await fetch("https://api.ezyrides.chitgenius.com/api/expenses2/");
        if (!response.ok) {
          throw new Error('Failed to fetch rider count');
        }
        const data = await response.json();
        if (data && data.totalcount !== undefined) {
          setRiderCount(data.totalcount);
        } else {
          console.error('No rider count data found');
        }
      } catch (error) {
        console.error('Error fetching rider data:', error);
      }
    };
    fetchRiderCount();
  }, []);
 
  useEffect(() => {
    // Fetch monthly rider data
    const fetchMonthlyRiderData = async () => {
      try {
        const response = await fetch("https://api.ezyrides.chitgenius.com/api/expenses10");
        if (!response.ok) {
          throw new Error('Failed to fetch monthly rider data');
        }
        const data = await response.json();
        const formattedData = data.map(item => ({
          name: item.month_name,
          rides: item.rider_count
        }));
        setMonthlyRiderData(formattedData);
      } catch (error) {
        console.error('Error fetching monthly rider data:', error);
      }
    };
    fetchMonthlyRiderData();
  }, []);
 
  return (
    <div>
      <div className='dash-main'>
        <SAnavbar username={username} />
      </div>
 
      <div className="dashboard">
        <div className='dash'>
          <div className="card">
            <h2>
              <FaUsers className="icon" /> Number of Members
            </h2>
            <p className="activity-line">{memberCount}</p>
            <div className="activity-line">
              <p>Recent Activity</p>
              <div className="line"></div>
            </div>
          </div>
          <div className="card">
            <h2>
              <FaUser className="icon" /> Number of Users
            </h2>
            <p className="activity-line">{userCount}</p>
            <div className="activity-line">
              <p>Recent Activity</p>
              <div className="line"></div>
            </div>
          </div>
          <div className="card">
            <h2>
              <FaCar className="icon" /> Number of Riders
            </h2>
            <p className="activity-line">{riderCount}</p>
            <div className="activity-line">
              <p>Recent Activity</p>
              <div className="line"></div>
            </div>
          </div>
        </div>
 
        <div className="charts-container">
          <div className="chart-container">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={monthlyRiderData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="rides" stroke="#8884d8" fill="#8884d8" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
 
          <div className="chart-container">
            <ResponsiveContainer width="100%" height="100%">
              <PieChartComponent />
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Dash;
 