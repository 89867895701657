 
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import "./Reports.css";
import SAnavbar from '../components/SuperAdminNavbars/SAnavbar';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
 
const Reports = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'superadmin') {
        navigate('/');
      }
    }
  }, [navigate]);
 
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [reportStatus, setReportStatus] = useState('Active'); // Default to 'Active'
  const [updatedReports, setUpdatedReports] = useState([]);
 
  useEffect(() => {
    axios.get('https://api.ezyrides.chitgenius.com/api/reports')
      .then(response => {
        if (response.data.success) {
          const initialReports = response.data.reports.map(report => ({
            ...report,
            status: report.status || 'Active' // Ensure status is set to 'Active' if missing
          }));
          setReports(initialReports);
          setUpdatedReports(initialReports);
        } else {
          console.error('Error fetching reports:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error fetching reports:', error);
      });
  }, []);
 
  const handleDelete = (reportId) => {
    axios.delete(`https://api.ezyrides.chitgenius.com/api/reports/${reportId}`)
      .then(response => {
        if (response.data.success) {
          setUpdatedReports(updatedReports.filter(report => report.Report_id !== reportId));
          console.log('Report deleted successfully.');
        } else {
          console.error('Error deleting report:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error deleting report:', error);  
      });
  };
 
  const handleView = (reportId) => {
    console.log(`View report with id: ${reportId}`);
  };
 
  const handleReportStatus = (report) => {
    setSelectedReport(report);
    setReportStatus(report.status || 'Active'); // Ensure default status is 'Active'
    setShowPopup(true);
  };
 
  const closePopup = () => {
    setShowPopup(false);
  };
 
  const handleStatusChange = (event) => {
    setReportStatus(event.target.value);
  };
 
  const handleUpdateStatus = () => {
    const updatedReport = { ...selectedReport, status: reportStatus };
    axios.put(`https://api.ezyrides.chitgenius.com/api/reportstatus/${selectedReport.Report_id}`, updatedReport)
      .then(response => {
        if (response.data.success) {
          const updatedReportsCopy = updatedReports.map(report =>
            report.Report_id === selectedReport.Report_id ? updatedReport : report
          );
          setUpdatedReports(updatedReportsCopy);
          setShowPopup(false);
        } else {
          console.error('Error updating report status:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error updating report status:', error);
      });
  };
 
  return (
    <div>
      <SAnavbar username={username} />
      <div className='reports-main'>
        <div className="reports-reports">
          <h2 className="reports-reports-re">Reports</h2>
        </div>
        <div className='reports-table-5'>
          <table className='reports-table'>
            <thead>
              <tr>
                <th className='reports-table-1'>Report id</th>
                <th className='reports-table-1'>User id</th>
                <th className='reports-table-1'>Ride Booking id</th>
                <th className='reports-table-1'>Reported Riderid</th>
                <th className='reports-table-1'>What do you want to report</th>
                <th className='reports-table-1'>What happened exactly</th>
                <th className='reports-table-1'>Please tell us more about</th>
                <th className='reports-table-1'>Actions</th>
                <th className='reports-table-1'>Report Status</th>
              </tr>
            </thead>
            <tbody>
              {updatedReports.map(report => (
                <tr key={report.Report_id}>
                  <td>{report.Report_id}</td>
                  <td>{report.User_id}</td>
                  <td>{report.riderbooking_id}</td>
                  <td>{report.Reported_riderid}</td>
                  <td>{report.wan_to_report_you_want_to_report}</td>
                  <td>{report.exactly_happened}</td>
                  <td>{report.tell_us_about_and_more}</td>
                  <td>
                    <div className="button-container">
                      <Link to={`/view/${report.Report_id}`}>
                        <button className="button reports-view" onClick={() => handleView(report.Report_id)}>View</button>
                      </Link>
                      <button className="button reports-delete" onClick={() => handleDelete(report.Report_id)}>Delete</button>
                      <button className="button reports-status" onClick={() => handleReportStatus(report)}>Report Status</button>
                    </div>
                  </td>
                  <td>{report.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {showPopup && (
          <div className="reports-popup">
            <div className="reports-popup-content">
              <h2>Report Status</h2>
              <div>
                <input type="radio" id="active" name="status" value="Active" checked={reportStatus === 'Active'} onChange={handleStatusChange} />
                <label htmlFor="active">Active</label>
              </div>
              <div>
                <input type="radio" id="inactive" name="status" value="Inactive" checked={reportStatus === 'Inactive'} onChange={handleStatusChange} />
                <label htmlFor="inactive">Inactive</label>
              </div>
              <button className="button update-button" onClick={handleUpdateStatus}>Submit</button>
              {/* <button className="button close-button" onClick={closePopup}>Close</button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
 
export default Reports;
 