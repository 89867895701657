
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import './City.css';
import { Tabs, Tab } from 'react-bootstrap';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import SAnavbar from '../components/SuperAdminNavbars/SAnavbar';
 
const City = () => {

  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'superadmin') {
        navigate('/');
      }
    }
  }, [navigate]);




  const [formData, setFormData] = useState({ newCityName: '' });
  const [locationInputs, setLocationInputs] = useState([{ id: 1, name: '' }]);
  const [cities, setCities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState('');
 
  useEffect(() => {
    fetchCities();
  }, []);
 
  const fetchCities = async () => {
    try {
      const response = await axios.get('https://api.ezyrides.chitgenius.com/cities/addcities');
      setCities(response.data.cities);
    } catch (error) {
      console.error('Error fetching cities', error);
    }
  };
 
  const fetchLocations = async (cityId) => {
    try {
      const response = await axios.get(`https://api.ezyrides.chitgenius.com/locations?CityID=${cityId}`);
      setLocations(response.data.locations);
    } catch (error) {
      console.error('Error fetching locations', error);
    }
  };
 
  const handleLocationInputChange = (id, value) => {
    const updatedInputs = locationInputs.map(input =>
      input.id === id ? { ...input, name: value } : input
    );
    setLocationInputs(updatedInputs);
  };
 
  const handleAddLocationInput = () => {
    setLocationInputs([...locationInputs, { id: locationInputs.length + 1, name: '' }]);
  };
 
  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setSelectedCityId(cityId);
    fetchLocations(cityId);
  };
 
  const resetForm = () => {
    setFormData({ newCityName: '' });
    setLocationInputs([{ id: 1, name: '' }]);
    setSelectedCityId('');
  };
 
  const validateInputs = () => {
    if (!formData.newCityName && !selectedCityId) {
      Swal.fire({
        title: 'Error!',
        text: 'Please fill the city input field.',
        icon: 'error',
      });
      return false;
    }
 
    const emptyLocationInputs = locationInputs.some(input => !input.name);
    if (emptyLocationInputs) {
      Swal.fire({
        title: 'Error!',
        text: 'Please fill all location input fields.',
        icon: 'error',
      });
      return false;
    }
 
    return true;
  };
 
  const handleCityAndLocationSubmit = async () => {
    if (!validateInputs()) return;
    try {
      if (formData.newCityName) {
        // Add new city
        const cityResponse = await axios.post('https://api.ezyrides.chitgenius.com/cities', { CityName: formData.newCityName });
        const newCityID = cityResponse.data.city.CityID;
 
        // Add new locations
        await Promise.all(locationInputs.map(async (input) => {
          if (input.name) {
            await axios.post('https://api.ezyrides.chitgenius.com/locations', { CityID: newCityID, LocationName: input.name });
          }
        }));
 
        // Reset form data
        resetForm();
        setFormData({ newCityName: '' });
        setLocationInputs([{ id: 1, name: '' }]);
 
        // Fetch updated data
        fetchCities();
        fetchLocations(newCityID);
 
        Swal.fire({
          title: 'Success!',
          text: 'Details saved successfully.',
          icon: 'success',
        });
      }
    } catch (error) {
      console.error('Error adding city and locations', error);
    }
  };
 
  const handleExistingCityLocationSubmit = async () => {
    if (!validateInputs()) return;
    try {
      if (selectedCityId) {
        // Add new locations to existing city
        await Promise.all(locationInputs.map(async (input) => {
          if (input.name) {
            await axios.post('https://api.ezyrides.chitgenius.com/locations', { CityID: selectedCityId, LocationName: input.name });
          }
        }));
 
        // Reset location inputs
        resetForm();
        setLocationInputs([{ id: 1, name: '' }]);
 
        // Fetch updated data
        fetchLocations(selectedCityId);
 
        Swal.fire({
          title: 'Success!',
          text: 'Details saved successfully.',
          icon: 'success',
        });
      }
    } catch (error) {
      console.error('Error adding locations to existing city', error);
    }
  };
 
  return (
    <div>
            <SAnavbar username={username} />

      <div className="lca-containerr-city">
        <div className='lca-container-city'>
          <div className='lca-tab-container-city'>
            <Tabs defaultActiveKey="home" id="justify-tab-example" className="mb-3" justify>
              <Tab eventKey="home" title="Add New City & Location">
                <div className="lcg-city-container">
                  <h1>Cities</h1>
                  <div className="lcg-form-group">
                    <input
                      type="text"
                      placeholder="Add new city"
                      value={formData.newCityName}
                      onChange={(e) => setFormData({ ...formData, newCityName: e.target.value })}
                      className="lcg-input"
                    />
                    <div className="lcg-form-group">
                      {locationInputs.map(input => (
                        <div key={input.id} className="lcg-location-input-group">
                          <input
                            type="text"
                            placeholder="Add new location"
                            value={input.name}
                            onChange={(e) => handleLocationInputChange(input.id, e.target.value)}
                            className="lcg-input"
                          />
                        </div>
                      ))}
                      <button className="lcg-add-location-btn" onClick={handleAddLocationInput}>Add Location</button>
                    </div>
                    <button className="lcg-add-btn" onClick={handleCityAndLocationSubmit}>Save</button>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Add Existing City Location">
                <div className="lcg-city-container">
                  <h1>Cities</h1>
                  <div className="lcg-form-group">
                    <select
                      value={selectedCityId}
                      onChange={handleCityChange}
                      className="lcg-select"
                    >
                      <option value="">Select a city...</option>
                      {cities.map(city => (
                        <option key={city.CityID} value={city.CityID}>{city.CityName}</option>
                      ))}
                    </select>
                    <div className="lcg-form-group">
                      {locationInputs.map(input => (
                        <div key={input.id} className="lcg-location-input-group">
                          <input
                            type="text"
                            placeholder="Add new location"
                            value={input.name}
                            onChange={(e) => handleLocationInputChange(input.id, e.target.value)}
                            className="lcg-input"
                          />
                        </div>
                      ))}
                      <button className="lcg-add-location-btn" onClick={handleAddLocationInput}>Add Location</button>
                    </div>
                    <button className="lcg-add-btn" onClick={handleExistingCityLocationSubmit}>Save</button>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default City;
 
 
 
 
 
 
 