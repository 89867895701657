 
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import { Modal, Button, Table, Tabs, Tab } from 'react-bootstrap';
import SAnavbar from '../components/SuperAdminNavbars/SAnavbar';
import "./Members.css";
import { IoSearch } from "react-icons/io5";
 
const Members = () => {
  const [data, setData] = useState([]);
  const [ride, setRide] = useState([]);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [pubilshed, setPubilshed] = useState([]);
  const [selectedRiderId, setSelectedRiderId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'superadmin') {
        navigate('/');
      } else {
        loadData();
        loadDataUser();
        fetchRiders();
      }
    }
  }, [navigate]);
 
  const loadData = async () => {
    try {
      const response = await axios.get("https://api.ezyrides.chitgenius.com/api/users");
      setData(response.data.users);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
 
  const loadDataUser = async () => {
    try {
      const response = await axios.get('https://api.ezyrides.chitgenius.com/api/usersuser');
      setUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
 
  const fetchRiders = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api.ezyrides.chitgenius.com/api/usersriders', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setRide(response.data.users);
    } catch (error) {
      console.error('Error fetching riders:', error);
    }
  };
 
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this account?")) {
      try {
        const response = await axios.delete(`https://api.ezyrides.chitgenius.com/api/delete/signup/${id}`);
        if (response.data.status === 200) {
          setSuccessMessage('User deleted successfully.');
          setTimeout(() => {
            setSuccessMessage('');
          }, 3000);
          loadData();
        } else {
          console.error('Error deleting user:', response.data.message);
        }
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };
 
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
 
  const viewRiderBookings = async (riderbooking_id) => {
    try {
      console.log(`Fetching bookings for riderbooking_id: ${riderbooking_id}`);
      const response = await axios.get(`https://api.ezyrides.chitgenius.com/api/riderBookings/${riderbooking_id}`);
      if (response.data.success) {
        console.log('Bookings fetched:', response.data.bookings);
        setBookings(response.data.bookings);
        setSelectedRiderId(riderbooking_id); // Save the selected rider ID
        setShowPopup(true); // Set state to show popup
      } else {
        console.error('Error fetching bookings:', response.data.message);
      }
 
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };
 
 
 
  const viewRiderPublished = async (riderbooking_id) => {
    try {
      console.log(`Fetching bookings for riderbooking_id: ${riderbooking_id}`);
      const response = await axios.get(`https://api.ezyrides.chitgenius.com/api/riderpublished/${riderbooking_id}`);
      if (response.data.success) {
        console.log('Bookings fetched:', response.data.bookings);
        setBookings(response.data.bookings);
        setSelectedRiderId(riderbooking_id); // Save the selected rider ID
        setShowPopup(true); // Set state to show popup
      } else {
        console.error('Error fetching bookings:', response.data.message);
      }
 
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };
 
  const handleDeleteRider = async () => {
    if (!selectedRiderId) return;
 
    // Check if there are any upcoming bookings
    const now = new Date();
    const upcomingBookings = bookings.filter(booking => new Date(booking.date) > now);
 
    if (upcomingBookings.length > 0) {
      alert("The rider has upcoming rides and cannot be deleted.");
      return;
    }
 
    // Proceed with deletion
    try {
      const response = await axios.delete(`https://api.ezyrides.chitgenius.com/api/delete/rider/${selectedRiderId}`);
      if (response.data.status === 200) {
        setSuccessMessage('Rider deleted successfully.');
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
        setShowModal(false);
        loadData();
      } else {
        console.error('Error deleting rider:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting rider:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
 
  const filteredBookings = bookings.filter((booking) => {
    return (
      booking.leavingfrom.toLowerCase().includes(searchTerm.toLowerCase()) ||
      booking.goingto.toLowerCase().includes(searchTerm.toLowerCase()) ||
      booking.date.includes(searchTerm)
    );
  });
 
  return (
    <div>
      <SAnavbar username={username} />
      <div className='sstyled-main'>
        <div className='sstyled-table-5'>
          <Tabs defaultActiveKey="home" id="justify-tab-example" className="mb-3" justify>
            <Tab eventKey="home" title="All Members">
              <table className='sstyled-table'>
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Id</th>
                    <th style={{ textAlign: "center" }}>FirstName</th>
                    <th style={{ textAlign: "center" }}>LastName</th>
                    <th style={{ textAlign: "center" }}>Email</th>
                    <th style={{ textAlign: "center" }}>Date of Birth</th>
                    <th style={{ textAlign: "center" }}>Profile Image</th>
                    <th style={{ textAlign: "center" }}>Role</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.email}</td>
                      <td>{formatDate(item.dob)}</td>
                      <td>
                        {item.profileImage && (
                          <img
                            src={`https://api.ezyrides.chitgenius.com/uploads/${item.profileImage}`}
                            alt="Profile"
                            className="me-profile-image"
                          />
                        )}
                      </td>
                      <td>{item.role}</td>
                      <td style={{ display: 'flex', justifyContent: 'center' }}>
                        <button
                          className="sbtn btn-delete"
                          style={{ marginRight: '5px' }}
                          onClick={() => handleDelete(item.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tab>
 
            <Tab eventKey="profile" title="Users">
              <table className='sstyled-table'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Date of Birth</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Profile Image</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(user => (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{new Date(user.dob).toLocaleDateString()}</td>
                      <td>{user.phoneNumber}</td>
                      <td>{user.email}</td>
                      <td>
                        {user.profileImage && (
                          <img
                            src={`https://api.ezyrides.chitgenius.com/uploads/${user.profileImage}`}
                            alt="Profile"
                            className="me-profile-image"
                          />
                        )}
                      </td>
                      <td>{user.role}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tab>
 
            <Tab eventKey="ridees" title="Riders">
              <div>
                <table className='sstyled-table'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Date of Birth</th>
                      <th>Email</th>
                      <th>Profile Image</th>
                      <th>Rider id</th>
                      <th>Music Preference</th>
                      <th>Smoking Preference</th>
                      <th>Pet Preference</th>
                      <th>Vehicle Brand</th>
                      <th>Vehicle Model</th>
                      <th>Vehicle Color</th>
                      <th>Vehicle Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ride.map(user => (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>{user.firstName} {user.lastName}</td>
                        <td>{new Date(user.dob).toLocaleDateString()}</td>
                        <td>{user.email}</td>
                        <td>
                          {user.profileImage && (
                            <img
                              src={`https://api.ezyrides.chitgenius.com/uploads/${user.profileImage}`}
                              alt="Profile"
                              className="me-profile-image"
                            />
                          )}
                        </td>
                        <td>{user.rider_id}</td>
                        <td>{user.music}</td>
                        <td>{user.smoke}</td>
                        <td>{user.pet}</td>
                        <td>{user.brands}</td>
                        <td>{user.models}</td>
                        <td>{user.color}</td>
                        <td>{user.vehicle_num}</td>
                        <td>
                          <button
                            className="sbtn-btn-view"
                            onClick={() => viewRiderPublished(user.rider_id)}
                          >
                            View
                          </button>
 
 
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Tab>
 
          </Tabs>
         
        </div>
 
        {showPopup && (
          <div className="sstyled-popup">
            <div className="sstyled-popup-content">
              <span className="close" onClick={() => setShowPopup(false)}>&times;</span>
              <h2 class="rider-bookings-title">Rider Bookings</h2>
              <div className='rider-search-delete'>
              <div className='SA-rider-search-container'>
              <input
                type="text"
                placeholder="Search bookings..."
                value={searchTerm}
                onChange={handleSearch}
                className="SA-rider-search-input"
              />
              <IoSearch className="ridespage-search"/>
              </div>
              <div >
              <button
                className="sbtn-btn-delete"
                // style={{ width: '150px', marginLeft: '20px' }}
                onClick={() => handleDeleteRider(bookings.booking_id)}
              >
                Delete Rider
              </button>
              </div>
            </div>
 
           
                       
              {filteredBookings.length > 0 ? (
                <table className="sstyled-tablepopup">
                 
                  <thead>
                    <tr>
                      <th>Ride Booking ID</th>
                      <th>User  ID</th>
                      <th>Rider  ID</th>
                     
                      <th>leaving from</th>
                      <th>Pickup </th>
                      <th>going to</th>
                      <th>Dropoff</th>
                      <th>Date</th>
                      <th>Distance</th>
                      <th>Duration</th>
                     
                      <th>Add Stop</th>
                     
                      <th>Time</th>
                      <th>Passenger</th>
                      <th>Price</th>
                      <th>Timedrop</th>
                    </tr>
                  </thead>
                 
                  <tbody>
                    {filteredBookings.map((booking) => (
                      <tr key={booking.booking_id}>
 
                        <td>{booking.riderbooking_id}</td>
                        <td>{booking.user_id}</td>
                        <td>{booking.rider_id}</td>
                        <td>{booking.leavingfrom}</td>
                        <td>{booking.pickup}</td>
                        <td>{booking.goingto}</td>
                        <td>{booking.dropoff}</td>
                        <td>{booking.date}</td>
                        <td>{booking.distance}</td>
                        <td>{booking.duration}</td>
                        <td>{booking.addstop}</td>
                        <td>{booking.time}</td>
                       
                        <td>{booking.passengers}</td>
                        <td>{booking.price}</td>
                        <td>{booking.timedrop}</td>
         
                         
                      </tr>
                     
                    ))}
 
                  </tbody>
 
                </table>
               
              ) : (
                <p>No bookings found for this rider.</p>
               
              )}
            </div>
           
          </div>
        )}

      </div>
     
    </div>
  );
};
 
export default Members;
 