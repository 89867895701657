import React, { useState, useEffect, useRef } from "react";
import { IoCarOutline } from "react-icons/io5";
import { MdOutlineRadioButtonChecked, MdSecurity, MdOutlineRocketLaunch } from "react-icons/md";
import { FaUser, FaCoins } from "react-icons/fa";
import { PiChatsCircle } from "react-icons/pi";
import Video from '../Video/video.mp4';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "./Publishride.css";
import Navbar from "./Navbar";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS
import Help from "./Help";
import Footer from "./Footer";

const Publishride = () => {
  const textContents = ["First text", "Second text", "Third text"]; // Add your text contents here
  const [leavingFrom, setLeavingFrom] = useState("");
  const [goingTo, setGoingTo] = useState("");
  const [passengerCount, setPassengerCount] = useState(2);
  const videoRef = useRef(null); // Declare a ref using useRef

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoSlideActive, setIsAutoSlideActive] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const sliderImages = [
    "image1.jpg",
    "image2.jpg",
    "image3.jpg"
  ];

  useEffect(() => {
    AOS.init({
      duration: 900, // Animation duration
      once: true // Animation only occurs once
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let intervalId;

    if (isAutoSlideActive) {
      intervalId = setInterval(goToNextSlide, 3000); // Change slide every 3 seconds
    }

    return () => clearInterval(intervalId);
  }, [isAutoSlideActive, currentIndex]);

  const toggleAutoSlide = () => {
    setIsAutoSlideActive((prev) => !prev);
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sliderImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPreviousSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sliderImages.length - 1 : prevIndex - 1
    );
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop) {
      AOS.refresh();
    } else {
      AOS.refresh();
    }

    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };

  return (
    <div className="lr-main-container">
  <Navbar />
      <div className="lr-background-image-container">
        <div className="lr-card">
          <div className="lr-icon-input">
            <MdOutlineRadioButtonChecked />
            <input
              type="text"
              placeholder="Leaving from"
              value={leavingFrom}
              onChange={(e) => setLeavingFrom(e.target.value)}
              className="lr-input-field"
            />
          </div>
          <hr />
          <div className="lr-icon-input">
            <MdOutlineRadioButtonChecked />
            <input
              type="text"
              placeholder="Going to"
              value={goingTo}
              onChange={(e) => setGoingTo(e.target.value)}
              className="lr-input-field"
            />
          </div>
          <hr />
          <div className="lr-icon-input">
            <FaUser />
            <input
              type="number"
              placeholder="Passenger count"
              value={passengerCount}
              onChange={(e) => setPassengerCount(parseInt(e.target.value))}
              className="lr-input-field"
            />
          </div>
          <div>
            <h4 className="lr-save">Save up to $1,180 on your first ride.</h4>
          </div>
          <a className="lr-btn" href="/login">Publish a ride</a>
        </div>
      </div>
      <div className="lr-white">
        <h6 className="lr-text">The best of carpooling with EZY Ride</h6>
        <div className="lr-flex-container">
          <div className="lr-flex-item1" data-aos="fade-right">
            <FaCoins className="lr-icon-mdoutli"/>
            <h4 className="lr-text-2">Maximize Your Savings</h4>
            <p className="lr-color">
              "Cut travel expenses by sharing your car ride with passengers - sign up as a driver to start saving!"
            </p>
          </div>
          <div className="lr-flex-item2" data-aos="fade-down">
            <MdSecurity className="lr-icon-mdoutli"/>
            <h4 className="lr-text-3">Travel Confidently with Our Verified Community</h4>
            <p className="lr-color">
              "Travel securely with our community: all members undergo thorough verification for your peace of mind."
            </p>
          </div>
          <div className="lr-flex-item3" data-aos="fade-left">
            <MdOutlineRocketLaunch  className="lr-icon-mdoutli"/>
            <h4 className="lr-text-4">Streamlined Carpooling Solutions</h4>
            <p className="lr-color">
              "Streamlined by our technology, EZY Ride connects you seamlessly with passengers, simplifying your journey."
            </p>
          </div>
        </div>
      </div>
      <div className="lr-textsection">
        <div className="lr-flex-containerr">
          <div className="lr-flex-item">
            <h4 className="lr-textt-1">Join 21 million</h4>
            <p className="lr-color-9">drivers already using Groove Ride</p>
          </div>
          <div className="lr-flex-item">
            <h4 className="lr-textt-2">More than 100 million</h4>
            <p className="lr-color-9">Groovee Ride members worldwide</p>
          </div>
          <div className="lr-flex-item">
            <h4 className="lr-textt-3">Over 40 million</h4>
            <p className="lr-color-9">rides shared per year</p>
          </div>
        </div>
      </div>
      <div className="lr-section1">
        <div className="lr-slider">
        
          <img src={require(`../Images/${sliderImages[currentIndex]}`)} alt="Slider Image" className="lr-slider-image" />
        
          <button onClick={toggleAutoSlide} className="lr-auto-slide-btn">
          </button>
        </div>
        <div>
          <div className="lr-sectbod">
            <p className="lr-parra-5">"Half a decade with EZY Ride, countless shared adventures, and endless gratitude for the seamless experiences!"</p>
          </div>
        </div>
      </div>
      <h6 className="lr-texx">"List your ride in minutes and hit the road swiftly with EZY Ride's hassle-free platform!"</h6>
      <div className="lr-sect">
        <div className="lr-video-container">
          <video className="lr-video-player" controls autoPlay loop muted playsInline controlsList="nodownload" ref={videoRef}>
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <div className="lr-whitte">
            <div className="lr-horizontal-container">
              <div className="lr-horizontal-item">
                <FaUser  className="lr-ico"/>
                <div>
                  <h4 className="lr-text-create-1">Start Your Journey with Groove Ride: Create Your Account Now!</h4>
                  <p className="lr-color">
                    Add your profile picture, a few words about you and your phone number to increase trust between members.
                  </p>
                </div>
              </div>
              <div className="lr-horizontal-item">
                <IoCarOutline   className="lr-icoo" />
                <div>
                  <h4 className="lr-text-create-2">Publish your ride</h4>
                  <p className="lr-color">
                    Indicate departure and arrival points, the date of the ride and check our recommended price to increase your chances of getting your first passengers and ratings
                  </p>
                </div>
              </div>
              <div className="lr-horizontal-item">
                <MdSecurity className="lr-icooo"/>
                <div>
                  <h4 className="lr-text-create-3">Accept booking requests</h4>
                  <p className="lr-color">
                    Review passenger profiles and accept their requests to ride with you. That’s how easy it is to start saving on travel costs!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lr-secttext">
        <div>
          <h6 className="lr-test">Count on us for support at every turn - we're here for you every step of the way.</h6>
        </div>
        <div className="lr-flex-container">
        <div className="lr-flex-item1" data-aos="flip-right">
  <PiChatsCircle className="lr-icon-just-1" />
  <h4 className="lr-text-atyour-1">At your service 24/7</h4>
  <p className="lr-colorr">
    Our dedicated team is available to address your inquiries via email or social media, while live chat connects you instantly with seasoned members for immediate assistance.
  </p>
</div>
<div className="lr-flex-item1" data-aos="flip-up">
  <IoCarOutline className="lr-icon-just-2"/>
  <h4 className="lr-text-atyour-2">Groovee Ride at your side</h4>
  <p className="lr-colorr">
    For just ₹2, unlock reimbursement of up to ₹1,500 of your excess when you publish a ride as a driver on EZY Ride.
  </p>
</div>
<div className="lr-flex-item1" data-aos="flip-left">
  <MdSecurity className="lr-icon-just-3"/>
  <h4 className="lr-text-atyour-3">100% secure information</h4>
  <p className="lr-colorr">
    Our team is committed to safeguarding your data, ensuring 100% confidentiality through robust monitoring tools, secure navigation, and encryption protocols.
  </p>
</div>

        </div>
      </div>
      <div>
        <Help/>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
};

export default Publishride;
