import React, { navRef, useState, useEffect } from "react";
import { FaCoins } from "react-icons/fa6";
import { BsPersonVcard } from "react-icons/bs";
 import Lghelp from "./Lghelp";
 
// import blablaCarImage from '../images/images1.png';
import { FcAutomotive } from "react-icons/fc";
import { FcApproval } from "react-icons/fc";
 import { GoPerson } from "react-icons/go";
import { CiCalendarDate } from "react-icons/ci";
import { CgEditBlackPoint } from "react-icons/cg";
import { FcFlashOn } from "react-icons/fc";
import blablaCarImage from '../Images/images s2.jpg';
import blablaCarImage1 from '../Images/images ss2.jpg';
import blablaCarImage2 from '../Images/Vellfire.webp'
import './Usertlandingpage.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Search from "../UserPages/Search";
import { jwtDecode } from 'jwt-decode';
 
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import UsersNavbar from "./UsersNavbar";
import UserFooter from "./UserFooter";


 
function Usertlandingpage() {


    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");
   
    useEffect(() => {
      const role = localStorage.getItem("role");
      if (role !== "user") {
        navigate("/");
      }
    }, [navigate]);
   
    useEffect(() => {
      const token = Cookies.get("token");
      if (!token) {
        navigate("/");
      } else {
        const decoded = jwtDecode(token);
        setUsername(decoded.username);
        setRole(decoded.role);
        if (decoded.role !== "user") {
          navigate("/");
        }
      }
    }, [navigate]);


  useEffect(() => {
    Aos.init({duration: 1000});
  }, []);
  const [showDropdown, setShowDropdown] = useState(false);
 
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [passengers, setPassengers] = useState(1);
 
  const handleSwapLocations = () => {
    const temp = from;
    setFrom(to);
    setTo(temp);
  };
 
  const handleFromChange = (e) => {
    setFrom(e.target.value);
  };
 
  const handleToChange = (e) => {
    setTo(e.target.value);
  };
 
  const handleIncrement = () => {
    if (passengers < 8) {
      setPassengers(passengers + 1);
    }
  };
 
  const handleDecrement = () => {
    if (passengers > 1 ) {
      setPassengers(passengers - 1);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const destinations = [
     { source: 'New Delhi', destination: 'Chandigarh' },
    { source: 'New Delhi', destination: 'Jaipur' },
     { source: 'New Delhi', destination: 'Agra' },
     { source: 'Mumbai', destination: 'Pune' },
     { source: 'Agra', destination: 'New Delhi' },
     { source: 'Jaipur', destination: 'New Delhi' }

  ];

  const [showAllDestinations, setShowAllDestinations] = useState(false);

  const toggleDestinations = () => {
    setShowAllDestinations(!showAllDestinations);
  };

  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  const destinationsToRender = showAllDestinations ? destinations : destinations.slice(0, 3);

  const rows = chunkArray(destinationsToRender, 3);


  return (
    <div className="home-page">
       
    <div className="ulg-users"><UsersNavbar username={username} /></div>
     <div className="ulg-whole">
      <div>

     <div className="ulg-hero-1">
     
     <img className="ulg-hero-img" src={blablaCarImage2} alt="BlaBlaCar"  />

     </div>
     
 
   <Search/>
  </div>

    

      <main className="ulg-main-content">
        <section className="ulg-hero">
          <h2 className="ulg-text"></h2>
         
        </section>

</main>
   
<div className="ulg-cta-our">
<div className="ulg-paragraph-our">
  <div>
<img className="ulg-our-img" src={blablaCarImage} alt="BlaBlaCar"  />
</div>
<section className="ulg-our-1">

    <h2 className="ulg-our-2">Ensuring Your Safety</h2>
    <p className="ulg-our-3">
    Safeguarding your journey is paramount to us at Ensuring .
    While we strive to maintain the highest levels of security on our
     platform, we understand that occasional scams may occur.
      That's why we're committed to empowering you with the knowledge 
      to avoid and report any such incidents. Follow our expert tips to
    help us uphold your safety standards.
    </p>
   
  </section>
 

 
 

</div>
 
</div>
 
 
<div className="ulg-car-pn-pm">
<div>
<img className="ulg-car-img" src={blablaCarImage1} alt="BlaBlaCar"  />
</div>
  <div className="ulg-paragraph-car">
  <section className="ulg-drive-1">
 
    <h2 className="ulg-paragraph-1">Drive Smart, Earn Rewards!</h2>
    <p className="ulg-paragraph-2">
    Drivers, here's some exciting news! Your smart driving  
    choices are now being recognized and rewarded through 
    our Carpool Bonus program. Dive into carpooling and 
    unlock exclusive rewards. Check out the eligibility 
    requirements and start earning rewards for your 
     eco-friendly efforts! 
      </p>
    </section>
    <a href="#" class="ulg-more-btn-2"></a>

  </div>
</div>

<div className="ulg-want-ps">
        <h2 className="ulg-pn-pm-want">Where do you want to go?</h2>
        <div className="ulg-destination-row">
          {destinations.slice(0, 3).map((destination, index) => (
            <div key={index} className={`ulg-destination-box ulg-box-${index + 1}`}>
              <span className="ulg-destination">{destination.source}</span>
              <span className="ulg-arrow">&rarr;</span>
              <span className="ulg-destination">{destination.destination}</span>
              <span className="ulg-end-arrow">&gt;</span>
            </div>
          ))}
        </div>
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} className="ulg-destination-row">
            {row.map((destination, index) => (
              <div key={index} className={`ulg-destination-box ulg-box-${index + 4}`}>
                <span className="ulg-destination">{destination.source}</span>
                <span className="ulg-arrow">&rarr;</span>
                <span className="ulg-destination">{destination.destination}</span>
                <span className="ulg-end-arrow">&gt;</span>
              </div>
            ))}
          </div>
        ))}
      </div>
      <button className="ulg-pn-pm-See" onClick={toggleDestinations}>
        {showAllDestinations ? 'See fewer rides' : 'See our most popular rides'}
      </button>

  
  
 
</div>
 <UserFooter />
</div>

 
   
   
  );
}

 
export default Usertlandingpage;