import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Userrides.css';
import { Link, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import Cookies from 'js-cookie';
import UsersNavbar from '../Landingpages/UsersNavbar';
import { HiArrowLeft } from "react-icons/hi";
import { HiArrowSmRight } from "react-icons/hi";
function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
}

function Userrides() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");
    const [userId, setUserId] = useState(null);
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const storedRole = localStorage.getItem("role");
        if (storedRole !== "user") {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        const token = Cookies.get("token");
        if (!token) {
            navigate("/");
        } else {
            const decoded = jwtDecode(token);
            setUsername(decoded.username);
            setRole(decoded.role);
            setUserId(decoded.id);  // Assuming the decoded token contains the user ID as `id`
            if (decoded.role !== "user") {
                navigate("/");
            }
        }
    }, [navigate]);
    useEffect(() => {
      const fetchUserData = async () => {
          try {
              const response = await axios.get(
                  `https://api.ezyrides.chitgenius.com/api/getmemberdetails/afterlogin/${username}`
              );
              
              // Assuming response.data is an array and you need the 'id' field from the first element
              if (response.data.length > 0) {
                  setUserId(response.data[0].id);  // Set the user ID from the fetched data
                  console.log(response.data[0].id); // Log the fetched user ID
              } else {
                  console.error("User data not found");
              }
          } catch (error) {
              console.error("Error fetching user data", error);
          }
      };
      if (username) {
          fetchUserData();
      }
  }, [username]);

console.log(username)
    useEffect(() => {
        if (userId) {
            axios.get(`https://api.ezyrides.chitgenius.com/api/user/bookings/${userId}`)
                .then(response => {
                    console.log('Fetched bookings:', response.data.rides);
                    setBookings(response.data.rides);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching bookings:', error);
                    setError('Error fetching bookings. Please try again later.');
                    setLoading(false);
                });
        }
    }, [userId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
    <div><UsersNavbar username={username} /></div>
            <div className='lr-main'>
                <div className='lr-contents'>
              
                <div className="rides-container">
                    {bookings.length > 0 ? (
                        bookings.map(booking => (
                            <Link to={`/ridede/${booking.booking_id}`}  className='lr-rode'>
                                <div className="ride-card">
                                    <div className="ride-details">
                                    <div className='lr-bookedsect'>
                                    <p className='lr-book'>{formatDate(booking.date)}</p>
                                    </div>
                                    <p className='lr-book'>Rider Booking ID: {booking.riderbooking_id}</p>

                                    </div>
                                    <div className="booking-details">
   
                                    <div className='booking-info'>
                                       <p className='lr-book'>Booking Details:</p>
 

                                       <p className='lr-books1'>{booking.fromto}</p>
                                       <p className='lr-books2'><HiArrowSmRight /></p>
                                       <p className='lr-books3'>{booking.goingto}</p>
                                    </div>
                                  </div>

                                    <div>
                                    <p className='lr-bookprice'>Price: {booking.price}</p>

                                    </div>
                                   
                  
                                </div>
                            </Link>
                        ))
                    ) : (
                        <div className='no-booking-found'>
                        <p className='no-booking'>No bookings found for this user.</p>
                        </div>
                    )}
                </div>
                </div>
            </div>
        </div>
    );
}

export default Userrides;
