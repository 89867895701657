// src/components/loginpage.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Signin.css';
import AlertMessage from './AlertMessage'; // Import the AlertMessage component
 
export const Login = () => {
  // State variables for username, password, loading state, and alert messages
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('error');
  const [alertMessage, setAlertMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
 
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
 
  const handleLogin = async () => {
    if (!username || !password) {
      setAlertSeverity('warning');
      setAlertMessage('Please fill in all fields.');
      setAlertOpen(true);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post('https://api.ezyrides.chitgenius.com/loginn', {
        username,
        password,
      });
      if (response.data.success) {
        const role = response.data.role;
        console.log(response)
        setAlertSeverity('success');
        setAlertMessage(`Welcome, ${role}!`);
        setAlertOpen(true);
     
        localStorage.setItem('role',role)
        setTimeout(() => {
          Cookies.set('token', response.data.token);
          navigate(`/${role.toLowerCase()}`, { state: { username, role } });
        }, 1000);
      } else {
        setAlertSeverity('error');
        setAlertMessage('Invalid credentials');
        setAlertOpen(true);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setAlertSeverity('error');
      setAlertMessage('An error occurred during login. Please try again later.');
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };
  // JSX for login page UI
  return (
    <div className="lp_background">
      <div className="lp_login-container">
        <div className="lp_login">
          <div className="lp_animation">
            <h3 className="lp-heading">EZY Rides</h3>
            <h2>Best rides, Best prices</h2>
          </div>
          <div className="lp_form">
            <h2>Welcome</h2>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

<a href="/forgetpassword" className="login-link">Forget password</a>
            <button className="lp_submit" onClick={handleLogin}>
              {loading ? 'Logging in...' : 'Enter'}
            </button>
            <p className="login-text">Don't have an account? <a href="/signup" className="login-link">Sign up</a></p>
            <p className="login-text">Go to  <a href="/" className="login-link">Home page</a></p>
          </div>
        </div>
        <AlertMessage
          open={alertOpen}
          severity={alertSeverity}
          message={alertMessage}
          handleClose={handleAlertClose}
        />
      </div>
    </div>
  );
};
 