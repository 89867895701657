import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Suprofile.css';
import SAnavbar from '../components/SuperAdminNavbars/SAnavbar';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useNavigate,Link } from 'react-router-dom';
 
 
 
const Suprofile = () => {
  const { id } = useParams();
  console.log(id);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    password: '',
    profileImage: null,
  });
 
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [currentImageUrl, setCurrentImageUrl] = useState('');
  const navigate = useNavigate();
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'superadmin') {
        navigate('/');
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get('token');
 
    if (!token) {
      navigate('/');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
 
      axios
        .get(`https://api.ezyrides.chitgenius.com/profile/part/${id}`, config)
        .then(async (response) => {
          const userData = response.data.user;
 
          if (userData.profileImage) {
            setCurrentImageUrl(`https://api.ezyrides.chitgenius.com/uploads/${userData.profileImage}`);
          }
 
          setFormData({
            firstName: userData.firstName,
            lastName: userData.lastName,
            dob: userData.dob,
            email: userData.email,
            password: userData.password,
            profileImage: null,
          });
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [navigate, id]);
 
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleImageChange = (e) => {
    setFormData({ ...formData, profileImage: e.target.files[0] });
  };
 
  const handleSubmit = () => {
    const token = Cookies.get('token');
 
    if (!token) {
      navigate('/');
      return;
    }
 
    const formDataToSend = new FormData();
    formDataToSend.append('firstName', formData.firstName);
    formDataToSend.append('lastName', formData.lastName);
    formDataToSend.append('dob', formData.dob);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('password', formData.password);
 
    if (formData.profileImage) {
      formDataToSend.append('profileImage', formData.profileImage);
    }
 
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    };
 
    axios
      .put(`https://api.ezyrides.chitgenius.com/profile/editbyid/${id}`, formDataToSend, config)
      .then((response) => {
        console.log('User data updated successfully');
        navigate('/SuperAdminprofile');
      })
      .catch((error) => {
        console.error('Error updating user data:', error);
      });
  };
 
  const handleDeleteAccount = () => {
    const token = Cookies.get('token');
 
    if (!token) {
      navigate('/');
      return;
    }
 
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
 
    axios
      .delete(`https://api.ezyrides.chitgenius.com/profile/${id}`, config)
      .then((response) => {
        console.log('User account deleted successfully');
        navigate('/'); // Redirect to home after deletion
      })
      .catch((error) => {
        console.error('Error deleting user account:', error);
      });
  };
 
  return (
    <div>
      <SAnavbar username={username} />
      <div className="sua-main">
     
        <div className="sua-form">
        <Link to="/SuperAdminprofile" className="sua-travel-backsss">Back</Link>
 
          <div className="sua-form-row">
            <div className="sua-form-group">
              <label>First Name:</label>
              <input
                type="text"
                className="sua-textbox"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="sua-form-group">
              <label>Last Name:</label>
              <input
                type="text"
                className="sua-textbox"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="sua-form-row">
            <div className="sua-form-group">
              <label>Date of Birth:</label>
              <input
                type="text"
                className="sua-textbox"
                name="dob"
                value={formData.dob}
                onChange={handleInputChange}
              />
            </div>
            <div className="sua-form-group">
              <label>Email:</label>
              <input
                type="text"
                className="sua-textbox"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="sua-form-row">
            <div className="sua-form-group">
              <label>Profile Image:</label>
              {currentImageUrl && (
                <div>
                  <img src={currentImageUrl} alt="Profile" className="sup-profile-image" />
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="sua-textbox"
              />
            </div>
          </div>
          <div className="button-container">
            <button className="sua-buttonn" onClick={handleSubmit}>
              Update
            </button>
            
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Suprofile;
 
 