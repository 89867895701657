import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Usercomerider.css';
import { useNavigate, useParams } from "react-router-dom";
import { HiArrowSmRight } from "react-icons/hi";
import {jwtDecode} from 'jwt-decode';
import Cookies from 'js-cookie';
import UsersNavbar from '../Landingpages/UsersNavbar';
import { IoArrowBackSharp } from "react-icons/io5";

import Modal from 'react-modal';

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
}

Modal.setAppElement('#root');

function Usercomerider() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");
    const [userId, setUserId] = useState(null);
    const [rides, setRides] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [riderBookingData, setRiderBookingData] = useState([]);
 
    const [newPassengers, setNewPassengers] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDeleteActive, setIsDeleteActive] = useState(false);
    const [selectedRideId, setSelectedRideId] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(""); // State to hold selected status
    // const  {riderBookingID } = useParams();
    const { riderbooking_id } = useParams(); // Changed to riderbooking_id
 
  console.log("riderbooking_id", riderbooking_id);
    useEffect(() => {
        const storedRole = localStorage.getItem("role");
        if (storedRole !== "user") {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        const token = Cookies.get("token");
        if (!token) {
            navigate("/");
        } else {
            const decoded = jwtDecode(token);
            setUsername(decoded.username);
            setRole(decoded.role);
            setUserId(decoded.id);
            if (decoded.role !== "user") {
                navigate("/");
            }
        }
    }, [navigate]);

    useEffect(() => {
        if (username) {
            const fetchUserData = async () => {
                try {
                    const response = await axios.get(`https://api.ezyrides.chitgenius.com/api/getmemberdetails/afterlogin/${username}`);
                    if (response.data.length > 0) {
                        setUserId(response.data[0].id);
                    } else {
                        console.error("User data not found");
                    }
                } catch (error) {
                    console.error("Error fetching user data", error);
                }
            };
            fetchUserData();
        }
    }, [username]);

    // useEffect(() => {
    //     if (riderBookingID) {
    //         axios.get(`https://api.ezyrides.chitgenius.com/api/user/indivisualUser/${riderBookingID}`)
    //             .then(response => {
    //                 setNewRides(response.data.rides);
    //                 setLoading(false);
    //             })
    //             .catch(error => {
    //                 setError('Error fetching ride data. Please try again later.');
    //                 setLoading(false);
    //             });
    //     }
    // },
//      [riderBookingID]);
// console.log(newRides);
useEffect(() => {
    const fetchRiderBookingData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://api.ezyrides.chitgenius.com/api/user/indivisualUser/${riderbooking_id}`
        );
        if (response.data.rides && response.data.rides.length > 0) {
          setRiderBookingData(response.data.rides);
        } else {
          console.error("No rides found");
        }
      } catch (error) {
        console.error("Error fetching rider booking data", error);
        setError("Error fetching ride data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
 
    if (riderbooking_id) {
      fetchRiderBookingData();
    }
  }, [riderbooking_id]);
 
  console.log("riderBookingdata", riderBookingData);

    const fetchNewPassengers = async (riderbookingId) => {
        try {
            const response = await axios.get(`https://api.ezyrides.chitgenius.com/api/newpassengers/${riderbookingId}`);
            setNewPassengers(response.data.users);
        } catch (error) {
            console.error('Error fetching new passengers:', error);
        }
    };

    const handleTravelPreference = (riderbookingId) => {
        fetchNewPassengers(riderbookingId);
        setModalIsOpen(true);
        setSelectedRideId(riderbookingId);
    };

    const handleDeleteClick = () => {
        setIsDeleteActive(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`https://api.ezyrides.chitgenius.com/api/deleteRide/${selectedRideId}`);
            setRides(rides.filter(ride => ride.riderbooking_id !== selectedRideId));
            setModalIsOpen(false);
        } catch (error) {
            console.error('Error deleting ride:', error);
        }
    };

    const handleCloseModal = () => {
        setIsDeleteActive(false);
        setModalIsOpen(false);
    };


    const handleStatusChange = async (bookingId, status) => {
        try {
            await axios.post(`https://api.ezyrides.chitgenius.com/api/updateBookingStatus`, {
                bookingId,
                status
            });
            const updatedRides = rides.map(ride => {
                if (ride.riderbooking_id === selectedRideId) {
                    return { ...ride, status }; // Update the status in the specific ride object
                }
                return ride;
            });
            setRides(updatedRides); 
            fetchNewPassengers(selectedRideId); // Refresh the new passengers list
        } catch (error) {
            console.error('Error updating booking status:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <UsersNavbar username={username} />
            <div className='comer-main'>
                <div className='comer-contents'>
                    <div className="comer-container">
                    <button onClick={() => navigate('/yourcreationstable')} className="comer-page">
                  <IoArrowBackSharp /> Previous Page
                </button>
                        {riderBookingData.length > 0 ? (
                            riderBookingData.map(details => (
                                <div key={details.riderbooking_id} className="comer-card-passeng">
                                    <div className="comer-card">
                                        <div className='comer-passeng'>
                                            <p className='comer-userpasss'>Price: {details.price}</p>
                                        </div>
                                        <div className='comer-user-date-music'>
                                            <p className='comer-user'>Date: {details.date}</p>
                                            <p className='comer-user'>Riderbooking ID: {details.riderbooking_id}</p>

                                            <div className='comer-info'>
                                                <p className='comer-user'>Booking Details:</p>
                                                <p className='comer-user1'>{details.leavingfrom}</p>
                                                <p className='comer-user2'><HiArrowSmRight /></p>
                                                <p className='comer-user3'>{details.goingto}</p>
                                            </div>
                                            <p className='comer-user'>Pick Up Time: {details.time}</p>
                                            <p className='comer-user'>Drop Up Time: {details.timedrop}</p>
                                           
                                            <p className='comer-user'>Duration: {details.duration} hr</p>
                                            <p className='comer-user'>Passengers: {details.passengers}</p>

                                        </div>
                                    </div>
                                    <div className="comer-card1">
                                        <p className='comer-user'>Smoking: {details.smoke}</p>
                                        <p className='comer-user'>Pets: {details.pet}</p>
                                        <p className='comer-user'>Music: {details.music}</p>
                                    </div>
                                    <button className="comer-about-content-view" onClick={() => handleTravelPreference(details.riderbooking_id)}>
                                    View Passenger Details
                                    </button>
                                </div>
                            ))
                        ) : (
                            <div className='comer-usering-found'>
                                <p className='comer-usering'>You are not a rider yet.</p>
                            </div>
                        )}
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={handleCloseModal}
                            contentLabel="New Passengers"
                            className="modal-pop"
                            overlayClassName="modal-overlay"
                        >
                            {newPassengers.length > 0 ? (
                                <div className="new-passenger-table">
                                    <table className="new-passenger-table-booking">
                                        <thead>
                                            <tr>
                                                <th>Booking ID</th>
                                                <th>Rider Booking ID</th>
                                                <th>User ID</th>
                                                <th>Rider ID</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Price</th>
                                                <th>Going To</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {newPassengers.map(passenger => (
                                                <tr key={passenger.riderbooking_id}>
                                                    <td>{passenger.booking_id}</td>
                                                    <td>{passenger.riderbooking_id}</td>
                                                    <td>{passenger.user_id}</td>
                                                    <td>{passenger.rider_id}</td>
                                                    <td>{formatDate(passenger.date)}</td>
                                                    <td>{passenger.time}</td>
                                                    <td>{passenger.price}</td>
                                                    <td>{passenger.goingto}</td>
                                                    <td>
                                                    <select
                                                                        disabled={passenger.status !== ""} // Disable if status is already set
                                                                        value={passenger.status || 'select'}
                                                                        onChange={(e) => handleStatusChange(passenger.booking_id, e.target.value)}
                                                                    >
                                                                        <option value="select">--Select--</option>
                                                                        <option value="confirmed">Confirmed</option>
                                                                        <option value="rejected">Rejected</option>
                                                                    </select>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p className='nonew-passen'>No new passengers.</p>
                            )}
                            <div className="modal-actions">
                                <button className="close-new" onClick={handleCloseModal}>Close</button>
                                <button
                                    className={`delete-new ${isDeleteActive ? 'active' : ''}`}
                                    onClick={isDeleteActive ? handleConfirmDelete : handleDeleteClick}
                                >
                                    {isDeleteActive ? 'Confirm Delete' : 'Delete Ride'}
                                </button>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Usercomerider;
