import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './YourRidestable.css';
import { Link, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import Cookies from 'js-cookie';
import UsersNavbar from '../Landingpages/UsersNavbar';
import { HiArrowSmRight } from "react-icons/hi";

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
}

function YourRidestable() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");
    const [userId, setUserId] = useState(null);
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const storedRole = localStorage.getItem("role");
        if (storedRole !== "user") {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        const token = Cookies.get("token");
        if (!token) {
            navigate("/");
        } else {
            const decoded = jwtDecode(token);
            setUsername(decoded.username);
            setRole(decoded.role);
            setUserId(decoded.id);  // Assuming the decoded token contains the user ID as `id`
            if (decoded.role !== "user") {
                navigate("/");
            }
        }
    }, [navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(
                    `https://api.ezyrides.chitgenius.com/api/getmemberdetails/afterlogin/${username}`
                );

                if (response.data.length > 0) {
                    setUserId(response.data[0].id);
                    console.log(response.data[0].id);
                } else {
                    console.error("User data not found");
                }
            } catch (error) {
                console.error("Error fetching user data", error);
            }
        };
        if (username) {
            fetchUserData();
        }
    }, [username]);

    useEffect(() => {
        if (userId) {
            axios.get(`https://api.ezyrides.chitgenius.com/api/user/bookings/${userId}`)
                .then(response => {
                    console.log('Fetched bookings:', response.data.rides);
                    setBookings(response.data.rides);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching bookings:', error);
                    setError('Error fetching bookings. Please try again later.');
                    setLoading(false);
                });
        }
    }, [userId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <div className="yourcrea-usernavbar"><UsersNavbar username={username} /></div>
            <div className="yourRides-main">
            <div className="yourRides-contents">
            <div className="yourRides-rides">
              <h2 className="yourRides-rides-your">Bookings</h2>
            </div>
              
                    <div className="yourRides-table-container">
                        <table className="yourRides-table">
                            <thead>
                                <tr>
                                    {/* <th className="rides-th">Date</th> */}
                                    <th className="yourRides-th">Rider Booking ID</th>
                                    <th className="yourRides-th">From</th>
                                    <th className="yourRides-th">To</th>
                                    <th className="yourRides-th">Price</th>
                                    <th className="yourRides-th">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookings.length > 0 ? (
                                    bookings.map(booking => (
                                        <tr key={booking.booking_id}>
                                            {/* <td className="rides-td">{formatDate(booking.date)}</td> */}
                                            <td className="yourRides-td">{booking.riderbooking_id}</td>
                                            <td className="yourRides-td">{booking.fromto}</td>
                                            <td className="yourRides-td">{booking.goingto}</td>
                                            <td className="yourRides-td">{booking.price}</td>
                                            
                                            <td className="yourRides-td">
                                                <Link to={`/ridede/${booking.booking_id}`}>
                                           
                                                    <button className="yourRides-view-button">View</button>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="yourRides-no-booking">No bookings found for this user.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default YourRidestable;
