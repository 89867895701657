
 
import React, { useState } from "react";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import "./VerifyProfile.css"; // Create a CSS file for styling

import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import verify2backgrndimage from "../Images/EZY-removebg-preview.png"; // Import the image as a default export
 
 
const VerifyProfile = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const navigate = useNavigate();
  const handleSendOtp = async () => {
    try {
      const response = await axios.post("https://api.ezyrides.chitgenius.com/sendemailOTP", { email });
      if (response.data.success) {
        setIsOtpSent(true);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        alert(error.response.data.message);
      } else {
        console.error("Error sending OTP:", error);
        alert("Failed to send OTP. Please try again.");
      }
    }
  };
 
  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post("https://api.ezyrides.chitgenius.com/verifyemailOTP", { otp, email });
      if (response.data.success) {
        alert("Email verified successfully!");
        navigate("/profileinfo");
      } else {
        alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("Error verifying OTP. Please try again.");
    }
  };
 
  return (
    <div className="verify-profile-container">
      <div>
      <div className="verify-profile-previous-but">
      <Link className="verify-profile-link-csss" to={`/profileinfo`}>
          <p className="verify-profile-back-but"  ><FaRegArrowAltCircleLeft /> GoBack</p>
        </Link>
      </div>
      <div className="verify-profile-box">
        {!isOtpSent ? (
          <>
            <h2>Verify Your Email</h2>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="verify-profile-input"
            />
            <button onClick={handleSendOtp} className="verify-profile-button">Send OTP</button>
          </>
        ) : (
          <>
            <h2>Enter OTP</h2>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              className="verify-profile-input"
            />
            <button onClick={handleVerifyOtp} className="verify-profile-button">Verify OTP</button>
          </>
        )}
      </div>
      </div>
      <div className="verify-profile-image">
      <img src={verify2backgrndimage} alt="Verify Profile"  className="verify-profile-carimagecss"/>
 
      </div>
    </div>
  );
};
 
export default VerifyProfile;
 