


 
import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";
import "./Navlog.css";
import Cookies from 'js-cookie';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../Images/logo.png";
 
function Navlog() {
  const [data, setData] = useState([]);
 
  const navigate = useNavigate();
 
  const navRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
 
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth" // This smooth scrolls to the top
    });
  };
 
  const closeNavbar = () => {
    navRef.current.classList.remove("responsive_nav");
  };
 
  const toggleNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
 
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
    localStorage.removeItem('role');
  };
 
  return (
    <>
      <div className="navlog-cnavlog"></div>
      <header>
        <div>
          <Link to="/" className="navlog-linked-text">
            <div className="navlog-logo-10">
              <img src={logo} className="navlog-yaa" alt="Logo" />
            </div>
          </Link>
        </div>
        <nav ref={navRef}>
          <div className="tiger">
            <ul>
       
              {/* Moved sign-in button JSX here */}
              <div className="navlog-dropdown" onClick={toggleDropdown}>
                <FaRegUser className="navlog-icon" />
                <RiArrowDropDownLine className="navlog-icons" />
                {showDropdown && (
                  <div className="navlog-dropdown-content">
                    <a href="/rides">Your Rides</a>
                  <a href="/MyProfile">Profile</a>
                  <a href="#" onClick={handleLogout}>Logout</a>
                  </div>
                )}
              </div>
            </ul>
          </div>
          <button className="navlog-nav-btn nav-close-btn" onClick={toggleNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="navlog-nav-btn" onClick={toggleNavbar}>
          <FaBars />
        </button>
      </header>
    </>
  );
}
 
export default Navlog;
 