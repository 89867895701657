
import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import Cookies from 'js-cookie';
import { Link, useNavigate } from "react-router-dom";
import "./UsersNavbar.css";
import logo from '../Images/logos.png';
import { CiSearch } from "react-icons/ci";
import { IoIosAddCircleOutline } from "react-icons/io";
 
const UsersNavbar = ({ username }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [role, setRole] = useState("");
  const [showUserDetails, setShowUserDetails] = useState(false);
 
  const navRef = useRef();
  const profileRef = useRef();
 
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://api.ezyrides.chitgenius.com/user/${username}`);
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const data = await response.json();
        if (data.success) {
          setProfileImage(data.profileImage);
          setRole(data.role);
        } else {
          throw new Error('User data not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setProfileImage();
        setRole("");
      }
    };
 
    fetchUserData();
  }, [username]);
 
  const toggleNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
 
  const closeNavbar = () => {
    navRef.current.classList.remove("responsive_nav");
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
 
  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
    localStorage.removeItem('role');
  };

  const handleSearchClick = () => {
    navigate('/ser?passengers=1');
    window.location.reload();
  };
 
  return (
    <div className="usernb-main">
      <div className="usernb-cheader"></div>
      <header className="usernb-user">
        <div>
         
            <div className="usernb-logo-10"></div>
            <Link to="/Usertlandingpage" className="usernb-linked-text">
            <img src={logo} className="usernb-yaa" alt="Logo" />
          </Link>
        </div>
        <nav ref={navRef}>
     
            <ul>
                <div className="usernb-se-main">
                <p className="usernb-search-main">
                <a href="#" className="search-usernb" onClick={handleSearchClick}>
                  <CiSearch className="usernb-search"/> Search
                </a>
              </p>
              <p className="usernb-publish-main">
                <a href="/Pickingup" className="usernb-publish" onClick={closeNavbar}>
                  <IoIosAddCircleOutline className="usernb-publish-1"/> Publish a ride
                </a>
              </p>
              </div>
              </ul>
            <li ref={profileRef} className="usernb-details">
  <div className="usernb-profile-info-container">
    <img
      src={`https://api.ezyrides.chitgenius.com/uploads/${profileImage}`}
      className="usernb-profile-image"
     
    />
    <div className="usernb-profile-details">
      <p>Role: {role}</p>
    </div>
  </div>
</li>
        
     
                <div className="usernb-tiger">
            <ul>
       
              <div className="usernb-dropdown" onClick={toggleDropdown}>
                <RiArrowDropDownLine className="usernb-icons" onClick={() => setShowDropdown(!showDropdown)} />
                {showDropdown && (
                  <div className="usernb-dropdown-content">
                    <a href="/yourcreationstable">Creations</a> 
                    <a href="/yourRidestable">Bookings</a>
                    <a href="/profileinfo">Profile</a>
                    <a href="#" onClick={handleLogout}>Logout</a>
                  </div>
                )}
              </div>
            </ul>
          </div>
          <button className="usernb-nav-btnn nav-close-btn" onClick={toggleNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="usernb-nav-btn-2" onClick={toggleNavbar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
};
 
export default UsersNavbar;
 
