import React, { useState, useEffect } from "react";
 
import axios from "axios";
 
import "./Citybooked.css"; // Assuming your CSS file is named Citybooked.css
 
import { Tabs, Tab } from "react-bootstrap";
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
 
import Cookies from 'js-cookie';
import SAnavbar from "../components/SuperAdminNavbars/SAnavbar";
 
 
const Citybooked = () => {
 
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const navigate = useNavigate();
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'superadmin') {
        navigate('/');
      }
    }
  }, [navigate]);
 
 
  const [cities, setCities] = useState([]);
 
  const [locations, setLocations] = useState([]);
 
  const [editingLocation, setEditingLocation] = useState(null);
 
  const [editingLocationName, setEditingLocationName] = useState("");
 
  const [activeTab, setActiveTab] = useState("cities"); // State to manage active tab
 
  const [loading, setLoading] = useState(true);
 
  const [error, setError] = useState(null);
 
  useEffect(() => {
    fetchCities();
 
    fetchLocations();
  }, []);
 
  const fetchCities = async () => {
    try {
      const response = await axios.get(
        "https://api.ezyrides.chitgenius.com/cities/addcities"
      );
 
      setCities(response.data.cities || []);
 
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cities", error);
 
      setError("Error fetching cities");
 
      setLoading(false);
    }
  };
 
  const fetchLocations = async () => {
    try {
      const response = await axios.get("https://api.ezyrides.chitgenius.com/locations");
 
      setLocations(response.data.locations || []);
 
      setLoading(false);
    } catch (error) {
      console.error("Error fetching locations", error);
 
      setError("Error fetching locations");
 
      setLoading(false);
    }
  };
 
  const handleCityDelete = async (cityId) => {
    try {
      await axios.delete(`https://api.ezyrides.chitgenius.com/cities/${cityId}`);
 
      fetchCities();
 
      fetchLocations(); // Refresh locations as well
    } catch (error) {
      console.error("Error deleting city", error);
    }
  };
 
 
 

  const handleLocationUpdate = async (locationId) => {
    const staticPart = locations.find(loc => loc.LocationID === locationId).LocationName.split(', ')[1];
    try {
      await axios.put(`https://api.ezyrides.chitgenius.com/locations/${locationId}`, {
        LocationName: `${editingLocationName}, ${staticPart}`,
      });
      setEditingLocation(null);
      setEditingLocationName("");
      fetchLocations();
    } catch (error) {
      console.error("Error updating location", error);
    }
  };

  const handleLocationDelete = async (locationId) => {
    try {
      await axios.delete(`https://api.ezyrides.chitgenius.com/locations/${locationId}`);
 
      fetchLocations();
    } catch (error) {
      console.error("Error deleting location", error);
    }
  };
 
  const switchTab = (tab) => {
    setActiveTab(tab);
  };
 
  if (loading) {
    return <div>Loading...</div>;
  }
 
  if (error) {
    return <div>{error}</div>;
  }
 
  return (
    <div>
      <SAnavbar username={username} />
 
      <div className="lca-containerr">
        <div className="lca-container">
          <div className="lca-tab-container">
            <Tabs
              defaultActiveKey="home"
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="home" title="Cities">
                <div className="super-admin-cities-table">
                  <table className="lca-styled-table">
                    <thead>
                      <tr>
                        <th className="SA-tahe">City ID</th>
 
                        <th className="SA-tahe">City Name</th>
 
                        <th className="SA-tahe">Actions</th>
                      </tr>
                    </thead>
 
                    <tbody>
                      {cities.map((city) => (
                        <tr key={city.CityID}>
                          <td
                            className="column-city-id"
                            style={{ textAlign: "center" }}
                          >
                            {city.CityID}
                          </td>
 
                          <td
                            className="column-city-name"
                            style={{ textAlign: "center" }}
                          >
                            {city.CityName}
                          </td>
 
                          <td
                            className="column-actions"
                            style={{ textAlign: "center" }}
                          >
                            <button
                              className="lca-delete-btn"
                              onClick={() => handleCityDelete(city.CityID)}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Tab>
 
              <Tab eventKey="profile" title="Locations">
                <div className="super-admin-cities-table">
                  <table className="lca-styled-table">
                    <thead>
                      <tr>
                        <th className="SA-tahe">Location ID</th>
 
                        <th className="SA-tahe">City ID</th>
 
                        <th className="SA-tahe">Location Name</th>
 
                        <th className="SA-tahe">Actions</th>
                      </tr>
                    </thead>
 
                    <tbody>
                      {locations.map((location) => {
                        const [editablePart, staticPart] = location.LocationName.split(', ');
                        return(
 
                        <tr key={location.LocationID}>
                          <td
                            className="column-location-id"
                            style={{ textAlign: "center" }}
                          >
                            {location.LocationID}
                          </td>
 
                          <td
                            className="column-location-city-id"
                            style={{ textAlign: "center" }}
                          >
                            {location.CityID}
                          </td>
 
                          <td
                            className="column-location-name"
                            style={{ textAlign: "center" }}
                          >
                            {editingLocation === location.LocationID ? (
                              <>
                              <input
                                type="text"
                                value={editingLocationName}
                                onChange={(e) =>
                                  setEditingLocationName(e.target.value)
                                }
                              />
                               {`, ${staticPart}`}
                               </>
                            ) : (
                              location.LocationName
                            )}
                          </td>
 
                          <td
                            className="column-actions"
                            style={{ textAlign: "center" }}
                          >
                            <div className="lca-edit-delete-container">
                              {editingLocation === location.LocationID ? (
                                <button
                                  className="lca-save-btn"
                                  onClick={() =>
                                    handleLocationUpdate(location.LocationID)
                                  }
                                >
                                  Save
                                </button>
                              ) : (
                                <>
                                  <button
                                    className="lca-edit-btn"
                                    onClick={() => {
                                      setEditingLocation(location.LocationID);
 
                                      // setEditingLocationName(
                                      //   location.LocationName
                                      // );
                                      setEditingLocationName(editablePart);
 
                                    }}
                                  >
                                    Edit
                                  </button>
 
                                  <button
                                    className="lca-delete-btn"
                                    onClick={() =>
                                      handleLocationDelete(location.LocationID)
                                    }
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Citybooked;
 
 
