import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./Pickingup.css";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import {jwtDecode} from "jwt-decode";
import UsersNavbar from '../Landingpages/UsersNavbar';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
 
const MySwal = withReactContent(Swal);
 
function Pickingup() {
  const mapContainer = useRef(null);
  const mapInstanceRef = useRef(null);
  const routeLayerRef = useRef(null);
  const pickupMarkerRef = useRef(null);
  const dropoffMarkerRef = useRef(null);
 
  const [pickupCoordinates, setPickupCoordinates] = useState(null);
  const [dropoffCoordinates, setDropoffCoordinates] = useState(null);
  const [distance, setDistance] = useState(null);
  const [time, setTime] = useState(null);
 
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [from, setFrom] = useState("");
  const [pickup, setPickup] = useState("");
  const [to, setTo] = useState("");
  const [dropoff, setDropoff] = useState("");
  const [routeOptions, setRouteOptions] = useState([]);
  const [viaOptions, setViaOptions] = useState([]);
  const [journeystartdate, setJourneyStartDate] = useState("");
  const [pickuptime, setPickupTime] = useState("");
  const [noofpassengers, setNoOfPassengers] = useState(1);
  const [priceperseat, setPricePerSeat] = useState("");
  const [citySuggestions, setCitySuggestions] = useState({ from: [], to: [] });
  const [locationSuggestions, setLocationSuggestions] = useState({ pickup: [], dropoff: [] });
  const [cityIDs, setCityIDs] = useState({ from: null, to: null });
  const [showViaInput, setShowViaInput] = useState(false);
  const [newViaCity, setNewViaCity] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState(null);
  const [riderId, setRiderId] = useState(null);
  const [priceErrorMessage, setPriceErrorMessage] = useState("");
 
  const [isRouteFetched, setIsRouteFetched] = useState(false);
 
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      } else {
        fetchUserDetails(decoded.username);
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    if (mapInstanceRef.current === null && mapContainer.current) {
      mapInstanceRef.current = L.map(mapContainer.current).setView([17.360589, 78.4740613], 10);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapInstanceRef.current);
    }
    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
      }
    };
  }, []);
 
  const geocodeAddress = async (address) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}`
      );
      const coordinates = [parseFloat(response.data[0].lat), parseFloat(response.data[0].lon)];
      return coordinates;
    } catch (error) {
      console.error('Error geocoding address:', error);
      return null;
    }
  };
  const formatTime = (timeInHours) => {
    let hours = Math.floor(timeInHours);
    let minutes = Math.round((timeInHours - hours) * 60);
 
    if (minutes === 60) {
      hours += 1;
      minutes = 0;
    }
 
    const period = hours >= 12;
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strMinutes = minutes < 10 ? '0' + minutes : minutes;
 
    return `${hours}:${strMinutes}`;
  };
 
  const handleGetRoute = async () => {
    if (!pickupCoordinates || !dropoffCoordinates) {
      console.error('Coordinates are not set.');
      return;
    }
 
   
  // Check if "From" and "To" are the same
  if (from === to) {
    setErrorMessage("From and To locations cannot be the same.");
    return;
  } else {
    setErrorMessage(""); // Clear error message if locations are valid
  }
    try {
      const response = await axios.get(
        `https://router.project-osrm.org/route/v1/driving/${pickupCoordinates[1]},${pickupCoordinates[0]};${dropoffCoordinates[1]},${dropoffCoordinates[0]}?overview=full&geometries=geojson`
      );
 
      const routes = response.data.routes;
      if (routes && routes.length > 0) {
        const routeData = routes[0];
        if (routeLayerRef.current) {
          mapInstanceRef.current.removeLayer(routeLayerRef.current);
        }
        if (pickupMarkerRef.current) {
          mapInstanceRef.current.removeLayer(pickupMarkerRef.current);
        }
        if (dropoffMarkerRef.current) {
          mapInstanceRef.current.removeLayer(dropoffMarkerRef.current);
        }
 
        const routeLayer = L.geoJSON(routeData.geometry, {
          style: {
            color: '#3887be',
            weight: 5,
            opacity: 0.75,
          },
        }).addTo(mapInstanceRef.current);
        routeLayerRef.current = routeLayer;
 
        mapInstanceRef.current.fitBounds(routeLayer.getBounds());
 
        const pickupMarker = L.circleMarker([pickupCoordinates[0], pickupCoordinates[1]], {
          color: 'blue',
          radius: 5
        }).addTo(mapInstanceRef.current).bindPopup('Pickup Location').openPopup();
 
        const dropoffMarker = L.circleMarker([dropoffCoordinates[0], dropoffCoordinates[1]], {
          color: 'red',
          radius: 5
        }).addTo(mapInstanceRef.current).bindPopup('Dropoff Location').openPopup();
 
        pickupMarkerRef.current = pickupMarker;
        dropoffMarkerRef.current = dropoffMarker;
 
        setIsRouteFetched(true);
 
        const distanceInKm = (routeData.distance / 1000).toFixed(2);
        setDistance(distanceInKm);
 
        const speed = 40;
        const timeInHours = (distanceInKm / speed).toFixed(2);
        const formattedTime = formatTime(timeInHours);
         setTime(formattedTime);
     
 
      } else {
        console.error('No routes found in the response.');
      }
    } catch (error) {
      console.error('Error fetching route:', error);
    }
  };
 
  useEffect(() => {
    const fetchCoordinatesAndRoute = async () => {
      if (pickup && dropoff) {
        const pickupCoords = await geocodeAddress(pickup);
        const dropoffCoords = await geocodeAddress(dropoff);
 
        if (pickupCoords && dropoffCoords) {
          setPickupCoordinates(pickupCoords);
          setDropoffCoordinates(dropoffCoords);
        } else {
          console.error('Error geocoding addresses.');
        }
      }
    };
 
    fetchCoordinatesAndRoute();
  }, [pickup, dropoff]);
 
  useEffect(() => {
    if (pickupCoordinates && dropoffCoordinates) {
      handleGetRoute();
    }
  }, [pickupCoordinates, dropoffCoordinates]);
 
  const fetchUserDetails = async (username) => {
    try {
      const response = await axios.get(`https://api.ezyrides.chitgenius.com/api/getmemberdetails/afterlogin/${username}`);
      const user = response.data[0];
      setUserId(user.id);
      fetchRiderInfo(user.id);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
 
  const fetchRiderInfo = async (userId) => {
    try {
      const response = await axios.get(`https://api.ezyrides.chitgenius.com/api/userinfodetails/afterlogin/${userId}`);
      const rider = response.data[0];
      setRiderId(rider.rider_id);
    } catch (error) {
      console.error("Error fetching rider info:", error);
    }
  };
 
  useEffect(() => {
    document.body.classList.add("l-body");
    return () => {
      document.body.classList.remove("l-body");
    };
  }, []);
 
  const fetchCitySuggestions = async (inputType, query) => {
    try {
      const response = await axios.get(`https://api.ezyrides.chitgenius.com/cities`, {
        params: { query }
      });
      const cities = response.data;
      setCitySuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [inputType]: cities,
      }));
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    }
  };
 
  const fetchLocationSuggestions = async (cityID, inputType) => {
    try {
      if (!cityID) return;
      const response = await axios.get(`https://api.ezyrides.chitgenius.com/locations/${cityID}`);
      const locations = response.data.map((location) => location.LocationName);
      setLocationSuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [inputType]: locations,
      }));
    } catch (error) {
      console.error("Error fetching location suggestions:", error);
    }
  };
 
  const handleCityInputChange = (inputType, setInput, value) => {
    setInput(value);
    if (value.length > 0) {
      fetchCitySuggestions(inputType, value);
    } else {
      setCitySuggestions((prevSuggestions) => ({
        ...prevSuggestions,
        [inputType]: [],
      }));
    }
      // Clear error message if "To" field is changed
  if (inputType === "to") {
    setErrorMessage("");
  }
  };
 
  const handleCitySuggestionClick = (inputType, setInput, value, cityID) => {
    setInput(value);
    setCityIDs((prevCityIDs) => ({
      ...prevCityIDs,
      [inputType]: cityID,
    }));
    fetchLocationSuggestions(cityID, inputType === "from" ? "pickup" : "dropoff");
    setCitySuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [inputType]: [],
    }));
  };
 
  const handleLocationInputChange = (inputType, setInput, value, cityID) => {
    setInput(value);
    fetchLocationSuggestions(cityID, inputType);
  };
 
  const handleLocationSuggestionClick = (inputType, setInput, value) => {
    setInput(value);
    setLocationSuggestions((prevSuggestions) => ({
      ...prevSuggestions,
      [inputType]: [],
    }));
  };
 
  const validateForm = () => {
    let isValid = true;
    let errorMsg = "";
 
    switch (step) {
      case 1:
        // Validate that "from" and "to" fields are not empty and are not the same
        isValid = from !== "" && to !== "" && from !== to;
        errorMsg = !isValid ? "From and To locations cannot be the same." : "";
        break;
      case 2:
        isValid = true; // No validation for case 2
        break;
      case 3:
        isValid = true; // No validation for case 3
        break;
      case 4:
        // Validate that journeystartdate is not empty and is today or a future date
        const startDate = new Date(journeystartdate);
        const currentDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        isValid = journeystartdate !== "" && startDate >= currentDate;
        errorMsg = !isValid ? "Journey start date must be today or a future date." : "";
        break;
      case 5:
        // Validate that pickuptime is not empty and is today or a future time
        const pickupDateTime = new Date(`${journeystartdate}T${pickuptime}`);
        isValid = pickuptime !== "" && pickupDateTime >= new Date();
        errorMsg = !isValid ? "Pickup time must be today or a future time." : "";
        break;
      case 6:
        isValid = noofpassengers > 0;
        errorMsg = !isValid ? "Number of passengers must be greater than 0." : "";
        break;
      case 7:
        const price = parseFloat(priceperseat);
        isValid = price >= 500 && price <= 5000;
        errorMsg = !isValid ? "Price per seat must be between 500 and 5000 Rs." : "";
        break;
      default:
        isValid = false;
    }
 
    if (step === 7) {
      setPriceErrorMessage(errorMsg);
    }
 
    return isValid;
  };
 
 
  useEffect(() => {
    setFormValid(validateForm());
  }, [from, to, journeystartdate, pickuptime, noofpassengers, priceperseat, step]);
 
 
  const nextStep = () => {
    if (validateForm()) {
      setStep(step + 1);
    }
  };
 
  const prevStep = () => {
    setStep(step - 1);
  };
 
 
  const handleViaOption = (value) => {
    if (viaOptions.includes(value)) {
      setViaOptions(viaOptions.filter((option) => option !== value));
    } else {
      setViaOptions([...viaOptions, value]);
    }
  };
 
  const handleAddViaCity = () => {
    if (newViaCity && !viaOptions.includes(newViaCity)) {
      setViaOptions([...viaOptions, newViaCity]);
      setNewViaCity("");
      setShowViaInput(false);
    }
  };
 
  const decrementPassengers = () => {
    setNoOfPassengers((prevValue) => Math.max(prevValue - 1, 1));
  };
 
  const incrementPassengers = () => {
    setNoOfPassengers((prevValue) => Math.min(prevValue + 1, 8));
  };
 
  const handleSubmit = async () => {
    if (!riderId) {
      MySwal.fire({
        title: 'Error!',
        text: 'You are not a rider yet. Please add vehicle details in travel preferences first.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }
 
    const data = {
      user_id: userId,
      rider_id: riderId,
      from,
      pickup,
      to,
      dropoff,
      route: routeOptions.join(', '),
      distance: distance,
      tolls: null,
      journeytime: time,
      addstop: viaOptions.join(', '),
      journeystartdate,
      pickuptime,
      noofpassengers,
      priceperseat
    };
 
    try {
      const response = await axios.post('https://api.ezyrides.chitgenius.com/rider-ride', data);
      console.log(response.data);
      MySwal.fire({
        title: 'Success!',
        text: 'Ride Published Successfully',
        icon: 'success',
        confirmButtonText: 'OK'
       
      })
      setFrom("");
      setPickup("");
      setTo("");
      setDropoff("");
      setRouteOptions([]);
      setViaOptions([]);
      setJourneyStartDate("");
      setPickupTime("");
      setNoOfPassengers(1);
      setPricePerSeat("");
      window.location.reload();
 
 
    }
     catch (error) {
      if (error.response && error.response.status === 403) {
        MySwal.fire({
          title: 'Error!',
          text:  error.response.data.error,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }else if (error.response && error.response.status === 400) {
        MySwal.fire({
          title: 'Error!',
          text: error.response.data.error,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }  else {
        MySwal.fire({
          title: 'Error!',
          text: 'An error occurred while creating the ride.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        console.error('Error submitting form:', error);
      }
    }
  };
 
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="maprelated-input">
            <div className="l-step-container1">
              <div className="l-input-container1">
                <h2>Journey From?</h2>
                <input
                  type="text"
                  placeholder="Enter From Location"
                  value={from}
                  onChange={(e) => handleCityInputChange("from", setFrom, e.target.value)}
                />
                {citySuggestions.from.length > 0 && (
                  <div className="l-suggestions-dropdown">
                    {citySuggestions.from.map((city, index) => (
                      <div
                        key={index}
                        className="l-suggestion-item"
                        onClick={() => handleCitySuggestionClick("from", setFrom, city.CityName, city.CityID)}
                      >
                        {city.CityName}
                      </div>
                    ))}
                  </div>
                )}
                <h2>Pickup</h2>
                <input
                  type="text"
                  placeholder="Enter Pickup Location"
                  value={pickup}
                  onChange={(e) => handleLocationInputChange("pickup", setPickup, e.target.value, cityIDs.from)}
                   readOnly
                />
                {locationSuggestions.pickup.length > 0 && (
                  <div className="l-suggestions-dropdown">
                    {locationSuggestions.pickup.map((location, index) => (
                      <div
                        key={index}
                        className="l-suggestion-item"
                        onClick={() => handleLocationSuggestionClick("pickup", setPickup, location)}
                      >
                        {location}
                      </div>
                    ))}
                  </div>
                )}
                <h2>Final Destination?</h2>
                <input
                  type="text"
                  placeholder="Enter To Location"
                  value={to}
                  onChange={(e) => handleCityInputChange("to", setTo, e.target.value)}
                />
                {citySuggestions.to.length > 0 && (
                  <div className="l-suggestions-dropdown">
                    {citySuggestions.to.map((city, index) => (
                      <div
                        key={index}
                        className="l-suggestion-item"
                        onClick={() => handleCitySuggestionClick("to", setTo, city.CityName, city.CityID)}
                      >
                        {city.CityName}
                      </div>
                    ))}
                  </div>
                )}
                <h2>Dropoff</h2>
                <input
                  type="text"
                  placeholder="Enter Dropoff Location"
                  value={dropoff}
                  onChange={(e) => handleLocationInputChange("dropoff", setDropoff, e.target.value, cityIDs.to)}
                   readOnly
                />
                {locationSuggestions.dropoff.length > 0 && (
                  <div className="l-suggestions-dropdown">
                    {locationSuggestions.dropoff.map((location, index) => (
                      <div
                        key={index}
                        className="l-suggestion-item"
                        onClick={() => handleLocationSuggestionClick("dropoff", setDropoff, location)}
                      >
                        {location}
                      </div>
                    ))}
                  </div>
                )}
         {errorMessage && <div className="error-message">{errorMessage}</div>}
              </div>
              <button className="l-next-button" onClick={() => {
              handleGetRoute(); // Call handleGetRoute to validate the locations
              if (from === to) {
                setErrorMessage("From and To locations cannot be the same.");
              } else {
                setErrorMessage(""); // Clear error message if validation passes
                nextStep();
              }
            }} disabled={!formValid || !isRouteFetched}>Next</button>
            </div>
            <div className="onlymap">
              <div ref={mapContainer} className="map-containers" />
            </div>
          </div>
        );
      case 2: // New case 2
        return (
          <div className="SA-l-step-container-step">
 
          <div className="SA-l-step-container">
 
            <h2>Ride Information</h2>
            <p><strong>From:</strong> {from}</p>
            <p><strong>Pickup:</strong> {pickup}</p>
            <p><strong>To:</strong> {to}</p>
            <p><strong>Dropoff:</strong> {dropoff}</p>
            <p><strong>Distance:</strong> {distance} km</p>
            <p><strong>Time:</strong> {time} hrs</p>
            <div>
              <button className="l-prev-button" onClick={prevStep}>
                Previous
              </button>
              <button className="l-next-button" onClick={nextStep}>
                Next
              </button>
            </div>
            </div>
          </div>
        );
      case 3: // Updated case 3
        return (
          <div className="l-step-container-step">
 
          <div className={`l-step-container case-2 ${showViaInput ? 'expand' : ''}`}>
            <h2>Add intermediate stops to boost passenger numbers</h2>
            <div className="l-checkbox-container">
              {viaOptions.map((option, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    value={option}
                    checked={true}
                    onChange={() => handleViaOption(option)}
                  />
                  <span>{option}</span>
                </label>
              ))}
              <div>
                <button
                  className="l-add-button"
                  onClick={() => setShowViaInput(true)}
                >
                  Add City
                </button>
              </div>
              {showViaInput && (
                <div className="l-via-input-container">
                  <input
                    type="text"
                    placeholder="Enter Via City"
                    value={newViaCity}
                    onChange={(e) => setNewViaCity(e.target.value)}
                  />
                  <button
                    className="l-add-button"
                    onClick={handleAddViaCity}
                  >
                    Add
                  </button>
                </div>
              )}
            </div>
            <div>
              <button className="l-prev-button" onClick={prevStep}>
                Previous
              </button>
              <button className="l-next-button" onClick={nextStep} disabled={!formValid}>
                Next
              </button>
            </div>
          </div>
          </div>
        );
      case 4:
        return (
          <div className="l-step-container-step">
 
          <div className="l-step-container">
            <h2>When will your journey start?</h2>
            <input
              type="date"
              value={journeystartdate}
              onChange={(e) => setJourneyStartDate(e.target.value)}
              min={new Date().toISOString().split("T")[0]} // Disable past dates
            />
            <div>
              <button className="l-prev-button" onClick={prevStep}>
                Previous
              </button>
              <button className="l-next-button" onClick={nextStep} disabled={!formValid}>
                Next
              </button>
            </div>
          </div>
          </div>
        );
      case 5:
        const currentTime = new Date().toTimeString().slice(0, 5);
        const selectedDateIsToday = new Date(journeystartdate).toDateString() === new Date().toDateString();
        return (
          <div className="l-step-container-step">
 
          <div className="l-step-container">
            <h2>Pickup Time</h2>
            <input
              type="time"
              value={pickuptime}
              onChange={(e) => setPickupTime(e.target.value)}
              min={selectedDateIsToday ? currentTime : "00:00"} // Disable past times if the selected date is today
            />
            <div>
              <button className="l-prev-button" onClick={prevStep}>
                Previous
              </button>
              <button className="l-next-button" onClick={nextStep} disabled={!formValid}>
                Next
              </button>
            </div>
          </div>
          </div>
        );
      case 6:
        return (
          <div className="l-step-container-step">
 
          <div className="l-step-container">
            <h2>How many passengers will be traveling?</h2>
            <div className="l-passenger-container">
              <button
                className="l-decrement-button"
                onClick={decrementPassengers}
                disabled={noofpassengers === 1}
              >
                -
              </button>
              <input
                type="number"
                value={noofpassengers}
                min={1}
                max={8}
                onChange={(e) => setNoOfPassengers(e.target.value)}
              />
              <button
                className="l-increment-button"
                onClick={incrementPassengers}
                disabled={noofpassengers === 8}
              >
                +
              </button>
            </div>
            <div>
              <button className="l-prev-button" onClick={prevStep}>
                Previous
              </button>
              <button className="l-next-button" onClick={nextStep} disabled={!formValid}>
                Next
              </button>
            </div>
          </div>
          </div>
        );
 
     case 7:
  return (
    <div className="l-step-container-step">
      <div className="l-step-container">
        <h2>Enter the price per seat for your journey</h2>
        <input
          type="number"
          placeholder="Enter Price Per Seat"
          value={priceperseat}
          onChange={(e) => {
            setPricePerSeat(e.target.value);
            // Clear previous error message when user starts typing
            setPriceErrorMessage("");
          }}
          onBlur={() => {
            const price = parseFloat(priceperseat);
            if (price < 500 || price > 5000) {
              setPriceErrorMessage("Price per seat must be between 500 and 5000 Rs.");
            }
          }}
        />
        {priceErrorMessage && <div className="error-message">{priceErrorMessage}</div>}
        <div>
          <button className="l-prev-button" onClick={prevStep}>
            Previous
          </button>
          <button className="l-submit-button" onClick={handleSubmit} disabled={!formValid}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
 
      default:
        return null;
    }
  };
 
  return (
    <div className="l-prev-navbar">
      <UsersNavbar username={username} />
     
      {renderStep()}
 
     
     
    </div>
  );
}
 
export default Pickingup;