
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import './UserProfile.css';
import UsersNavbar from '../Landingpages/UsersNavbar';
 
function UserProfile() {
 
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
 
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);
 
 
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    phoneNumber: '',
    password:'',
    profileImage: null
  });
 
  const [saveStatus, setSaveStatus] = useState(null);
 
 
 
 
 
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = Cookies.get('token');
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        };
        const response = await axios.get('https://api.ezyrides.chitgenius.com/userprofile', config);
        const userProfileData = response.data;
        setFormData(userProfileData.userProfile);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
 
    fetchUserProfile();
  }, []);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
 
  const handleImageChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      profileImage: e.target.files[0]
    }));
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = Cookies.get('token');
      const formDataToSend = new FormData();
      formDataToSend.append('profileImage', formData.profileImage);
      for (let key in formData) {
        if (key !== 'profileImage') {
          formDataToSend.append(key, formData[key]);
        }
      }
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      };
      const response = await axios.post('https://api.ezyrides.chitgenius.com/userprofile', formDataToSend, config);
      console.log(response.data);
      setSaveStatus('success');
      navigate("/profileinfo");
      setTimeout(() => {
        setSaveStatus(null);
      }, 2000); // Hide the success message after 2 seconds
    } catch (error) {
      console.error('Error updating user data:', error);
      setSaveStatus('error');
    }
  };
 
  return (
  <div>
    <div>
    <div><UsersNavbar username={username} /></div>
 </div>
 <div className="us_user-main">
      <div className="us_user-profile">
      <h1 className="us_profile-title">Personal Details</h1>
      <form className="us_profile-form" onSubmit={handleSubmit}>
      <div className="us_form-group">
          <label>First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="us_form-group">
          <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="us_form-group">
          <label>Date of Birth</label>
          <input
            type="date"
            name="dob"
            value={formData.dob.split('T')[0]}
            onChange={handleChange}
          />
        </div>
        <div className="us_form-group">
          <label>Email Address</label>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="us_form-group">
          <label>Mobile Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </div>
     
        <div className="us_form-group">
          <label>Profile Image</label>
          <input
            type="file"
            name="profileImage"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <div className="us_button-container">
          <button type="/">Save</button>
          {saveStatus === 'success' && <p className="us_save-success">Data saved successfully!</p>}
          {saveStatus === 'error' && <p className="us_save-error">Error saving data. Please try again.</p>}
        </div>
      </form>
    </div>
    </div>
    </div>
  );
}
 
export default UserProfile;
 