import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { FaPlus, FaMinus, FaRegUser } from 'react-icons/fa';
import { MdRadioButtonChecked } from 'react-icons/md';
import { IoCalendarOutline } from 'react-icons/io5';
import { LuArrowDownUp } from 'react-icons/lu';
import "./Search.css";
import { useNavigate } from "react-router-dom";

function Search() {
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [date, setDate] = useState("");
  const [passengers, setPassengers] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get("https://api.ezyrides.chitgenius.com/ridedetails")
      .then((response) => {
        const rides = response.data.rides;
        const departureLocations = [...new Set(rides.map(ride => ride.leavingfrom))];
        const destinationLocations = [...new Set(rides.map(ride => ride.goingto))];
        const fromOptions = departureLocations.map(location => ({ value: location, label: location }));
        const toOptions = destinationLocations.map(location => ({ value: location, label: location }));
        setFromOptions(fromOptions);
        setToOptions(toOptions);
      })
      .catch((error) => {
        console.error("Error fetching rides:", error);
        setError("Error fetching rides. Please try again later.");
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/ser?from=${from}&to=${to}&date=${date}&passengers=${passengers}`);
  }

  const handleFromChange = (selectedOption) => {
    setFrom(selectedOption.value);
  };

  const handleToChange = (selectedOption) => {
    setTo(selectedOption.value);
  };

  const handleDecrement = () => {
    if (passengers > 1) {
      setPassengers(passengers - 1);
    }
  };

  const handleIncrement = () => {
    if (passengers < 8) {
      setPassengers(passengers + 1);
    }
  };

      // Get today's date in YYYY-MM-DD format
      const today = new Date().toISOString().split("T")[0];
 
 
     

  return (
    <div className="lr-search-container">
      <form onSubmit={handleSubmit} className="lr-search-inputs">
        <div className="lr-gap1">
          <div className="lr-sectt">
            <MdRadioButtonChecked className="lr-radio-icon" />
            <Select
              value={fromOptions.find(option => option.value === from)}
              onChange={handleFromChange}
              options={fromOptions}
              className="lr-leaving"
              placeholder="Leaving from"
              styles={{ control: provided => ({ ...provided, height: '60px' }) }}
            />
          </div>
          <LuArrowDownUp className="lr-arrow-icon" />
          <div className="lr-sectp">
            <MdRadioButtonChecked className="lr-radio-icon" />
            <Select
              value={toOptions.find(option => option.value === to)}
              onChange={handleToChange}
              options={toOptions}
              className="lr-leaving"
              placeholder="Going to"
              styles={{ control: provided => ({ ...provided, height: '60px' }) }}
            />
          </div>
        </div>
        <div className="lr-gap1">
          <div className="lr-sectt">
            <IoCalendarOutline className="lr-calendar-icon" />
            <input
              type="date"
              value={date}
              className="lr-cal"
              onChange={(e) => setDate(e.target.value)}
              min={today}
            />
          </div>
          <div className="lr-passenger-input">
            <FaRegUser className="lr-user-icon" />
            <input
              type="text"
              className="lr-passenger-count"
              value={`${passengers} Passenger${passengers !== 1 ? 's' : ''}`}
              readOnly
            />
            <div className="lr-passenger-controls">
              <FaMinus className="lr-passenger-minus-icon" onClick={handleDecrement} />
              <input
                type="number"
                className="lr-passenger-customm"
                value={passengers}
                onChange={(e) => setPassengers(e.target.value)}
                min="1"
                max="8"
                // style={{ width: '60px', height: '10px', border: 'none', paddingLeft: '20px' }}
              />
              <FaPlus className="lr-passenger-plus-icon" onClick={handleIncrement} />
            </div>
          </div>
        </div>
        <button type="submit" disabled={loading} className="lr-search">
          Search
        </button>
      </form>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
    </div>
  );
}

export default Search;
