import React from 'react';
import "./UserFooter.css";
import { FaFacebook } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import Image1 from '../Images/logo.png'

 
const UserFooter = () => {
  return (
    <div className='foot-foot'>
<div className="foot-hero-icon">
     
     <img className="foot-icon-img" src={Image1} alt="BlaBlaCar"  />

     </div>

      <div className='foot-foot-section'>
        <h4 className='foot-top'><b>Special Allowance</b></h4>
        <div>
          <h2 className='foot-work'>Music</h2>
          <h2 className='foot-work'>Pets Allowed</h2>
          <h2 className='foot-work'>Chattiness</h2>
          <h2 className='foot-work'>Instant Booking</h2>
        </div>
      </div>
      <div className='foot-foot-section-1'>
        <h4 className='foot-top'><b>Features</b></h4>
        <div >
          <h2 className='foot-work'>Earliest departure</h2>
          <h2 className='foot-work'>Lowest cost</h2>
          <h2 className='foot-work'>Close to departure point</h2>
          <h2 className='foot-work'>Close to arrival point</h2>
        </div>
      </div>
      <div className='foot-foot-section-2'>
        <h4 className='foot-top'><b>Language</b></h4>

        <div >
          <h2 className='foot-work'>English</h2>
          
        </div>
       
        <div className="foot-social-icons-container">
      <FaFacebook className="foot-iicon" />
      <FaYoutube className="foot-iicon" />
      <FaInstagram className="foot-iicon" />
    </div>
      </div>
    </div>
  );
};
 
export default UserFooter;