
import React from "react";
import "./SuccessMess.css"; // Reuse the existing CSS for styling
 
const RideCancelledMessage = ({ onClose }) => {
  const handleClose = () => {
    onClose();
  };
 
  return (
    <div className="success-message-overlay">
      <div className="success-message-box">
        <p>Your ride is successfully cancelled!</p>
        <div className="button-container">
          <button className="ok-button" onClick={handleClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
 
export default RideCancelledMessage;
 