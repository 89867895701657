import React from 'react';
import "./Footer.css";
import { FaFacebook } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import Image1 from '../Images/logo.png'

 
const Footer = () => {
  return (
    <div className='lrf-foot'>
      <div className="lrf-hero-icon">
     
     <img className="lrf-icon-img" src={Image1} alt="BlaBlaCar"  />

     </div>
      <div className='lrf-foot-section'>
        <h4 className='lrf-top'>Top carpool routes</h4>
        <div>
          <h2 className='lrf-work'>Delhi → Chandigarh</h2>
          <h2 className='lrf-work'>Mumbai → Pune</h2>
          <h2 className='lrf-work'>Pune → Mumbai</h2>
          <h2 className='lrf-work'>All carpool routes</h2>
          <h2 className='lrf-work'>All carpool destinations</h2>
        </div>
      </div>
      <div className='lrf-foot-section-1'>
        <h4 className='lrf-top'>About</h4>
        <div >
          <h2 className='lrf-work'>How it works</h2>
          <h2 className='lrf-work'>About Us</h2>
          <h2 className='lrf-work'>Help Center</h2>
          <h2 className='lrf-work'>We're Hiring!</h2>
        </div>
      </div>
      <div className='lrf-foot-section-2'>
        <h4 className='lrf-top'>Language</h4>
        <div >
          <h2 className='lrf-work'>English</h2>
      
        </div>
     
        <div className="lrf-social-icons-container">
      <FaFacebook className="lrf-iicon" />
      <FaYoutube className="lrf-iicon" />
      <FaInstagram className="lrf-iicon" />
    </div>
      </div>
    </div>
  );
};
 
export default Footer;