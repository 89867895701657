
import React, { useState } from "react";
import "./SuccessMess.css";
import RideCancelledMessage from "./RideCancelledMessage"; // Import the ride cancelled message component
 
const SuccessMess = ({ onClose }) => {
  const [rideCancelled, setRideCancelled] = useState(false);
 
  const handleNoClick = () => {
    onClose();
  };
 
  const handleYesClick = () => {
    setRideCancelled(true);
  };
 
  const handleRideCancelledClose = () => {
    onClose();
    setRideCancelled(false); // Reset the state for next use
  };
 
  return (
    <div className="success-message-overlay">
      <div className="success-message-box">
        {!rideCancelled ? (
          <>
            <p>Are you sure you want to cancel the ride?</p>
            <div className="button-container">
              <button className="cancel-button" onClick={handleNoClick}>
                No
              </button>
              <button className="confirm-button" onClick={handleYesClick}>
                Yes
              </button>
            </div>
          </>
        ) : (
          <RideCancelledMessage onClose={handleRideCancelledClose} />
        )}
      </div>
    </div>
  );
};
 
export default SuccessMess;
 