import React, { useEffect, useState } from "react";
import axios from "axios";
import SAnavbar from "../components/SuperAdminNavbars/SAnavbar";
import "./Ridespage.css"; // Import your CSS file for styling
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { IoSearch } from "react-icons/io5";

 
const Ridespage = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [role, setRole] = useState("");
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    }
  }, []);
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "superadmin") {
        navigate("/");
      }
    }
  }, []);
 
  const [rides, setRides] = useState([]);
  const [username, setUsername] = useState("");
  const [search, setSearch] = useState("");
 
  useEffect(() => {
    fetchRides();
  }, []);
 
  const fetchRides = () => {
    axios
      .get("https://api.ezyrides.chitgenius.com/rides111")
      .then((response) => {
        console.log("Fetched rides:", response.data.rides); // Log the response to inspect the data structure
        setRides(response.data.rides);
      })
      .catch((error) => {
        console.error("There was an error fetching the rides data!", error);
      });
  };
 
  const handleView = (rideId) => {
    console.log("View ride with ID ${rideId}");
  };
 
  const handleDelete = (rideId) => {
    axios
      .delete(`http://localhost:5000/ridespage/${rideId}`)
      .then((response) => {
        console.log("Ride with ID ${rideId} deleted successfully");
        fetchRides(); // Refresh rides after deletion
      })
      .catch((error) => {
        console.error("Error deleting ride with ID ${rideId}, error");
      });
  };
 
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
 
  const filteredRides = rides.filter((ride) => {
    return (
      ride.riderbooking_id.toLowerCase().includes(search.toLowerCase()) ||
      ride.leavingfrom.toLowerCase().includes(search.toLowerCase()) ||
      ride.goingto.toLowerCase().includes(search.toLowerCase()) ||
      ride.date.toLowerCase().includes(search.toLowerCase()) ||
      ride.time.toLowerCase().includes(search.toLowerCase()) ||
      ride.passengers.toString().includes(search.toLowerCase()) ||
      ride.price.toString().includes(search.toLowerCase()) ||
      ride.pet.toLowerCase().includes(search.toLowerCase()) ||
      ride.music.toLowerCase().includes(search.toLowerCase()) ||
      ride.smoke.toLowerCase().includes(search.toLowerCase())
    );
  });
 
  return (
    <div>
      <SAnavbar username={username} />
      <div className="ridespage-container">
        <div className="SA-searchContainer">
          <input
            type="text"
            placeholder="Search by date"
            value={search}
            onChange={handleSearchChange}
            className="SA-search-input"
          />
          <IoSearch className="ridespage-search"/>
        </div>
        <div className="ridespage-content">
          <div className="ridespage-title">
            <h2>Rides</h2>
          </div>
          <div className="rides-table-container">
            
            <table className="rides-table">

              <thead>
              {/* <tr>   
                                    <th colSpan="10" className="ridespage-title-rides" > Rides</th>
                                </tr> */}
                <tr>
                  <th className="lvp-rides-th">Rider Booking ID</th>
                  <th className="lvp-rides-th">Leaving From</th>
                  <th className="lvp-rides-th">Going To</th>
                  <th className="lvp-rides-th">Date</th>
                  <th className="lvp-rides-th">Time</th>
                  <th className="lvp-rides-th">Passengers</th>
                  <th className="lvp-rides-th">Price</th>
                  <th className="lvp-rides-th">Pet Allowed</th>
                  <th className="lvp-rides-th">Music</th>
                  <th className="lvp-rides-th">Smoking Allowed</th>
                </tr>
              </thead>
              <tbody>
                {filteredRides.map((ride) => (
                  <tr key={ride.riderbooking_id}>
                    <td className="lvp-rides-td">{ride.riderbooking_id}</td>
                    <td className="lvp-rides-td">{ride.leavingfrom}</td>
                    <td className="lvp-rides-td">{ride.goingto}</td>
                    <td className="lvp-rides-td">{ride.date}</td>
                    <td className="lvp-rides-td">{ride.time}</td>
                    <td className="lvp-rides-td">{ride.passengers}</td>
                    <td className="lvp-rides-td">{ride.price}</td>
                    <td className="lvp-rides-td">{ride.pet}</td>
                    <td className="lvp-rides-td">{ride.music}</td>
                    <td className="lvp-rides-td">{ride.smoke}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Ridespage;
 
 