import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import Cookies from 'js-cookie';
import './Yourcreationstable.css';
import UsersNavbar from '../Landingpages/UsersNavbar';

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
}

const Yourcreationstable = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");
    const [userId, setUserId] = useState(null);
    const [rides, setRides] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const storedRole = localStorage.getItem("role");
        if (storedRole !== "user") {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        const token = Cookies.get("token");
        if (!token) {
            navigate("/");
        } else {
            const decoded = jwtDecode(token);
            setUsername(decoded.username);
            setRole(decoded.role);
            setUserId(decoded.id);
            if (decoded.role !== "user") {
                navigate("/");
            }
        }
    }, [navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(
                    `https://api.ezyrides.chitgenius.com/api/getmemberdetails/afterlogin/${username}`
                );
                if (response.data.length > 0) {
                    setUserId(response.data[0].id);
                    console.log(response.data[0].id);
                } else {
                    console.error("User data not found");
                }
            } catch (error) {
                console.error("Error fetching user data", error);
            }
        };
        if (username) {
            fetchUserData();
        }
    }, [username]);

    useEffect(() => {
        if (userId) {
            axios.get(`https://api.ezyrides.chitgenius.com/api/user/rides13/${userId}`)
                .then(response => {
                    setRides(response.data.rides);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching ride data 13', error);
                    setError('Error fetching ride data 13. Please try again later.');
                    setLoading(false);
                });
        }
    }, [userId]);

    const handleView = (yourcreations) => {
        console.log(`View button clicked yourcreations: ${yourcreations}`);
        // Implement the logic to view ride details
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <div ><UsersNavbar username={username} /></div>

         <div className="yourcrea-main" >
            <div className="yourcrea-container">
            <div className="yourcrea-rides">
              <h2 className="yourcrea-rides-your">Your creations</h2>
            </div>
                <div className="yourcrea-content">
                {/* <th className="yourcrea-rides">Your creations</th> */}

                    <div className="yourcrea-table-container">
                        <table className="yourcrea-table">
                            <thead>
                                <tr>
                                    <th className="yourcrea-rides-th">Rider ID</th>
                                    <th className="yourcrea-rides-th">Riderbooking ID</th>
                                    <th className="yourcrea-rides-th">Leaving From</th>
                                    <th className="yourcrea-rides-th">Going To</th>
                                    <th className="yourcrea-rides-th">Date</th>
                                    <th className="yourcrea-rides-th">Time</th>
                                    <th className="yourcrea-rides-th">Passengers</th>
                                   
                                    <th className="yourcrea-rides-th">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rides.length > 0 ? (
                                rides.map(ride => (
                                    <tr key={ride.yourcreations}>
                                        <td className="yourcrea-rides-td">{ride.rider_id}</td>
                                        <td className="yourcrea-rides-td">{ride.riderbooking_id}</td>
                                        <td className="yourcrea-rides-td">{ride.leavingfrom}</td>
                                        <td className="yourcrea-rides-td">{ride.goingto}</td>
                                        <td className="yourcrea-rides-td">{ride.date}</td>
                                        <td className="yourcrea-rides-td">{ride.time}</td>
                                        <td className="yourcrea-rides-td">{ride.passengers}</td>
                                      
                                        <td>
                                            <div className="button-container">
                                            <Link to={`/Usercomerider/${ride.riderbooking_id}`}>
                                            <button className="yourcrea-reports-view" >View</button>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                              ):(
                                <tr>
                                <td colSpan="9" className="yourcrea-no-booking">You are not a rider yet.</td>
                            </tr>
                              )
                                
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Yourcreationstable;
