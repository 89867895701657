import React, { useState } from "react";
import "./Help.css";
 
const Help = () => {
  const [expanded, setExpanded] = useState({
    para1: false,
    para2: false,
    para3: false,
    para4: false,
  });
 
  const toggleExpanded = (para) => {
    setExpanded({ ...expanded, [para]: !expanded[para] });
  };
 
  return (
    <div>
      <h6 className="lr-help">
        Everything you need as a driver, in our Help Centre
      </h6>
      <div className="lr-flex-container">
        {/* First column */}
        <div className="content-1">
          <h4 className="lr-howdo-1"> How do I set the passenger contribution for my ride?</h4>
          <p className={expanded.para1 ? "lr-expanded" : "lr-collapsed"}>
            We recommend a contribution per passenger on your rides. These
            suggestions help you set fair contributions for your rides (those
            most likely to get your seats filled!), but can still be adjusted
            within a margin of our recommendation.
         
         
          </p>
         
           <hr className="lr-more-hr"/>
       
        </div>
        <div className="content-2">
          <h4 className="lr-howdo-1">When do I get my money?</h4>
          <p className={expanded.para2 ?"lr-expanded" : "lr-collapsed"}>
            We send your money 48 hours after the ride if you travelled as
            planned. You’ll get your money 1 to 5 weekdays (not counting
            weekends and holidays) after we send it. If you don’t see any money
            in Awaiting transfers, it’s because we already sent it. You can
            check out what we’ve sent in your Transfer history.
          </p>
          
           <hr className="lr-more-hr"/>
        </div>
        {/* Second column */}
        <div className="content-3">
          <h4 className="lr-howdo-1">What should I do if there’s an error with my ride?</h4>
          <p className={expanded.para3 ? "lr-expanded" : "lr-collapsed"}>
            You should edit your ride as soon as you spot the error. If you
            can’t edit your ride because passengers have already booked, contact
            them explaining the mistake. If the changes don’t suit them, you
            should cancel your ride and publish a new one.
          </p>
          
 
        </div>
        <div className="content-4">
          <h4 className="lr-howdo-1">How do I cancel a carpool ride as a driver of a ride?</h4>
          <p className={expanded.para4 ? "lr-expanded" : "lr-collapsed"}>
            It only takes a minute to cancel a listed ride. However, if a driver
            cannot fulfill a ride that has been already booked, it is their
            responsibility to cancel in a timely manner to allow the passenger
            time to adjust their plans.
 
          </p>
          
        </div>
      </div>
   
      
     
    </div>
  );
};
 
export default Help;