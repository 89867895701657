// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import  {Superadmin}   from './SuperAdminPages/superadmin';
import { User } from './UserPages/users';
import { UserDashboard } from './UserPages/userdashboard';
import Signup from './components/Signup';
import { Login } from './components/Login';
import Sampledata from './UserPages/Sampledata';
import Landing from './Landingpages/Landing';
import Footer from './Landingpages/Footer';
import Help from './Landingpages/Help';
import Lghelp from './Landingpages/Lghelp';
import Publishride from './Landingpages/Publishride';
import Navlog from './UserPages/Navlog';
import Navbar from './Landingpages/Navbar';
import SuperAdminprofile from './SuperAdminPages/SuperAdminprofile';
import Members from './SuperAdminPages/Members';
import Suprofile from './SuperAdminPages/Suprofile';
import Reports from './SuperAdminPages/Reports';
import ReportDetails from './SuperAdminPages/ReportDetails';
import SAnavbar from './components/SuperAdminNavbars/SAnavbar';
import Search from './UserPages/Search';
import Ser from './UserPages/Ser';
import Info from './UserPages/Info';
import Book from './UserPages/Book';
import Reportride from './UserPages/Reportride';
import Profileinfo from './UserPages/Profileinfo';
import UserProfile from './UserPages/UserProfile';
import Booked from './SuperAdminPages/Booked';
import Userrides from './UserPages/Userrides';
import Userrrides from './UserPages/Userrrides';
import Usertlandingpage from './Landingpages/Usertlandingpage';
import UsersNavbar from './Landingpages/UsersNavbar';
import UserFooter from './Landingpages/UserFooter';

import TravelPreferences from './UserPages/Travelpreference';
import Edittraveldata from './UserPages/Edittraveldata';

import { Password } from './components/Password';
import Ridespage from './SuperAdminPages/Ridespage';
import Dash from './Dashboard/Dash';
import Usercomerider from './UserPages/Usercomerider';
import Yourcreationstable from './UserPages/Yourcreationstable';
import YourRidestable from './UserPages/YourRidestable';
import VerifyProfile from "./UserPages/VerifyProfile";
import Pickingup from './UserPages/Pickingup';
import Citybooked from './SuperAdminPages/Citybooked';
import City from './SuperAdminPages/City';



const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/userrides" element={<Userrides/>}></Route>
          <Route path="/ridede/:booking_id" element={<Userrrides/>}></Route>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/Login" element={<Login/>}/>
          <Route path="/publishride" element={<Publishride/>} />
          <Route path="/Superadmin" element={<Superadmin/>} />
          <Route path="/lghelp" element={<Lghelp/>} />
          <Route path="/help" element={<Help/>} />
          <Route path="/" element={<Landing/>} />
          <Route path="/footer" element={<Footer/>} />
          <Route path="/Navlog" element={<Navlog/>} />
          <Route path="/Navbar" element={<Navbar/>}/>
          <Route path="/user" element={<User/>} />          
          <Route exact path="/SuperAdminprofile" element={< SuperAdminprofile/>} />  
          <Route exact path="/Members" element={<Members/>} />
          <Route exact path="/Suprofile/:id" element={<Suprofile/>} />
          <Route exact path="/Reports" element={<Reports/>} />
          <Route path="/user/Dashboard" element={<UserDashboard/>} />
          <Route path="/sampledata" element={<Sampledata/>}> </Route>
          <Route path="/SAnavbar" element={<SAnavbar/>}> </Route>
          <Route path="/view/:report_id" element={<ReportDetails/>} />
          <Route path="/search" element={<Search />} />
          <Route path="/Usertlandingpage" element={<Usertlandingpage />} />
          <Route path="/ser" element={<Ser />} />
          <Route path="/info/:riderbooking_id" element={<Info />} />  
          <Route path="/Footer" element={<Footer />} />
         <Route path="/book/:riderbooking_id/:rider_id" element={<Book />} />
          <Route path="/Reportride/:riderbooking_id/:rider_id" element={<Reportride />} />
          <Route path="/profileinfo" element={<Profileinfo />} />
          <Route path="/userprofile" element={<UserProfile />} />
          <Route path="/Booked" element={<Booked />} />
          <Route path="/UsersNavbar" element={<UsersNavbar/>}/>
          <Route path="/UserFooter" element={<UserFooter/>}/>
          <Route path="/travelpreference" element={<TravelPreferences />} />
          <Route path="/forgetpassword" element={<Password />} />
          <Route path="/edittraveldata" element={<Edittraveldata />} />
          <Route path="/Ridespage" element={<Ridespage/>} />
          <Route path="/Dash" element={<Dash/>} />
          <Route path="/Usercomerider/:riderbooking_id" element={<Usercomerider/>} />
          <Route path="/yourcreationstable" element={<Yourcreationstable/>} />
          <Route path="/yourRidestable" element={<YourRidestable/>} />
          <Route path="/verifyprofile" element={<VerifyProfile />} />
          <Route path="/pickingup" element={<Pickingup/>} />
          <Route path="/Citybooked" element={<Citybooked/>} />
          <Route path="/City" element={<City/>} />



       </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
