import React, { useState, useEffect } from 'react';
import { HiChevronRight } from "react-icons/hi";
import axios from 'axios'; 
import './Reportride.css';
import { useParams } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
 
import Cookies from 'js-cookie';
import UsersNavbar from '../Landingpages/UsersNavbar';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';


function Reportride() {

  const { riderbooking_id,rider_id } = useParams();
  
  const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");
    const [user, setUser] = useState({});
    console.log(rider_id)
    useEffect(() => {
      const role = localStorage.getItem("role");
      if (role !== "user") {
        navigate("/");
      }
    }, [navigate]);
   
    useEffect(() => {
      const token = Cookies.get("token");
      if (!token) {
        navigate("/");
      } else {
        const decoded = jwtDecode(token);
        setUsername(decoded.username);
        setRole(decoded.role);
        if (decoded.role !== "user") {
          navigate("/");
        }
      }
    }, [navigate]);
 console.log(username)
  const [showReport, setShowReport] = useState(true);
  const [showScam, setShowScam] = useState(false);
  const [showTextfield, setShowTextfield] = useState(false);
  const [showSuspicious, setShowSuspicious] = useState(false);
  const [showInappropriate, setShowInappropriate] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [wanToReport, setWanToReport] = useState('');
  const [tellUsMore, setTellUsMore] = useState('');
  const [exactlyHappened, setExactlyHappened] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [data, setdata] = useState({});
  const [userId, setUserId] = useState(null);

  const handleOptionClick1 = () => {
    setShowReport(false);
    setWanToReport("A scam activity");
    setShowScam(true);
  };

  const handleOptionClick2 = () => {
    setShowReport(false);
    setWanToReport("Suspicious activity");
    setShowSuspicious(true);
  };
  const handleOptionClick3 = () => {
    setShowReport(false);
    setWanToReport("Inappropriate content");
    setShowInappropriate(true);
  };
  const handleOptionClick4 = () => {
    setShowReport(false);
    setWanToReport("Other");
    setShowOther(true);
  };

  const handleOptionClick5 = () => {
    setShowScam(false);
    setExactlyHappened("I want to report a scam attempt");
    setShowTextfield(true);
  };

  const handleOptionClick6 = () => {
    setShowScam(false);
    setExactlyHappened("I was scammed");
    setShowTextfield(true);
  };


 
  const handleOptionClick7 = () => {
    setShowSuspicious(false);
    setExactlyHappened("A commercial activity");
    setShowTextfield(true);
  };
  const handleOptionClick8 = () => {
    setShowSuspicious(false);
    setExactlyHappened("Fraudulent activities");
    setShowTextfield(true);
  };
  const handleOptionClick9 = () => {
    setShowInappropriate(false);
    setExactlyHappened("Inappropriate content");
    setShowTextfield(true);
  };
  const handleOptionClick10 = () => {
    setShowOther(false);
    setExactlyHappened("Other");
    setShowTextfield(true);
  };
 

  const handleTextChange = (event) => {
    setTellUsMore(event.target.value);
  };

  useEffect(() => {
    const fetchUserData = async () => {
        try {
            const response = await axios.get(
                `https://api.ezyrides.chitgenius.com/api/getmemberdetails/afterlogin/${username}`
            );
           
            // Assuming response.data is an array and you need the 'id' field from the first element
            if (response.data.length > 0) {
                setUserId(response.data[0].id);  // Set the user ID from the fetched data
                console.log(response.data[0].id); // Log the fetched user ID
            } else {
                console.error("User data not found");
            }
        } catch (error) {
            console.error("Error fetching user data", error);
        }
    };
    if (username) {
        fetchUserData();
    }
}, [username]);
console.log(userId)
  const handleSubmit = () => {
    const data = {
      wan_to_report_you_want_to_report: wanToReport,
      tell_us_about_and_more: tellUsMore,
      exactly_happened: exactlyHappened,
      rider_id:riderbooking_id,
       User_id:userId,
       rider_uiid:rider_id
     
    };

    

    // Send a POST request to store the data
    axios.post('https://api.ezyrides.chitgenius.com/api/Report', data)
      .then(response => {
        console.log(response.data.message);
        setTellUsMore(''); // Clear the text field
        setShowSuccessModal(true); // Show success modal
      })
      .catch(error => {
        console.error('Error storing report:', error);
        if (error.response && error.response.data && error.response.data.message) {
          setError(error.response.data.message);
        } else {
          setError('An error occurred. Please try again later.');
        }
 
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Please try again later.',
        })
        .then(() => {
          navigate("/ser?passengers=1"); // Navigate back to previous page
        });
      });
  };

  const closeModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div> 
    <div><UsersNavbar username={username} /></div>
      {showReport && ( 
        <div className="ride-main">
          <div className="ride-main-1">
          <div className="ride-box-you">
                <div className="ride-tax-center-1">
            
            </div>
            <ul className="ride-report-options">
            <h1 className="ride-tax-center">What do you want to report?</h1>
              <li onClick={handleOptionClick1}>
                <div className="ride-hr-ascam">
                A scam activity
                <div className="Scam-symbol"><HiChevronRight /></div>
                </div>
              </li>
              <hr/>
              <li onClick={handleOptionClick2}>
                <div className="ride-hr-ascam">
                Suspicious activity
                <div className="Scam-symbol"><HiChevronRight /></div>
                </div>
              </li>
              <hr/>
              <li onClick={handleOptionClick3}>
                <div className="ride-hr-ascam">
                Inappropriate content
                  <div className="Scam-symbol"><HiChevronRight /></div>
                </div>
              </li>
              <hr/>
              <li onClick={handleOptionClick4}>
                <div className="ride-hr-ascam">
                Other
                  <div className="Scam-symbol"><HiChevronRight /></div>
                </div>
              </li>
            </ul>
            </div>
          </div>
        </div>
      )}

      {showScam && (
        <div className="Scam-main">
          <div className="Scam-main-1">
          <div className="Scam-box-you-1">

            
            <ul className="Scam-report-options">
            <h1 className="Scam-tax-center">What happened, exactly?</h1>
              <li onClick={handleOptionClick5}>
                <div className="Scam-hr-ascam">
                  I want to report a scam attempt
                  <div className="Scam-symbol-1"><HiChevronRight /></div>
                </div>
              </li>
              <hr/>
              <li onClick={handleOptionClick6}>
                <div className="Scam-hr-ascam">
                I was scammed
                  <div className="Scam-symbol-1"><HiChevronRight /></div>
                </div>
              </li>
            </ul>
            </div>
          </div>
        </div>
      )}

{showSuspicious && (
        <div className="Scam-main">
          <div className="Scam-main-1">
          <div className="Scam-box-you-1">

            <ul className="Scam-report-options">
            <h1 className="Scam-tax-center">What happened, exactly?</h1>

              <li onClick={handleOptionClick7}>
                <div className="Scam-hr-ascam">
                This person may be using easy ride as a commercial activity
                  <div className="Scam-symbol-1"><HiChevronRight /></div>
                </div>
              </li>
              <hr/>
              <li onClick={handleOptionClick8}>
                <div className="Scam-hr-ascam">
                This person may be engaging in fraudulent activities
                  <div className="Scam-symbol-1"><HiChevronRight /></div>
                </div>
              </li>
            </ul>
            </div>
          </div>
        </div>
      )}

{showInappropriate && (
        <div className="Scam-main">
          <div className="Scam-main-1">
          <div className="Scam-box-you-1">

            <ul className="Scam-report-options">
            <h1 className="Scam-tax-center">What happened, exactly?</h1>

              <li onClick={handleOptionClick9}>
                <div className="Scam-hr-ascam">
                Inappropriate content
                  <div className="Scam-symbol-1"><HiChevronRight /></div>
                </div>
              </li>
              
            </ul>
            </div>
          </div>
        </div>
      )}

{showOther && (
        <div className="Scam-main">
          <div className="Scam-main-1">
          <div className="Scam-box-you-1">

            <ul className="Scam-report-options">
            <h1 className="Scam-tax-center">What happened, exactly?</h1>

              <li onClick={handleOptionClick10}>
                <div className="Scam-hr-ascam">
                Other
                  <div className="Scam-symbol-1"><HiChevronRight /></div>
                </div>
              </li>
              
            </ul>
            </div>
          </div>
        </div>
      )} 

{showTextfield && (
        <div className="Textfield">
          <div className="Textfield-tell-us">
            <p className="Textfield-tell">Please tell us more</p>
          </div>
          <div className="Text-box-1">
            <textarea
              placeholder="Max. 150 characters"
              value={tellUsMore}
              onChange={handleTextChange}
            ></textarea>
          </div>
          {tellUsMore && (
            <div className="button-container">
              <button type="button" className="button-fa1 eqU-gyb" onClick={handleSubmit}>
                <span className="dJw-2if"><span>Report</span></span>
              </button>
            </div>
          )}
        </div>
      )}
      {showSuccessModal && (
        <div className="modal-cont-popyour">
          <div className="modal-content">
       
            <span className="close-button" onClick={closeModal}>&times;</span>   
            <p>Your concern was sent successfully!</p>
          
            <Link to={`/info/${riderbooking_id}`} className="ok-button-your" onClick={closeModal}>OK</Link>
            </div>
        </div>
      )}

      {/* {error && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )} */}
 </div>
  );
}

export default Reportride;