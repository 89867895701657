
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { LuArrowDownUp } from "react-icons/lu";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import "./Book.css";
import UsersNavbar from "../Landingpages/UsersNavbar";
import { jwtDecode } from "jwt-decode"; // Import without destructuring
import { MdOutlineArrowForward } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { CgSandClock } from "react-icons/cg";
import { FaUserClock } from "react-icons/fa";
import { MdEventSeat } from "react-icons/md";
 
 
function Book() {
  const navigate = useNavigate();
  const { riderbooking_id } = useParams();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [user, setUser] = useState({});
  const [data, setData] = useState({});
  const [passengers, setPassengers] = useState(0);
  const [riderId, setRiderId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(false);
  const [passengerLimit, setPassengerLimit] = useState(3);
  const [showModal, setShowModal] = useState(false);
 
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "user") {
      navigate("/");
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "user") {
        navigate("/");
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.ezyrides.chitgenius.com/api/getmemberdetails/afterlogin/${username}`
        );
        setData(response.data[0]);
        fetchRiderInfo(response.data[0].id);
      } catch (error) {
        console.error("Error fetching member details:", error);
      }
    };
    fetchData();
  }, [username]);
 
  const fetchRiderInfo = async (userId) => {
    try {
      const response = await axios.get(
        `https://api.ezyrides.chitgenius.com/api/userinfodetails/afterlogin/${userId}`
      );
      setRiderId(response.data[0].rider_id);
    } catch (error) {
      console.error("Error fetching rider info:", error);
    }
  };
 
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://api.ezyrides.chitgenius.com/info1/${riderbooking_id}`
        );
        setUser(response.data.user);
        setPassengerLimit(response.data.user.passengers);
        // setPassengers(Math.min(response.data.user.passengers, 0));
        setPassengers(0); // Set passengers to 0 initially
 
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };
    fetchUserData();
  }, [riderbooking_id]);
 
  if (loading) {
    return <div>Loading...</div>;
  }
 
  const handleSubmit = async (event) => {
    event.preventDefault();
 
    // if (passengers === 0) {
    //   setFormError("Please select the number of seats to book.");
    //   return;
    // }
 
    if (passengers === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select the number of seats to book.",
      });
      return;
    }
 
    if (riderId === user.rider_id) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You cannot book your own ride.",
      });
      return;
    }
 
    const bookingData = {
      riderbooking_id: user.riderbooking_id,
      userid: data.id,
      rider_id: user.rider_id,
      riderus_id: riderId,
      time: user.time,
      date: user.date,
      price: user.price,
      fromto: user.leavingfrom,
      goingto: user.goingto,
      Noofseatsbooked: passengers,
    };
 
    try {
      const response = await axios.post(
        "https://api.ezyrides.chitgenius.com/api/bookings",
        bookingData
      );
      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your booking was successful!",
        }).then(() => {
          axios
            .get(`https://api.ezyrides.chitgenius.com/info1/${riderbooking_id}`)
            .then((updatedResponse) => {
              setUser(updatedResponse.data.user);
              setPassengerLimit(updatedResponse.data.user.passengers);
              setPassengers(Math.min(updatedResponse.data.user.passengers, 0));
              navigate(`/info/${riderbooking_id}`); // Navigate to the info page
            })
            .catch((error) => {
              console.error("Error fetching updated user data:", error);
            });
        });
      }
    } catch (error) {
      console.error("Error submitting booking:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error submitting your booking. Please try again later.",
      });
    }
  };
 
  const formattedDate = new Date(user?.date).toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
 
  const decrementPassengers = () => {
    if (passengers > 1) {
      setPassengers((prevPassengers) => prevPassengers - 1);
      setFormError(null);
    }
  };
 
  const handleClosePopup = () => {
    setFormSuccess(false);
  };
 
  const handleModalClose = () => {
    setShowModal(false);
  };
 
  const handleBookClick = (event) => {
    if (passengerLimit === 0) {
      Swal.fire({
        icon: "error",
        title: "Fully Booked",
        text: "No seats available. This ride is fully booked.",
      }).then(() => {
        navigate(`/ser?passengers=1`); // Add the desired navigation path here
      });
      // setShowModal(true);
    } else {
      handleSubmit(event);
    }
  };
 
  const incrementPassengers = () => {
    if (passengers < passengerLimit) {
      setPassengers((prevPassengers) => prevPassengers + 1);
      setFormError(null);
    } else {
      setFormError(`Only ${passengerLimit} seats are available.`);
    }
  };
 
 
  return (
    <div>
      <UsersNavbar username={username} />
      <div className="lb-heads">
        <form onSubmit={handleSubmit}>
          <div className="lb-main">
            <div className="lb-head">
              <h1 className="lb-sectionbook">
                Book online and secure your seat
              </h1>
              <div className="lb-book-main-container">
                <div className="lb-day">
                  <h3 className="lb-day">{formattedDate}</h3>
                </div>
 
                <div className="lb-travel">
                  <p>
                    <IoLocationSharp className="lb-bold" />
                    <span className="lb-bold-text">Ride:</span>{" "}
                    {user?.leavingfrom}
                    <MdOutlineArrowForward className="lb-timerr" />
                    <span id="picktime">{user?.goingto}</span>
                  </p>
                  <div className="lb-timeup">
                    <div className="pickup-dropoff-time">
                      <CgSandClock />
                      <span className="lb-bold-text">Pickup Time:</span>{" "}
                      {user?.time}
                    </div>
                    <div className="pickup-dropoff-time">
                      <FaUserClock />
                      <span className="lb-bold-text">Dropoff Time:</span>{" "}
                      {user?.timedrop}
                    </div>
                  </div>
 
                  <div className="passenger-selector">
                    <span className="lb-bold-text">
                      <MdEventSeat />
                      Select seats:{" "}
                    </span>
                    <div className="passenger-box">
                      <button
                        type="button"
                        className="passenger-btn"
                        onClick={decrementPassengers}
                        disabled={passengers === 1}
                      >
                        -
                      </button>
                      <span className="passenger-count">{passengers}</span>
                      <button
                        type="button"
                        className="passenger-btn"
                        onClick={incrementPassengers}
                        disabled={passengers === passengerLimit}
                      >
                        +
                      </button>
                    </div>
                    {formError && (
                      <div className="error-message-container">
                        <div className="error-message">{formError}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
 
              <div className="lb-line"></div>
              <div className="lb-book-main-container">
                <div>
                  <h3 className="lb-dayy">Price summary</h3>
                </div>
                <div className="lb-book-detaill">
                  <div className="lb-book-detaill">
                    <span className="lb-seat" id="">
                      ₹Price: {user?.price}
                    </span>
                  </div>
                  <div className="lb-cash">
                    <p className="lb-bold-text">Pay in the car</p>
                    <h3 className="lb-cashh">Cash</h3>
                  </div>
                </div>
                {!formSuccess && passengerLimit > 0 && (
                  <input
                    className="lb-book"
                    type="button"
                    value="Book"
                    onClick={handleBookClick}
                  />
                )}
                {passengerLimit === 0 && (
                  <input
                    className="lb-book"
                    type="button"
                    value="Book"
                    onClick={handleBookClick}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        {/* {showModal && <Modal onClose={handleModalClose} />} */}
      </div>
    </div>
  );
}
 
export default Book;
 
 