
import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import Cookies from 'js-cookie';
import { Link, useNavigate } from "react-router-dom";
import "./SAnavbar.css";
import logo from '../Imagess/logos.png';
 
const SAnavbar = ({ username }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [role, setRole] = useState("");
 
  const navRef = useRef();
  useEffect(() => {
    const fetchSuperAdminData = async () => {
      try {
        const response = await fetch(`https://api.ezyrides.chitgenius.com/superadmin/${username}`);
        if (!response.ok) {
          throw new Error('Failed to fetch superadmin data');
        }
        const data = await response.json();
        if (data.success) {
          setProfileImage(data.superadminData.profileImage);
          setRole(data.superadminData.role);
        } else {
          throw new Error('Superadmin data not found');
        }
      } catch (error) {
        console.error('Error fetching superadmin data:', error);
        setProfileImage(null); 
        setRole(""); 
      }
    };
    fetchSuperAdminData();
  }, [username]);
 
  const toggleNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
 
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
 
  const handleLogout = () => {
    Cookies.remove('token');
    navigate('/');
    localStorage.removeItem('role');
  };
 
  return (
    <>
      <div className="SAnavbar-clr"></div>
      <header className="SAnavbar-header">
        <div>
          <Link to="/Dash" className="SAnavbar-linked-text">
            <div className="SAnavbar-logo-10"></div>
            <img src={logo} className="SAnavbar-yaa" alt="Logo" />
          </Link>
        </div>
        <nav ref={navRef}>
          <ul>
            <li className="SAnavbar-details">
              <div className="SAnavbar-profile-info-container">
                <img
                  src={`https://api.ezyrides.chitgenius.com/uploads/${profileImage}`}
                  className="SAnavbar-profile-image"
                />
                <div className="SAnavbar-profile-details">
                  <p>Role: {role}</p>
                </div>
              </div>
            </li>
          </ul>
          <div className="SAnavbar-dropdown">
            <ul>
              <div className="SAnavbar-dropdown" onClick={toggleDropdown}>
                <RiArrowDropDownLine className="SAnavbar-icons" onClick={() => setShowDropdown(!showDropdown)} />
                {showDropdown && (
                  <div className="SAnavbar-dropdown-content">
                    <a href="/Members">Members</a>  
                    <a href="/Ridespage">Rides</a>
                    <a href="/Reports">Reports</a>
                    <a href="/Booked">Bookings</a>
                    <a href="/SuperAdminprofile">Profile</a>
                    <a href="/Citybooked">Locations</a> 
                    <a href="/City">Add Locations</a> 
                    <a href="#" onClick={handleLogout}>Logout</a>
                  </div>
                )}
              </div>
            </ul>
          </div>
          <button className="SAnavbar-nav-btnn nav-close-btn" onClick={toggleNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="SAnavbar-nav-btn-1" onClick={toggleNavbar}>
          <FaBars />
        </button>
      </header>
    </>
  );
};
 
export default SAnavbar;
 