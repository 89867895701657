import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';


import UserHeader from '../components/UserNavbars/Userheader'; 
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Search from './Search';
import UsersNavbar from '../Landingpages/UsersNavbar';

export const UserDashboard = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'user') {
        navigate('/');
      }
    }
  }, []);

  return (
    <div>
  
      <UsersNavbar username={username} />
      <div>
         <Search/>
      </div>
    </div>
  );
};
